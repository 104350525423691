import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { BehaviorSubject, map, Observable, shareReplay, take, tap } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AddMandatoryFieldsType,
  AssetType,
  AssetTypeEnum,
  DeliveryPackApiService,
  EditMandatoryFieldsType,
  FieldsOptionsService,
} from '@vdms-hq/api-contract';
import { enumValuesToSelectOptions, SelectOption, RefreshService } from '@vdms-hq/shared';
import { uniqBy } from 'lodash';
import { SelectorsModule, SelectorSourceType } from '@vdms-hq/selectors';

@Component({
  selector: 'vdms-hq-edit-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UILayoutModule,
    UIFormModule,
    UIButtonModule,
    SelectorsModule,
  ],
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
})
export class EditDialogComponent implements OnInit {
  private ref: MatDialogRef<EditDialogComponent> = inject(MatDialogRef);
  private fieldsOptionsService: FieldsOptionsService = inject(FieldsOptionsService);
  private deliveryPackApiService: DeliveryPackApiService = inject(DeliveryPackApiService);
  private refreshService = inject(RefreshService);
  public data: { uuid: string } = inject(MAT_DIALOG_DATA);

  #atLeastOneFieldRequiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;
    const { content_type, asset_type, content_class, fields } = formGroup.controls;
    return (content_type.value || asset_type.value || content_class.value) && fields.value?.length
      ? null
      : { atLeastOneRequired: true };
  };

  form = new FormGroup(
    {
      content_type: new FormControl<string | null>(''),
      asset_type: new FormControl<AssetType | null>(null, [Validators.required]),
      content_class: new FormControl<string | null>(null),
      fields: new FormArray<FormControl<string>>([]),
    },
    { validators: this.#atLeastOneFieldRequiredValidator },
  );

  loading$ = new BehaviorSubject(false);

  assetTypes = enumValuesToSelectOptions(AssetTypeEnum, '');

  allPossibleFieldsSelectOptions$: Observable<SelectOption[]> = this.fieldsOptionsService
    .getAllFieldTypesSettings()
    .pipe(
      take(1),
      map((items) =>
        items.map((item) => ({
          key: item,
          label: item.includes('.') ? item.split('.')[1] : item,
        })),
      ),
      map((items) => uniqBy(items, 'key')),
      shareReplay(1),
    );

  get contentType() {
    return this.form.controls.content_type;
  }

  get assetType() {
    return this.form.controls.asset_type;
  }

  get contentClass() {
    return this.form.controls.content_class;
  }

  get formArray() {
    return this.form.controls.fields;
  }

  ngOnInit() {
    if (!this.data) {
      return;
    }

    this.#patchForm(this.data.uuid);
  }

  createNewFormArrayElement(value = '') {
    return new FormControl<string>(value, [Validators.required]) as FormControl<string>;
  }

  removeField(index: number) {
    this.formArray.removeAt(index);
  }

  addField(value = '') {
    this.formArray.push(this.createNewFormArrayElement(value));
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.loading$.next(true);
    const fields: string[] = this.form.value.fields ?? [];

    const patchData: EditMandatoryFieldsType = {
      fields,
    };

    const postData: AddMandatoryFieldsType = {
      content_type: this.form.value.content_type ?? undefined,
      content_class: this.form.value.content_class ?? undefined,
      asset_type: this.form.value.asset_type ?? undefined,
      fields,
    };

    const request$ = this.data?.uuid
      ? this.deliveryPackApiService.updateMandatoryFields(patchData, this.data?.uuid)
      : this.deliveryPackApiService.addMandatoryFieldsType(postData);

    request$
      .pipe(
        take(1),
        tap(() => this.loading$.next(false)),
      )
      .subscribe({
        next: () => {
          this.close(true);
          this.refreshService.refresh('mandatory-fields');
        },
      });
  }

  close(success?: boolean) {
    this.ref.close(success);
  }

  #patchForm(uuid: string) {
    this.loading$.next(true);
    this.deliveryPackApiService
      .getMandatoryField(uuid)
      .pipe(take(1))
      .subscribe({
        next: (resp) => {
          this.contentType.patchValue(resp.contentType);
          this.contentType.disable();

          this.assetType.patchValue(resp.assetType);
          this.assetType.disable();

          this.contentClass.patchValue(resp.contentClass);
          this.contentClass.disable();

          const fields = resp.fields;
          fields.forEach((value) => {
            this.addField(value);
          });

          this.loading$.next(false);
        },
      });
  }

  protected readonly selectorType = SelectorSourceType;
}
