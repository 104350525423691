<vdms-hq-ui-dialog-wrapper>
  <ng-container title>
    <h1>{{ 'client-user-admin.edit_supplier.title' | translate }}</h1>
  </ng-container>
  <ng-container content>
    <vdms-hq-selector
      [formControl]="supplierControl"
      [sourceType]="SelectorSourceType.SUPPLIERS"
      label="Select supplier"
    ></vdms-hq-selector>
  </ng-container>
  <ng-container footer>
    <vdms-hq-ui-button (click)="onAbort()" color="secondary">{{
      'common.global.cancel' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" (click)="onSubmit()">{{ 'common.global.save' | translate }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
