<vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'common.notification_subscriptions.title' }]" gapSize="medium">
  <div class="actions">
    <div class="left">
      <vdms-hq-ui-form-input-text
        [formControl]="userControl"
        [label]="'common.notification_subscriptions.create_dialog.select_users' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-select
        [formControl]="notificationTypeControl"
        [selectOptions]="(notificationTypes$ | async) ?? []"
        [label]="'common.notification_subscriptions.notification_type' | translate"
      ></vdms-hq-ui-form-input-select>
    </div>
    <div class="right">
      <vdms-hq-ui-button color="primary" (click)="handleAction({ key: 'add' })">{{
        'common.notification_subscriptions.buttons.add' | translate
      }}</vdms-hq-ui-button>
      <vdms-hq-ui-button (click)="popSettingsDialog()" icon="settings"></vdms-hq-ui-button>
    </div>
  </div>
  <div class="notifications-table">
    <vdms-hq-ui-multiple-data-presentation
      [dataSource]="dataSource"
      [configuration]="configuration"
      (action)="handleAction($event)"
    >
    </vdms-hq-ui-multiple-data-presentation>
  </div>

  <vdms-hq-ui-actions-footer *ngIf="(dataSource.selection.identifiers$ | async)?.length">
    <ng-container right>
      <vdms-hq-ui-button (click)="handleAction({ key: 'delete_many' })" color="warn">
        {{ 'common.notification_subscriptions.buttons.delete' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
  </vdms-hq-ui-actions-footer>
</vdms-hq-ui-layout-page-wrapper>
