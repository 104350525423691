<div class="multi-data-container" *ngIf="currentOrder$ | async as currentOrder; else loadingDetails">
  <vdms-hq-ui-data-presentation-header
    (action)="handleHeaderAction($event)"
    [actions]="this.headerActions"
    [breadcrumbs]="[
      { name: 'Home', path: '/' },
      { name: 'common.orders.title', path: '../' },
    ]"
    [title]="currentOrder.title ?? ''"
    [counterIndicator]="dataSource.total$ | async"
    [listConfig]="currentOrder.metadata ?? []"
    [sharedE2eId]="'order-data-header'"
  >
  </vdms-hq-ui-data-presentation-header>

  <ng-container *ngIf="canShowTable$ | async; else loading">
    <ng-container [ngTemplateOutlet]="warmingUp"></ng-container>

    <vdms-hq-asset-results-2
      *ngIf="actions$ | async as actions"
      [dataSource]="dataSource"
      [actions]="actions"
      [scopeName]="scopeName"
      [hiddenColumns]="hiddenColumns"
      (action)="handleAction($event)"
      [multiViewKey]="TABLE_TYPE.ASSET_ORDER"
      [contextActions]="assetResultsMenuConfig2"
    >
      @if (filterService.config; as fieldsConfig) {
        <vdms-hq-dynamic-form-group-renderer
          filters
          [form]="filterService.form"
          [fieldsConfig]="fieldsConfig"
          [isLoading]="dataSource.isLoading$ | async"
        ></vdms-hq-dynamic-form-group-renderer>
      }
    </vdms-hq-asset-results-2>
  </ng-container>

  @if (footerActions$ | async; as footerActions) {
    @if ((dataSource.selection.entities$ | async)?.length && footerActions?.length) {
      <vdms-hq-ui-actions-footer>
        <div left>
          @for (footerAction of footerActions; track footerAction.key) {
            <vdms-hq-ui-button
              (click)="handleHeaderAction({ key: footerAction.key })"
              [disabled]="footerAction?.disabled === true"
              [icon]="footerAction?.icon"
              [tooltip]="footerAction.label | translate"
              [color]="footerAction.color ?? 'primary'"
              >{{ footerAction.label | translate }}
            </vdms-hq-ui-button>
          }
        </div>
      </vdms-hq-ui-actions-footer>
    }
  }

  <ng-template #loading>
    <vdms-hq-ui-loader></vdms-hq-ui-loader>
  </ng-template>
</div>

<ng-template #warmingUp>
  @if (estimatedRestoreAt) {
    <div class="order-warming">
      <vdms-hq-ui-empty-results
        type="compact"
        [path]="thermometerImageType$ | async"
        message="Assets are getting warmed up"
      >
        {{ 'ETA: ' }} {{ estimatedRestoreAt | countdown | async }}
      </vdms-hq-ui-empty-results>
    </div>
  }
</ng-template>

<ng-template #loadingDetails>
  <vdms-hq-ui-loader></vdms-hq-ui-loader>
</ng-template>
