import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DataAction,
  DataPresentationHeaderComponent,
  FormSectionComponent,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UIConfirmationDialogService,
  UIFormModule,
  UILayoutModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AdminSubscriptionsDatasource } from '../../logic/admin-subscriptions.datasource';
import { MatDialog } from '@angular/material/dialog';
import { CreateSubscriptionDialogComponent } from '../../components/create-subscription-dialog/create-subscription-dialog.component';
import { AdminNotificationSubscriptionViewModel } from '../../logic/admin-notification-subscription.model';
import { catchError, filter, iif, map, switchMap, take, throwError } from 'rxjs';
import {
  NotificationSettingsService,
  NotificationSubscriptionsService,
  UpdateNotificationsConfigInterface,
} from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { camelToSnakeCase, DestroyComponent, SortDirection, SortDirectionEnum } from '@vdms-hq/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { NotificationSubscriptionEditSettingsDialogComponent } from '../notification-subscription-edit-settings-dialog/notification-subscription-edit-settings-dialog.component';

@Component({
  selector: 'vdms-hq-notification-subscription',
  templateUrl: './notification-subscription.component.html',
  styleUrls: ['./notification-subscription.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MultipleDataPresentationComponent,
    TranslateModule,
    UIButtonModule,
    UIFormModule,
    UILayoutModule,
    DataPresentationHeaderComponent,
    FormSectionComponent,
  ],
  providers: [],
})
export class NotificationSubscriptionComponent extends DestroyComponent implements OnInit {
  notificationTypeControl = new FormControl<string>('all');
  userControl = new FormControl<string>('');

  headerActions = [
    {
      key: 'add',
      label: 'common.notification_subscriptions.buttons.add',
    },
  ];
  actions: DataAction<AdminNotificationSubscriptionViewModel>[] = [
    {
      key: 'edit',
      icon: 'edit',
      label: 'edit',
    },
    {
      key: 'delete',
      icon: 'delete',
      label: 'delete',
    },
  ];
  configuration: MultipleViewConfiguration<AdminNotificationSubscriptionViewModel> = {
    multiView: {
      pagination: true,
      defaultView: 'tableAdvanced',
      fitToVisibleArea: false,
    },
    tableAdvanced: {
      actions: this.actions,
      layout: {
        headers: true,
      },
      columns: [
        { id: 'select', type: 'select' },
        {
          id: 'notification_type',
          label: 'Notification Type',
          valuePath: 'notificationType',
          sortable: true,
          viewFormat: {
            modifiers: {
              translate: true,
            },
          },
        },
        {
          id: 'user_name',
          label: 'User Name',
          valuePath: 'props.user.name',
          sortable: true,
        },
        {
          id: 'user_email',
          label: 'User Email',
          valuePath: 'props.user.email',
          sortable: true,
        },
        {
          id: 'daily_notifications',
          label: 'Email Notifications',
          valuePath: 'props.email_notifications_enabled',
          viewFormat: {
            pills: {
              true: 'done',
              default: '',
            },
            modifiers: {
              yesNo: true,
            },
          },
        },
        {
          id: 'event_notifications',
          label: 'App Notifications',
          valuePath: 'props.app_notifications_enabled',
          viewFormat: {
            pills: {
              true: 'done',
              default: '',
            },
            modifiers: {
              yesNo: true,
            },
          },
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
      columnsEnabled: [
        'select',
        'notification_type',
        'user_name',
        'user_email',
        'daily_notifications',
        'event_notifications',
        'actions',
      ],
    },
  };
  notificationTypes$ = this.notificationsService.getNotificationTypes().pipe(
    map(({ app_notification_types, email_notification_types }) => [
      ...new Set([...app_notification_types, ...email_notification_types]),
    ]),
    map((types) => [
      { key: 'all', label: 'All ' },
      ...types.map((type) => ({
        key: type,
        label: `common.notification_subscriptions.types.type${camelToSnakeCase(type).toLowerCase()}`,
      })),
    ]),
  );

  constructor(
    public readonly dataSource: AdminSubscriptionsDatasource,
    private dialog: MatDialog,
    private confirmationDialog: UIConfirmationDialogService,
    private notificationsService: NotificationSubscriptionsService,
    private toast: ToastService,
    private notificationSettingsService: NotificationSettingsService,
  ) {
    super();
  }

  ngOnInit() {
    this.#listenForInputChanges();
  }

  handleAction($event: { key: string; item?: AdminNotificationSubscriptionViewModel }) {
    const { key, item } = $event;
    switch (key) {
      case 'add':
        this.#add();
        break;

      case 'delete':
        item && this.#delete(item);
        break;

      case 'edit':
        item && this.#edit(item);
        break;

      case 'delete_many':
        this.#deleteMany();
        break;

      default:
        console.log('unknown action');
        break;
    }
  }

  #add(uuid?: string) {
    this.dialog.open(CreateSubscriptionDialogComponent, {
      data: {
        uuid,
      },
    });
  }

  #delete(item: AdminNotificationSubscriptionViewModel) {
    const {
      props: { uuid },
    } = item;

    this.confirmationDialog
      .openDelete()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.notificationsService.deleteSubscription(uuid)),
        catchError((err) => {
          this.toast.error({ id: 'subscription', message: err.message });
          return throwError(err);
        }),
      )
      .subscribe(() => {
        this.toast.success({
          id: 'subscription',
          message: 'common.notification_subscriptions.subscription_messages.delete_success',
        });
        this.#refreshDataSource();
      });
  }

  #edit(item: AdminNotificationSubscriptionViewModel) {
    const {
      props: { uuid },
    } = item;

    this.#add(uuid);
  }

  #deleteMany() {
    this.dataSource.selection.entities$
      .pipe(
        take(1),
        filter(Boolean),
        switchMap((subscriptions) => {
          return this.confirmationDialog.openDelete().pipe(
            take(1),
            filter(Boolean),
            switchMap(() => {
              const payload = subscriptions.map(({ props }) => ({ subscription_uuid: props.uuid }));
              return this.notificationsService.deleteMultipleSubscriptions(payload);
            }),
          );
        }),
      )
      .subscribe();
  }

  #listenForInputChanges() {
    this.notificationTypeControl.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((type) => {
      this.dataSource.isLoading$.next(true);
      this.dataSource.typeSearch$.next(type as string);
    });

    this.userControl.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((user) => {
      this.dataSource.isLoading$.next(true);
      this.dataSource.userSearch$.next(user as string);
    });
  }

  #refreshDataSource() {
    this.dataSource.refresh$.next(new Date().toString());
  }

  popSettingsDialog() {
    this.dialog.open(NotificationSubscriptionEditSettingsDialogComponent);
  }
}
