import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { AuthMfaService, AuthService } from '@vdms-hq/auth';
import { filterEmpty } from '@vdms-hq/shared';
import {
  InfoBarClickAction,
  InfoBarType,
  UIButtonModule,
  UIConfirmationDialogService,
  UIFormModule,
  UILayoutModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { EMPTY, Observable, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { MfaDialogComponent, MfaDialogOutput } from '../mfa-dialog/mfa-dialog.component';
import { map } from 'rxjs/operators';
import moment from 'moment';

export const FRESH_LOGIN_TIMEOUT_MINUTES = 5;

@Component({
  selector: 'vdms-hq-user-profile-security',
  standalone: true,
  imports: [
    CommonModule,
    UILayoutModule,
    TranslateModule,
    UIButtonModule,
    UIFormModule,
    UILoaderModule,
    UILayoutModule,
  ],
  templateUrl: './user-profile-security.component.html',
  styleUrls: ['./user-profile-security.component.scss'],
})
export class UserProfileSecurityComponent implements OnInit, OnDestroy {
  control = new FormControl<boolean>(false, { nonNullable: false });
  processing = false;

  factors$ = this.mfaService.factors$;
  private destroyed$ = new Subject<void>();

  constructor(
    private matDialog: MatDialog,
    private mfaService: AuthMfaService,
    private confirmationDialog: UIConfirmationDialogService,
    private authService: AuthService,
  ) {}

  lastLoginAgo$: Observable<[number, string]> = this.authService.authDefinite$.pipe(
    map((auth) => {
      const minutes = moment().diff(moment(auth?.lastSignIn), 'minutes');
      return [minutes, moment.duration(minutes, 'minutes').humanize()];
    }),
  );

  recentLogin$: Observable<{ recent: boolean; humanized: string }> = this.lastLoginAgo$.pipe(
    map(([minutesAgo, _]) => {
      return {
        recent: minutesAgo < FRESH_LOGIN_TIMEOUT_MINUTES,
        humanized: moment.duration(minutesAgo, 'minutes').humanize(),
      };
    }),
  );

  ngOnInit(): void {
    this.mfaService.mfaEnabled$.pipe(takeUntil(this.destroyed$)).subscribe((isEnabled) => {
      this.control.setValue(isEnabled);
    });

    this.recentLogin$.pipe(take(1)).subscribe((isRecent) => {
      if (!isRecent.recent) {
        this.control.disable();
        return;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onChangeValue($event: boolean | null | undefined) {
    if (!$event) {
      this.deleteAll();
      return;
    }

    this.add(!$event);
  }

  delete(uid: string) {
    this.authService.auth$
      .pipe(
        filterEmpty(),
        switchMap((user) => {
          if (!user) {
            return EMPTY;
          }

          return this.confirmationDialog
            .openDelete({
              title: 'common.dialog.question',
              message: 'common.account_settings.mfa.unenroll',
            })
            .pipe(
              switchMap((confirmed) => {
                this.processing = true;

                if (confirmed) {
                  return this.mfaService.removeMultiFactor(uid);
                }

                return of(confirmed);
              }),
              tap(() => (this.processing = false)),
              tap((res) => {
                if (res) {
                  setTimeout(() => this.authService.logout().then(), 2000);
                }
              }),
            );
        }),
        take(1),
      )
      .subscribe();
  }

  add(selectorOnFail?: boolean) {
    this.matDialog
      .open<MfaDialogComponent, unknown, MfaDialogOutput>(MfaDialogComponent)
      .afterClosed()
      .subscribe((value) => {
        let newToggleValue = this.control.value;
        if (value?.added) {
          newToggleValue = true;
        } else if (selectorOnFail !== undefined) {
          newToggleValue = selectorOnFail;
        }

        this.control.setValue(newToggleValue);
      });
  }

  private deleteAll() {
    this.confirmationDialog
      .openDelete({
        title: 'Are you sure?',
        message: 'common.account_settings.mfa.unenrollAll',
      })
      .pipe(
        switchMap((item) => {
          this.processing = true;

          if (!item) {
            return of(item);
          }
          return this.mfaService.removeAllMultiFactors();
        }),
      )
      .subscribe((confirmed) => {
        this.control.setValue(!confirmed);
        this.processing = false;
      });
  }

  protected readonly InfoBarType = InfoBarType;
  protected readonly InfoBarClickAction = InfoBarClickAction;
}
