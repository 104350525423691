@if (form) {
  <div class="policies-settings-wrapper">
    <ng-container *ngIf="dataSource.connection$ | async as data; else loading">
      <div class="user-selector" *ngIf="copyEnabled" [formGroup]="form">
        <vdms-hq-ui-form-input-select
          class="user-selector__select"
          #userSelect
          [label]="'client-user-admin.copy.title' | translate"
          [selectOptions]="(dataSource.usersSelectOptions$ | async) ?? []"
          formControlName="templateUser"
          (inputChange)="dataSource.searchUsers($event)"
        ></vdms-hq-ui-form-input-select>
        <vdms-hq-ui-button
          class="user-selector__button"
          (click)="copyPoliciesFrom(userSelect.innerFormControl.value)"
          color="primary"
        >
          {{ 'client-user-admin.copy.apply' | translate }}
        </vdms-hq-ui-button>
      </div>
      <div class="policies-filter" [formGroup]="form">
        <vdms-hq-ui-form-input-text
          [suffixConfig]="{ type: 'primary-transparent', text: 'Search', onFocus: false }"
          [fallbackSuffixIcon]="'search'"
          placeholder="Search policy name"
          formControlName="filter"
        ></vdms-hq-ui-form-input-text>
      </div>
      <div class="policies-list">
        <h3 class="title">Policies</h3>
        <ng-container *ngFor="let policy of dataSource.policiesList$ | async">
          <vdms-hq-ui-form-input-checkbox
            [disabled]="!!policy.isCrossGroup || !!policy.isRestricted"
            [label]="policy.name"
            (valueChange)="dataSource.togglePolicy($event, policy)"
            [ngModel]="dataSource.policyIsSelected(policy) | async"
            [withFooter]="false"
          ></vdms-hq-ui-form-input-checkbox>
          <ng-container *ngIf="policy.defaultFor.length">
            <span *ngFor="let app of policy.defaultFor" class="policies-list__policy-default-for">
              Default for:
              <vdms-hq-ui-value-renderer [value]="app" [format]="formatConfig"></vdms-hq-ui-value-renderer> </span
          ></ng-container>
        </ng-container>
      </div>

      <div class="effective-permissions-list" *ngIf="dataSource.permissionsList$ | async as permissionsList">
        <h3 class="title">
          Enabled permissions <span *ngIf="dataSource.permissionsListTotal$ | async as total">({{ total }})</span>
        </h3>
        <ul class="permissions" *ngFor="let permissionsSection of permissionsList | keyvalue">
          <div class="permissions__section">
            <p class="permissions__section__label">{{ permissionsSection.key }}</p>
            <ng-container *ngFor="let permission of permissionsSection.value">
              <li class="permissions__section__item--disabled mat-body-2">
                {{ permission.name }}
              </li>
            </ng-container>
          </div>
        </ul>
      </div>
    </ng-container>
  </div>

  <ng-template #loading>
    <div class="loader-wrapper">
      <vdms-hq-ui-loader></vdms-hq-ui-loader>
    </div>
  </ng-template>
}
