import { inject, Injectable } from '@angular/core';
import { debounceTime, tap, combineLatest, EMPTY } from 'rxjs';
import { NotificationsStreamService } from '@vdms-hq/api-contract';
import { NotificationsDatasource } from './notifications.datasource';
import { NOTIFICATION_SUBSCRIPTION_ROUTES } from '../notification-subscription.routes';
import { PermissionService, Permission } from '@vdms-hq/activated-client';

@Injectable({ providedIn: 'root' })
export class NotificationsWebsocketService {
  private apiContractService = inject(NotificationsStreamService);
  private notificationsDatasource = inject(NotificationsDatasource);
  protected permissions = inject(PermissionService);

  get notificationCenterPage() {
    return window.location.href.includes(NOTIFICATION_SUBSCRIPTION_ROUTES.NOTIFICATIONS_PATH);
  }

  registerListeners() {
    return combineLatest([
      this.permissions.verifyWithOwnedPermissions$([Permission.BROWSE_NOTIFICATIONS]),
      this.apiContractService.connection$,
    ]).pipe(
      debounceTime(5000),
      tap(([allowed, conn]) => {
        if (!allowed) {
          return;
        }
        this.notificationCenterPage && this.notificationsDatasource.notificationsRefresh$.next(new Date().toString());
        this.notificationsDatasource.unreadRefresh$.next(new Date().toString());
      }),
    );
  }
}
