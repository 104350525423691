import { Injectable } from '@angular/core';
import { TranscriptionModel, TranscriptionService } from '@vdms-hq/api-contract';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TranscriptionModelsDialogComponent } from '../smart/transcription-models-dialog/transcription-models-dialog.component';
import { Observable, of } from 'rxjs';
import { TranscriptionToasts } from './transcription-toasts';
import { TranscriptionConfig, TranscriptionDataSourceService } from './transcription.data-source.service';
import { filterEmpty } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class TranscriptionGeneratorService {
  modelsSelectOptions$ = this.transcriptionService.listModels().pipe(
    map((models) => models.filter((model) => model.context === 'transcribe')),
    map((models) =>
      models.map((model) => ({
        key: model.name?.match(/\b\d+x\b/)?.[0] ?? '',
        label: model.name,
      })),
    ),
  );

  constructor(
    private readonly transcriptionService: TranscriptionService,
    private readonly transcriptionDataSourceService: TranscriptionDataSourceService,
    private readonly dialog: MatDialog,
    private readonly toastService: TranscriptionToasts,
  ) {}

  triggerModelSelection = (config: TranscriptionConfig) => {
    this.transcriptionDataSourceService.configure(config);
    this.dialog.open(TranscriptionModelsDialogComponent);
  };

  generateNewTranscription(selectedModel: string) {
    return this.transcriptionDataSourceService.assetId$.pipe(
      switchMap((assetId) =>
        this.transcriptionService.runTranscription(assetId, selectedModel).pipe(
          catchError(() => {
            this.toastService.popToast.TRIGGER_TRANSCRIPTION_FAILED();
            return of(null);
          }),
        ),
      ),
      tap(() => this.toastService.popToast.TRIGGER_TRANSCRIPTION_SUCCESS()),
    );
  }
}
