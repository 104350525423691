import { inject, Injectable, TrackByFunction } from '@angular/core';
import {
  PageableDataSource,
  SelectionManager,
  SortableDataSource,
  SortBy,
  SortDirection,
  SortEvent,
} from '@vdms-hq/shared';
import { TableAdvancedDataSource, UIConfirmationDialogService } from '@vdms-hq/ui';
import {
  LicensedPackage,
  LicensedPackageAssetGet,
  LicensePackagesService,
  PaginationAPIModel as Pagination,
} from '@vdms-hq/api-contract';
import { SinglePackageResolverService } from './single-license-package-resolver.service';
import { BehaviorSubject, EMPTY, Observable, switchMap, tap, combineLatest, withLatestFrom, shareReplay } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { LicensePackagesActionsService } from './license-packages-actions';
import { LicensePackagesRefreshService } from './license-packages-refresher.service';
import { StorageUrlService } from '@vdms-hq/storage';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { DataProviderService } from '@vdms-hq/selectors';
import { LicensePackageAssetViewModel } from './license-package-asset-view.model';
import { RouterParamsPagination } from '@vdms-hq/view-settings';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SingleLicensePackageDsService
  extends RouterParamsPagination
  implements TableAdvancedDataSource<LicensePackageAssetViewModel>, PageableDataSource, SortableDataSource
{
  private confirmationDialog = inject(UIConfirmationDialogService);
  private singlePackageResolverService: SinglePackageResolverService = inject(SinglePackageResolverService);
  private licensePackagesService: LicensePackagesService = inject(LicensePackagesService);
  private licensePackagesActionsService: LicensePackagesActionsService = inject(LicensePackagesActionsService);
  private licensePackageRefresher: LicensePackagesRefreshService = inject(LicensePackagesRefreshService);
  private dataProvider: DataProviderService = inject(DataProviderService);
  private storageUrlService: StorageUrlService = inject(StorageUrlService);
  private activatedClientService: ActivatedClientService = inject(ActivatedClientService);

  uuid$ = this.singlePackageResolverService.currentUuidDefinite$;
  isLoading$ = new BehaviorSubject<boolean>(true);
  isLoadingPackageDetails$ = new BehaviorSubject<boolean>(false);

  packageDetailsLoaded$ = new BehaviorSubject(false);

  refresh$ = this.licensePackageRefresher.refreshSingleLicensePackage$;

  sortDirection$ = new BehaviorSubject<SortDirection>('');
  sortBy$ = new BehaviorSubject<SortBy>(null);
  total$ = new BehaviorSubject<number>(0);
  emptyResults$ = new BehaviorSubject<boolean>(false);
  failedToLoad$ = new BehaviorSubject<boolean>(false);

  allData$ = combineLatest([
    this.refresh$,
    this.packageDetailsLoaded$,
    this.pageIndex$,
    this.pageSize$,
    this.sortDirection$,
    this.sortBy$,
  ]).pipe(
    withLatestFrom(this.uuid$),
    tap(() => this.isLoading$.next(true)),
    switchMap(([[, detailsLoaded, pageIndex, pageSize, sortDirection, sortBy], uuid]) => {
      if (detailsLoaded === false) return EMPTY;
      const pagination = {
        page: pageIndex,
        perPage: pageSize,
        orderBy: sortBy,
        orderDir: sortDirection,
      };
      return this.licensePackagesService.getItems(uuid, Pagination.create({ ...pagination })).pipe(
        tap((data) => {
          this.total$.next(data.total);
          this.emptyResults$.next(data.total === 0);
        }),
        map((res) => {
          const data = res.data.map((item: LicensedPackageAssetGet) => {
            return LicensePackageAssetViewModel.fromLicensePackageItem(item, {
              dataProvider: this.dataProvider,
              storageUrlService: this.storageUrlService,
              activatedClientService: this.activatedClientService,
            });
          });

          return data;
        }),

        tap(() => this.isLoading$.next(false)),
        catchError(() => {
          this.licensePackagesActionsService.popToast.GET_ITEMS_FAILURE();
          this.failedToLoad$.next(true);
          this.isLoading$.next(false);
          return EMPTY;
        }),
      );
    }),
    shareReplay(1),
  );

  licensedPackage$: Observable<LicensedPackage> = combineLatest([
    this.uuid$,
    this.viewSettingsService.perPageUserSettings$,
    this.refresh$,
  ]).pipe(
    tap(() => this.isLoadingPackageDetails$.next(true)),
    switchMap(([uuid, perPageUser]) => {
      this.changePerPageSize$.next(perPageUser);
      return this.licensePackagesService.getOne(uuid).pipe(
        tap(() => {
          this.isLoadingPackageDetails$.next(false);
          this.packageDetailsLoaded$.next(true);
        }),
        catchError((err) => this.#errorHandler(err)),
      );
    }),
  );
  connection$ = this.allData$;

  selection: SelectionManager<LicensePackageAssetViewModel> = new SelectionManager<LicensePackageAssetViewModel>(
    this,
    (item) => item.context.uuid,
  );

  pagination$?: BehaviorSubject<{ page: string; perPage: string } | null> | undefined;
  trackBy?: TrackByFunction<LicensePackageAssetViewModel>;

  sortChange($event: SortEvent): void {
    this.sortDirection$.next($event.direction);
    this.sortBy$.next($event.active);
  }

  #errorHandler(err: HttpErrorResponse): Observable<never> {
    this.isLoadingPackageDetails$.next(false);
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if ('data' in err.error && uuidRegex.test(err.error?.data)) {
      return this.#popClientChangeDialog(err);
    }
    return EMPTY;
  }

  #popClientChangeDialog(err: HttpErrorResponse) {
    return this.confirmationDialog
      .open({
        title: 'Please confirm',
        message: 'The resource exists in a different client, would you like to switch there?',
      })
      .pipe(
        take(1),
        map((dialogResponse) => ({
          isConfirmed: dialogResponse,
        })),
        tap(({ isConfirmed }) => {
          if (!isConfirmed) {
            this.router.navigate(['/']);
          }
        }),
        filter(({ isConfirmed }) => isConfirmed),
        tap(() => {
          this.activatedClientService.setActivatedClientId(err.error.data);
          this.refresh$.next(!this.refresh$.value);
        }),
        switchMap(() => EMPTY),
      );
  }
}
