<vdms-hq-ui-dialog-wrapper [size]="'wide'">
  <div title>
    <span class="title">{{
      (hideUsersList$ | async) ? ('pages.lists.select_users_bbc' | translate) : ('pages.lists.select_users' | translate)
    }}</span>
  </div>
  <div content class="relative">
    <vdms-hq-ui-loader *ngIf="isLoading; else contentLoaded"></vdms-hq-ui-loader>
    <ng-template #contentLoaded>
      @if (usersAddSelectableConfig) {
        <vdms-hq-ui-selectable-tiles
          [dataSource]="usersShareDS"
          [filters]="usersShareDS.filters"
          [config]="usersAddSelectableConfig"
          (updateSelectedListEmit)="usersAddSelectableConfig.selectedList.data = $event"
          [selected]="selectedControl.value"
          (selectedChangeEmit)="selectedControl.setValue($event)"
          (updateConfigEmit)="usersAddSelectableConfig = $event; saveViewSettings($event.searchView.showSearch)"
          (emptyActionButtonClick)="createNewUser()"
        ></vdms-hq-ui-selectable-tiles>
      }
    </ng-template>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button
      (click)="close()"
      [disabled]="isSaving"
      [sharedE2eId]="'dialog-close-button'"
      color="secondary"
      >{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button
    >
    <vdms-hq-ui-button
      (click)="close(true)"
      [loading]="isSaving"
      [disabled]="selectedControl.invalid || (selectedControl.value.length === 0 && data.shared_to_users?.length === 0)"
      [sharedE2eId]="'dialog-save-button'"
      color="primary"
      >{{ 'common.global.save' | translate }}</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
