import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { Asset, AssetApiService, AudioTrack, SimpleType } from '@vdms-hq/api-contract';
import { transformLegacyConfigToNew } from '@vdms-hq/asset-results';
import { FieldsFetcherService, InputDefinitionModel } from '@vdms-hq/fields';
import { FormBuilderService } from '@vdms-hq/dynamic-form';
import {
  AllListComponentConfig,
  AssetViewComponent,
  AssetViewConfig,
  FieldConfig,
  isFormField,
} from '@vdms-hq/firebase-contract';
import {
  AUDIO_FRAME_RAW,
  AUDIO_OFFSET,
  isSupportedExtension as isSupportedExtensionByUIPlayer,
  PlayerConfiguration,
  PlayerService,
  SpritePreviewSettings,
  VIDEO_OFFSET_DEFAULT,
} from '@vdms-hq/player';
import { FileDestination, filterEmpty, isEmbargoActive } from '@vdms-hq/shared';
import { StorageUrlService } from '@vdms-hq/storage';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { TranscriptionDataSourceService } from '@vdms-hq/transcription';
import {
  BreadCrumb,
  FileNotYetAvailable,
  isSupportedExtension as isSupportedExtensionByUIPreview,
  PreviewNotAvailable,
  UIConfirmationDialogService,
} from '@vdms-hq/ui';
import { BehaviorSubject, firstValueFrom, Observable, of, shareReplay, switchMap } from 'rxjs';
import { catchError, filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { AssetViewModel, AvailableAssetViewConfig, FilePreviewModel } from '../models';
import { MetadataListConfigParams } from '@vdms-hq/metadata-list';

type LoadAssetError =
  | {
      errorType: 'ConfigNotFound' | 'InternalServerError' | 'Unknown';
    }
  | { errorType: 'AvailableInDifferentClient'; clientId?: string };

@Injectable({ providedIn: 'root' })
export class ActiveAssetService implements Resolve<string | null> {
  private fieldsFetcherService = inject(FieldsFetcherService);
  private storageUrlService = inject(StorageUrlService);
  private activatedClientService = inject(ActivatedClientService);
  private assetService = inject(AssetApiService);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private router = inject(Router);
  private formBuilderService = inject(FormBuilderService<AssetViewModel>);
  private playerService = inject(PlayerService);
  private transcription = inject(TranscriptionDataSourceService);

  private DEFAULT_CONFIG_KEY = 'DEFAULT_CONFIG_KEY';
  #currentParams$ = new BehaviorSubject<{
    assetId: string;
    pageViewConfigKey: string | null;
  } | null>(null);
  #currentParamsDefinite$: Observable<{
    assetId: string;
    pageViewConfigKey: string | null;
  }> = this.#currentParams$.pipe(filterEmpty());
  currentIdDefinite$ = this.#currentParamsDefinite$.pipe(map((data) => data.assetId));
  #isLoading$ = new BehaviorSubject<boolean>(false);
  isLoading$ = this.#isLoading$.asObservable();

  #currentAssetViewOrError$: Observable<AssetViewModel | LoadAssetError> = this.#currentParamsDefinite$.pipe(
    tap(() => this.#isLoading$.next(true)),
    switchMap(({ assetId, pageViewConfigKey }) =>
      this.assetService.get(assetId).pipe(
        map((asset) => ({
          asset,
          pageViewConfigKey,
        })),
      ),
    ),
    withLatestFrom(this.activatedClientService.clientDefinite$),
    switchMap(([{ asset, pageViewConfigKey }, client]) => {
      return this.fieldsFetcherService.getAssetViewConfiguration$(pageViewConfigKey ?? 'default').pipe(
        map((assetFieldsConfig) => {
          return {
            asset,
            pageViewConfigKey,
            assetFieldsConfig,
            client,
          };
        }),
        take(1),
      );
    }),
    map(({ asset, assetFieldsConfig, pageViewConfigKey, client }) => {
      // todo move to fields fetcher
      const parsedConfigs = this.#matchConfig(client.assetView, pageViewConfigKey ?? null, asset);

      if (pageViewConfigKey == 'launchpad_preview') {
        parsedConfigs.enabled.elements = parsedConfigs.enabled.elements.filter((e) => e.type == 'field');
      }

      const viewConfig = transformLegacyConfigToNew(parsedConfigs.enabled);

      const enabledFields = viewConfig.elements.filter(isFormField).map((field) => (<FieldConfig>field).definition);

      const enabledFieldsDefinitions = assetFieldsConfig.definitions.filter((definition) =>
        enabledFields.includes(definition.id),
      );

      const definitions = this.#transformFieldDefinitions(asset, enabledFieldsDefinitions);

      return this.#transformAsset(asset, viewConfig, definitions, parsedConfigs.available);
    }),
    catchError((error: Error | HttpErrorResponse) => {
      switch (true) {
        case 'status' in error && error.status === 500:
          return of(<LoadAssetError>{ errorType: 'InternalServerError' });
        case 'status' in error && error.status === 400:
          return of(<LoadAssetError>{
            errorType: 'AvailableInDifferentClient',
            clientId: (<HttpErrorResponse>error)?.error?.data,
          });
        case error.message === 'ConfigNotFound':
          return of(<LoadAssetError>{ errorType: 'ConfigNotFound' });
      }
      return of(<LoadAssetError>{ errorType: 'Unknown' });
    }),
    tap(() => this.#isLoading$.next(false)),
    shareReplay(1),
  );

  containersWithGroupedElements$ = this.#currentParamsDefinite$.pipe(
    switchMap(({ pageViewConfigKey }) =>
      this.fieldsFetcherService
        .getAssetViewConfiguration$(pageViewConfigKey ?? 'default', true)
        .pipe(map((config) => config.getContainersWithGroupedElements())),
    ),
  );

  groupedElementsForFirstContainer$ = this.containersWithGroupedElements$.pipe(
    map((containers) => containers[0]?.elements ?? []),
  );

  currentAssetView$: Observable<AssetViewModel | null> = this.#currentAssetViewOrError$.pipe(
    map((response) => ('errorType' in response ? null : response)),
    filterEmpty(),
  );

  currentAssetType$: Observable<string> = this.currentAssetView$.pipe(
    filter((asset) => asset !== null),
    map((asset) => asset!.general.type),
  );

  currentFile$: Observable<FilePreviewModel | FileNotYetAvailable | PreviewNotAvailable> = this.currentAssetView$.pipe(
    filterEmpty(),
    map((asset: AssetViewModel) => {
      try {
        if (asset.general.combined_status === 'Placeholder') {
          throw 'Awaiting file';
        } else if (asset.general.combined_status === 'ERROR') {
          throw 'Error occurred while processing asset';
        } else if (!['Ready', 'Active', 'Cold'].includes(asset.general.combined_status) || !asset.general.file_url) {
          throw 'Asset is processing';
        } else if (
          asset.general.combined_status === 'Cold' &&
          !['video', 'audio', 'image'].includes(asset.general.type)
        ) {
          throw 'Asset is in cold storage';
        }

        let url = this.storageUrlService.updateCdn(
          asset.general.is_virtual
            ? asset.general.file_url.replace(asset.general.uuid, asset.general.parent)
            : asset.general.file_url,
        );

        if (asset.general.type !== 'video' && asset.general.type !== 'audio') {
          url = asset.general.preview_url ? this.storageUrlService.updateCdn(asset.general.preview_url) : url;
        }

        const bigThumbnail = asset.general.thumbnails?.find((th) => {
          return th.type === 'big';
        });

        if (asset.general.type === 'image' && bigThumbnail) {
          url = this.storageUrlService.updateCdn(bigThumbnail.url);
        }

        const file = FileDestination.fromUrl(url ?? '', true);

        const isAudio = asset.general.type === 'audio';

        if (file && isSupportedExtensionByUIPreview(file.extension)) {
          let includeCookiesInRequest = true;
          const isWasabiCdn = file.url.includes('wasabisys');
          if (isWasabiCdn && !isAudio) {
            includeCookiesInRequest = false;
          }

          const waveForm = asset.general.thumbnails?.find((th) => {
            return th.type === 'waveform-json';
          });

          return {
            type: 'preview',
            file: file,
            includeCookiesInRequest,
            pdfWithToolbar: true,
            pdfWithNavPanes: true,
            waveformUrl: waveForm?.url,
          };
        }

        if (file && isSupportedExtensionByUIPlayer(file.extension)) {
          const ratio =
            Number(asset?.file_metadata?.video?.screen_size?.width) /
            Number(asset?.file_metadata?.video?.screen_size?.height);
          const tapeThumbnail = asset.general.thumbnails.find((thumb) => {
            return thumb.type === 'sprite';
          });
          let thumbnailsSprite: SpritePreviewSettings | null = null;
          if (tapeThumbnail?.url && tapeThumbnail?.details?.interval && ratio) {
            const fixedWidth = 180;
            let calculatedHeight = fixedWidth / Number(ratio);

            if (ratio < 1) {
              calculatedHeight = fixedWidth * Number(ratio);
            }

            thumbnailsSprite = {
              thumbnailWidth: fixedWidth,
              thumbnailHeight: calculatedHeight,
              second: Number(tapeThumbnail.details.interval),
              spriteUrl: tapeThumbnail.url,
            };
          }
          const advanced = asset.viewConfig.components?.includes(AssetViewComponent.ADVANCED_PLAYER);
          const dolbyRaspProxy = asset.viewConfig.components?.includes(AssetViewComponent.ADVANCED_PLAYER_DOLBY_RASP);
          const dolbyRaspVideoSource = asset.viewConfig.components?.includes(
            AssetViewComponent.ADVANCED_PLAYER_DOLBY_RASP_SOURCE,
          );
          const preferredAudioIndex = this.#retrievePreferredAudioIndex(asset.audioTracks);

          const waveForm = asset.general.thumbnails?.find((th) => {
            return th.type === 'waveform-json';
          });

          const playerType = (): 'advanced' | 'simple' | 'audio' => {
            if (isAudio) {
              return 'audio';
            }
            if (advanced || dolbyRaspProxy || dolbyRaspVideoSource) {
              return 'advanced';
            }
            return 'simple';
          };

          const playerConfig = <PlayerConfiguration>{
            playerType: playerType(),
            file: file,
            framerate: Framerate.fromValue(asset.framerate?.value, asset.framerate?.isDrop),
            offset: asset.currentOffset,
            askToFillOffset: true,
            includeCookiesInRequest: true,
            clip: asset.clip,
            thumbnailsSprite,
            subtitles: asset.general.subtitles,
            withCredentials: true,
            waveformsVtt: isAudio && waveForm ? Array({ url: waveForm.url, name: waveForm.type }) : null,
            preferredAudioIndex,
            isAdvancedV2: asset.viewConfig.components?.includes(AssetViewComponent.ADVANCED_PLAYER_V2),
            audioTracks: asset.audioTracks,
            file_metadata: asset.file_metadata,
          };

          const fileDestination = FileDestination.fromUrl(asset.general.download_url);

          if (fileDestination && dolbyRaspProxy) {
            return {
              type: 'player-dolby-rasp',
              masterFile: fileDestination,
              originalPlayerConfig: playerConfig,
            };
          }

          if (fileDestination && dolbyRaspVideoSource) {
            return {
              type: 'player-dolby-rasp-source',
              masterFile: fileDestination,
              originalPlayerConfig: playerConfig,
            };
          }

          return {
            type: 'player',
            ...playerConfig,
          };
        }

        const thumbnail = asset.general.thumbnails.find((a) => {
          return a.type === 'big';
        });

        const thumbFile = thumbnail
          ? FileDestination.fromUrl(this.storageUrlService.updateCdn(thumbnail.url) ?? '', true)
          : null;
        if (thumbnail && thumbFile) {
          return {
            type: 'preview',
            file: thumbFile,
            includeCookiesInRequest: true,
            pdfWithToolbar: true,
            pdfWithNavPanes: true,
          };
        }

        throw 'File is not supported';
      } catch (e) {
        switch (e) {
          case 'Awaiting file':
          case 'Asset is processing':
            return new FileNotYetAvailable(e);
          case 'Error occurred while processing asset':
          case 'File is not supported':
            return new PreviewNotAvailable(e);
          case 'Invalid extension':
            return new PreviewNotAvailable('File extension is invalid');
          case 'Asset is in cold storage':
            return new PreviewNotAvailable(e);
        }
        return new PreviewNotAvailable('File url is invalid');
      }
    }),
  );

  error$: Observable<LoadAssetError | null> = this.#currentAssetViewOrError$.pipe(
    map((response) => ('errorType' in response ? response : null)),
    filterEmpty(),
  );

  components$ = this.currentAssetView$.pipe(
    filter((asset) => asset !== null),
    map((asset) => asset!.viewConfig.components),
  );
  containers$ = this.currentAssetView$.pipe(
    filter((asset) => asset !== null),
    map((asset) => asset!.viewConfig.containers),
  );
  elements$ = this.currentAssetView$.pipe(
    filter((asset) => asset !== null),
    map((asset) => asset!.viewConfig.elements),
  );
  sectionTitles$ = this.currentAssetView$.pipe(
    filter((asset) => asset !== null),
    map((asset) => asset!.viewConfig.sectionTitles),
  );
  isEmbargoInactive$ = this.currentAssetView$.pipe(map((asset) => !isEmbargoActive(asset?.general?.embargoed_to)));

  #listenTo400Error = this.error$
    .pipe(filter((error) => error?.errorType === 'AvailableInDifferentClient'))
    .subscribe((error) => {
      const id = error && 'clientId' in error && error.clientId;
      id && this.#popAskToChangeClient(id);
    });

  #listenAndLoadComponentsDeps = this.currentAssetView$
    .pipe(
      filter((asset) => asset !== null),
      map((asset) => asset as AssetViewModel),
    )
    .subscribe((asset) => {
      //todo remove formBuilderService when formBuilderComponent replaces it
      this.formBuilderService.set(asset!.fieldsDefinitions, asset);

      if (asset.framerate) {
        this.transcription.configure({
          assetId: asset.uuid,
          framerate: asset.framerate,
        });
      }
    });

  #matchConfig = (
    configs: Record<string, AssetViewConfig>,
    routeViewType: string | null,
    asset: Asset,
  ): {
    enabled: AssetViewConfig;
    available: AvailableAssetViewConfig[];
  } => {
    const forceUseKeyIfAvailable = localStorage.getItem(this.DEFAULT_CONFIG_KEY);
    const assetType = asset.general.type;
    const prioritizedConfigs = [];

    if (routeViewType) {
      prioritizedConfigs.push(`${routeViewType}:${assetType}`, `${routeViewType}`);
    }

    prioritizedConfigs.push(`default:${assetType}`, 'default');

    for (const firstConfigKey of prioritizedConfigs) {
      if (configs && firstConfigKey in configs) {
        const firstConfig = configs[firstConfigKey];

        const secondConfigKey = `${firstConfigKey}_2`;
        const secondConfig = configs[secondConfigKey];

        const canUseProvidedKey =
          forceUseKeyIfAvailable && [firstConfigKey, secondConfigKey].includes(forceUseKeyIfAvailable);

        const defaultKey = secondConfig?.default ? secondConfigKey : firstConfigKey;

        const activeKey = canUseProvidedKey ? forceUseKeyIfAvailable : defaultKey;

        const enabled = configs[activeKey] ?? configs[firstConfigKey];

        const available: AvailableAssetViewConfig[] = [];
        available.push({
          name: firstConfig.name,
          isActive: firstConfigKey === activeKey,
          key: firstConfigKey,
          config: firstConfig,
        });

        if (secondConfig) {
          available.push({
            name: secondConfig.name,
            isActive: secondConfigKey === activeKey,
            key: secondConfigKey,
            config: secondConfig,
          });
        }

        return {
          available,
          enabled,
        };
      }
    }

    throw Error('ConfigNotFound');
  };

  #transformFieldDefinitions(asset: Asset, fieldsDefinitions: InputDefinitionModel[]) {
    const readOnlyForNonVideo = ['playerOffset', 'metadataFramerate'];
    const isVideo = asset.general.type == 'video';
    return fieldsDefinitions.map((definition) => {
      if (!isVideo && readOnlyForNonVideo.includes(definition.id)) {
        return {
          ...definition,
          input: {
            ...definition.input,
            readonly: true,
          },
        };
      } else {
        return definition;
      }
    });
  }

  #transformAsset(
    asset: Asset,
    viewConfig: AssetViewConfig,
    fieldsDefinitions: InputDefinitionModel[],
    availableViewConfigs: AvailableAssetViewConfig[],
  ): AssetViewModel {
    const titlePattern = viewConfig.titlePattern ?? 'Preview - {{assetName}}';
    const offsetRaw = asset.user_media_data.player_offset ?? asset.file_metadata.player_offset;
    const framerate = this.#transformFramerate(asset);

    const isAudio = asset.general.type === 'audio';
    const currentOffset = isAudio
      ? AUDIO_OFFSET
      : offsetRaw
        ? Timecode.fromTimecode(offsetRaw, framerate, false)
        : VIDEO_OFFSET_DEFAULT;

    const assetTitle = asset.core?.episode_name || asset.general?.original_filename || '';
    const breadcrumb: BreadCrumb[] = [
      // default home breadcrumb for asset details
      {
        name: 'Assets',
        path: ['/', 'browse'],
        clickable: true,
      },
      {
        name: assetTitle,
      },
    ];

    const ext_title_info = asset?.ext_title_info;
    if (ext_title_info) {
      ext_title_info.tx_date = this.#dateWithUserOffset(ext_title_info.tx_date);
    }

    const programmeNumber = asset?.core?.programme_number || '';
    const tapeNo = asset?.additional.tape_no || '';
    const originalFilename = asset?.general.original_filename || '';
    const clientAssetId = asset?.general.client_asset_id || '';
    const clientRef = asset?.core.client_ref || '';
    const vidaNumber = asset?.additional.vida_number || '';

    const header = titlePattern
      .replace('{{assetName}}', assetTitle)
      .replace('{{programmeNumber}}', programmeNumber)
      .replace('{{tapeNo}}', tapeNo)
      .replace('{{originalFilename}}', originalFilename)
      .replace('{{clientAssetId}}', clientAssetId)
      .replace('{{clientRef}}', clientRef)
      .replace('{{vidaNumber}}', vidaNumber);

    const duration = this.#transformVirtualAssetDuration(asset);

    const general: Omit<Asset['general'], 'timecode_in' | 'duration' | 'timecode_out' | 'mediainfo'> & {
      timecode_in: Timecode | null;
      timecode_out: Timecode | null;
      duration: Timecode | null;
      collections: SimpleType[];
    } = {
      ...asset.general,
      subtitles: (asset?.general?.subtitles ?? []).map((subtitle) => ({
        ...subtitle,
        language: subtitle.language === 'Automatic subtitles' ? 'Transcription' : subtitle.language,
      })),
      duration: duration,
      timecode_in: Timecode.fromTimecode(asset.general.timecode_in, framerate, false),
      timecode_out: Timecode.fromTimecode(asset.general.timecode_out, framerate, false),
    };

    const metadataListAdminSettings = viewConfig.elements.find((element) => element.type === 'metadata_list') as
      | AllListComponentConfig
      | undefined;

    const metadataListConfig: MetadataListConfigParams = {
      assetUuid: asset.general.uuid,
      framerate: framerate ?? Framerate.default(),
      defaultStream: metadataListAdminSettings?.settings.defaultStream ?? [],
      enabledStreams: metadataListAdminSettings?.settings.enabledStreams ?? [],
      enabledComponents: viewConfig.components,
      locks: asset.locks ?? [],
    };

    return {
      ...asset,
      ext_title_info,
      uuid: asset.general.uuid,
      fieldsDefinitions,
      framerate,
      currentOffset,
      breadcrumb,
      clip:
        general.timecode_in && general.timecode_out
          ? {
              startAt: general.timecode_in,
              endAt: general.timecode_out,
            }
          : null,
      general,
      header,
      viewConfig,
      availableViewConfigs,
      collectionTiles: (asset.general.collections_details ?? []).map((collection) => ({
        ...collection,
        tile: {
          id: collection.uuid,
          header: collection.name,
          background: collection.custom_cover_path,
          backgroundHover: collection.custom_cover_path,
        },
      })),
      metadataListConfig,
    };
  }

  resolve(route: ActivatedRouteSnapshot) {
    const assetId = route.paramMap.get('assetId') as string;
    const pageViewConfigKey = route.paramMap.get('pageView') as string | null;

    if (!assetId) {
      return null;
    }

    this.#currentParams$.next({ assetId, pageViewConfigKey });
    return assetId;
  }

  changeViewKey(manuallySelectedKey: string) {
    localStorage.setItem(this.DEFAULT_CONFIG_KEY, manuallySelectedKey);
    window.location.reload();
  }

  unResolve() {
    this.#currentParams$.next(null);
    //todo remove formBuilderService when formBuilderComponent replaces it
    this.formBuilderService.unset(false);
    this.playerService.unConfigure();
  }

  async #popAskToChangeClient(clientId: string) {
    const isConfirmed = await firstValueFrom(
      this.confirmationDialog.open({
        title: 'Please confirm',
        message: 'The resource exists in a different client, would you like to switch there?',
      }),
    );
    if (!isConfirmed) {
      await this.router.navigate(['/']);
      return;
    } else {
      await firstValueFrom(this.activatedClientService.setActivatedClientId$(clientId));
      const assetId = this.#currentParams$.value?.assetId ?? '';
      const pageViewConfigKey = this.#currentParams$.value?.pageViewConfigKey ?? null;
      this.#currentParams$.next({ assetId, pageViewConfigKey });
      await this.router.navigateByUrl(`/asset/${assetId}`, { skipLocationChange: true }).then(() => {
        this.router.navigate(['/asset', assetId], { replaceUrl: true });
        window.location.reload();
      });
    }
  }

  #transformVirtualAssetDuration(asset: Asset) {
    const framerate = Number(asset?.file_metadata?.framerate)
      ? Framerate.fromValue(asset?.file_metadata?.framerate)
      : Framerate.default();

    if (!asset.general.is_virtual || asset?.file_metadata?.duration) {
      return Timecode.fromSeconds(asset?.file_metadata?.duration, framerate);
    }

    if (!asset?.general?.timecode_in || !asset?.general?.timecode_out) {
      return null;
    }

    const tcIn = Timecode.fromTimecode(asset?.general?.timecode_in, framerate, false)?.countSeconds() ?? 0;
    const tcOut = Timecode.fromTimecode(asset?.general?.timecode_out, framerate, false)?.countSeconds() ?? 0;
    const duration = Timecode.fromSeconds(tcOut - tcIn, framerate, false);

    return duration ?? null;
  }

  #retrievePreferredAudioIndex(audioTracks: AudioTrack[]) {
    if (audioTracks.length === 0) {
      return null;
    }

    let audioTrackCount = 0;

    for (const audioTrack of audioTracks) {
      const channels = audioTrack.channels ?? [];
      audioTrackCount = audioTrackCount + channels.length;

      if (audioTrack.layout === '2.0') {
        // first 2.0 audio
        return audioTrackCount === 0 ? 0 : Math.floor(audioTrackCount / 2) - 1;
      }
    }

    return null;
  }

  #transformFramerate(asset: Asset) {
    const isAudio = asset.general.type === 'audio';
    const droppedFrames = Boolean(asset.file_metadata.dropped_frames);

    if (isAudio) {
      return Framerate.fromValue(AUDIO_FRAME_RAW);
    }

    const fileMetadataFramerate = Number(asset.file_metadata.framerate);
    if (fileMetadataFramerate > 0) {
      return Framerate.fromValue(fileMetadataFramerate, droppedFrames);
    }

    const additionalFramerate = Number(asset.additional.framerate);
    if (additionalFramerate > 0) {
      return Framerate.fromValue(additionalFramerate, droppedFrames);
    }

    return null;
  }

  #dateWithUserOffset = (date: string) => {
    const newDate = new Date(date);
    const userTimezoneOffset = newDate.getTimezoneOffset() * 60000;
    return new Date(newDate.getTime() + userTimezoneOffset).toISOString();
  };

  setActiveAsset(assetId: string, pageViewConfigKey?: string) {
    this.#currentParams$.next({
      assetId,
      pageViewConfigKey: this.#currentParams$.value?.pageViewConfigKey ?? pageViewConfigKey ?? null,
    });
  }
}
