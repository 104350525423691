import { Injectable, inject } from '@angular/core';
import { PermissionService, Permission } from '@vdms-hq/activated-client';
import { map, Observable, tap, combineLatest } from 'rxjs';
import { MultipleViewConfiguration } from '@vdms-hq/ui';
import { PolicyService, VidaAppTypes, VidaAppType, ClientUserAdminView, SupplierService } from '@vdms-hq/api-contract';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import { ValueFormat, SelectOption, PillColorEnum } from '@vdms-hq/shared';
import { ClientUserAdminActionService } from './client-user-admin-action.service';
import { FilterType } from '@vdms-hq/fields';

export type VidaAppTypesPills = Record<VidaAppType, PillColorEnum>;

export enum CLIENT_USER_ADMIN_EVENT_KEYS {
  ADD_USER = 'add_user',
  EDIT = 'edit',
  EDIT_SUPPLIER = 'edit_supplier',
  DELETE = 'delete',
  BULK_DELETE = 'bulk_delete',
  CANCEL = 'cancel',
}

@Injectable({ providedIn: 'root' })
export class ClientUserAdminConfigService {
  private permissionService = inject(PermissionService);
  private policyService = inject(PolicyService);
  private supplierService = inject(SupplierService);
  private actionService = inject(ClientUserAdminActionService);

  readonly viewConfiguration$ = combineLatest([
    this.permissionService.verifyWithOwnedPermissions$([Permission.USER_ADMIN_EDIT_POLICIES]),
    this.permissionService.verifyWithOwnedPermissions$([Permission.USER_ADMIN_DELETE]),
    this.permissionService.verifyWithOwnedPermissions$([Permission.USER_ADMIN_EDIT]),
  ]).pipe(
    map(([canEdit, canDelete, canEditSupplier]) => {
      this.actionService.startLoading();
      return [canEdit, canDelete, canEditSupplier];
    }),
    map<boolean[], MultipleViewConfiguration<ClientUserAdminView>>(([canEdit, canDelete, canAssignSupplier]) => ({
      tableAdvanced: {
        actions: [
          this.actionService.buildEditAction(canEdit),
          this.actionService.buildDeleteAction(canDelete),
          this.actionService.buildChangeSupplierAction(canAssignSupplier),
        ],
        columnsEnabled: [
          canEdit ? 'select' : '',
          'name',
          'email',
          'application_access',
          'policies',
          'suppliers',
          'teams',
          'actions',
        ],
        columns: [
          {
            id: 'select',
            type: 'select',
          },
          {
            id: 'name',
            label: 'Name',
            valuePath: 'name',
            sortable: true,
            sortObjectPath: 'user.displayName',
          },
          {
            id: 'email',
            label: 'Email',
            valuePath: 'email',
            sortable: true,
            sortObjectPath: 'user.emailAddress',
          },
          {
            id: 'application_access',
            label: 'Application Access',
            valuePath: 'app_accesses',
            sortable: false,
            viewFormat: {
              pills: VidaAppTypes.reduce((pills, app) => {
                pills[app] = PillColorEnum.ColdBlue;
                return pills;
              }, {} as VidaAppTypesPills),
            },
          },
          {
            id: 'policies',
            label: 'Policies',
            valuePath: 'policiesNames',
            sortable: false,
            foldValues: true,
          },
          {
            id: 'suppliers',
            label: 'Suppliers',
            valuePath: 'supplierName',
            sortable: true,
            sortObjectPath: 'supplier.displayName',
          },
          {
            id: 'teams',
            label: 'Teams',
            valuePath: 'teamsNames',
            sortable: false,
            foldValues: true,
          },
          {
            id: 'actions',
            type: 'actions',
          },
        ],
      },
    })),
    tap(() => this.actionService.stopLoading()),
  );

  filtersConfig$: Observable<DynamicFilterInput[]> = combineLatest([
    this.supplierService.getSuppliers(),
    this.policyService.getSimplePolicies(),
  ]).pipe(
    map(([suppliers, policies]) => {
      this.actionService.startLoading();
      const policiesOptions = policies
        .map((policy) => ({ key: policy.uuid, label: policy.name }) as SelectOption)
        .filter((policy) => Boolean(policy.key));
      policiesOptions.unshift({ key: '', label: 'All' });

      const supplierOptions = suppliers
        .map((supplier) => ({ key: supplier.uuid, label: supplier.name }) as SelectOption)
        .filter((supplier) => Boolean(supplier.key));
      supplierOptions.unshift({ key: '', label: 'All' });

      return [
        {
          id: 'text',
          label: 'Name or email',
          format: ValueFormat.AS_IS,
          filters: {
            objectPath: 'text',
            validFormat: 'keyword',
            type: FilterType.MASTER_TEXT,
          },
        },
        {
          id: 'app',
          label: 'Application Access',
          format: ValueFormat.AS_IS,
          filters: {
            objectPath: 'app',
            validFormat: 'keyword',
            type: FilterType.SELECTOR,
          },
          selectOptions: [
            { key: '', label: 'All' },
            { key: 'vida', label: 'VIDA' },
            { key: 'launchpad', label: 'LAUNCHPAD' },
            { key: 'storefront', label: 'STOREFRONT' },
            { key: 'connect2', label: 'CONNECT2' },
          ],
        },
        {
          id: 'policy',
          label: 'Policy',
          format: ValueFormat.AS_IS,
          filters: {
            objectPath: 'policies',
            validFormat: 'keyword',
            type: FilterType.SELECTOR,
          },
          selectOptions: policiesOptions,
        },
        {
          id: 'supplier',
          label: 'Supplier',
          format: ValueFormat.AS_IS,
          filters: {
            objectPath: 'supplier',
            validFormat: 'keyword',
            type: FilterType.SELECTOR,
          },
          selectOptions: supplierOptions,
        },
      ] as DynamicFilterInput[];
    }),
    tap(() => this.actionService.stopLoading()),
  );
}
