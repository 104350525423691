import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiEmptyResponse, ApiPaginatedResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { SearchParams } from '../parameters/search-params';
import { DeliveryUploadJobStatus } from '../upload-job';
import {
  AddMandatoryFieldsType,
  DeliveryPack,
  DeliveryPackGetFlat,
  DeliveryPackPatch,
  DeliveryPackPost,
  EditMandatoryFieldsType,
  ExportDataSelector,
  MandatoryField,
} from './delivery-pack.model';
import { PaginationAPIModel } from '../pagination.model';
import { createErrorContext } from '../../error-handler';

const DELIVERY_PACK_URL = 'delivery-pack';
const MANDATORY_FIELDS_URL = 'mandatory-fields';

@Injectable({
  providedIn: 'root',
})
export class DeliveryPackApiService {
  constructor(private apiService: ApiService) {}

  list = (
    statuses: DeliveryUploadJobStatus[],
    fullTextSearch = '',
    pagination?: PaginationAPIModel,
    owner?: string | null,
    coordinatorView = false,
  ) => {
    let headers = new HttpHeaders({ 'Cache-Control': 'max-age=0' });
    let params = {};

    headers = pagination ? pagination?.applyToHeaders(headers) : headers;

    if (coordinatorView) {
      headers = headers.append('coordinator', '1');
    }

    if (statuses.length) {
      params = {
        status: statuses.join('|'),
        keyword: fullTextSearch,
      };
    }
    if (owner) {
      params = { ...params, owner };
    }

    const queryParams = new SearchParams(params).toQueryParams();

    return this.apiService.get<ApiPaginatedResponse<DeliveryPackGetFlat>>('delivery-packs', {
      headers,
      params: queryParams,
    });
  };

  get = (id: string) => {
    const context = createErrorContext(
      {
        '*': 'Request failed',
        'Invalid group': false,
      },
      'delivery-pack',
    );

    return this.apiService
      .get<ApiResponse<DeliveryPack>>(`${DELIVERY_PACK_URL}/${id}`, { context })
      .pipe(map(({ data }) => data));
  };

  export = (uuid: string, data: ExportDataSelector) =>
    this.apiService.post<ExportDataSelector, ApiEmptyResponse>(`${DELIVERY_PACK_URL}/${uuid}/export`, data);

  create = (data: DeliveryPackPost) =>
    this.apiService
      .post<DeliveryPackPost, ApiResponse<DeliveryPack>>(DELIVERY_PACK_URL, data)
      .pipe(map(({ data }) => data));

  update = (id: string, data: Partial<DeliveryPackPatch>, status?: string) => {
    return this.apiService
      .patch<
        Partial<DeliveryPackPatch>,
        ApiResponse<DeliveryPack>
      >(`${DELIVERY_PACK_URL}/${id}${status ? '/' + status : ''}`, data)
      .pipe(map(({ data }) => data));
  };

  delete = (id: string) => this.apiService.delete(`${DELIVERY_PACK_URL}/${id}`);

  addMandatoryFieldsType = (data: AddMandatoryFieldsType) =>
    this.apiService.post<AddMandatoryFieldsType, ApiResponse<MandatoryField>>(
      `${DELIVERY_PACK_URL}/${MANDATORY_FIELDS_URL}`,
      data,
    );

  updateMandatoryFields = (data: EditMandatoryFieldsType, uuid: string) =>
    this.apiService.patch<EditMandatoryFieldsType, ApiResponse<MandatoryField>>(
      `${DELIVERY_PACK_URL}/${MANDATORY_FIELDS_URL}/${uuid}`,
      data,
    );

  getMandatoryFields = () =>
    this.apiService
      .get<ApiResponse<MandatoryField[]>>(`${DELIVERY_PACK_URL}/${MANDATORY_FIELDS_URL}`)
      .pipe(map(({ data }) => data));

  getMandatoryField = (uuid: string) =>
    this.apiService
      .get<ApiResponse<MandatoryField>>(`${DELIVERY_PACK_URL}/${MANDATORY_FIELDS_URL}/${uuid}`)
      .pipe(map(({ data }) => data));

  deleteMandatoryField = (uuid: string) =>
    this.apiService.delete(`${DELIVERY_PACK_URL}/${MANDATORY_FIELDS_URL}/${uuid}`);
}
