import { Injectable, inject } from '@angular/core';
import {
  FilenameConventionsService,
  GetFilenameConvention,
  PostFilenameConvention,
  SimpleAnalyzeMetadataJobInterface,
} from '@vdms-hq/api-contract';
import { map } from 'rxjs/operators';
import { StatsConfig, StatsType } from '@vdms-hq/ui';

@Injectable({ providedIn: 'root' })
export class MetadataRecognitionService {
  private filenameConventionsService = inject(FilenameConventionsService);

  filenameConventions$ = this.filenameConventionsService.getAll();
  analyzeMetadataRecognition$ = this.filenameConventions$.pipe(
    map<GetFilenameConvention[], SimpleAnalyzeMetadataJobInterface[]>((conventions) =>
      conventions
        .filter((convention) => convention.default_for.some((df) => df.default_for_type === 'analyze-metadata'))
        .map((convention) => ({
          uuid: convention.uuid,
          defaultFor: convention.default_for
            .filter((df) => df.default_for_type === 'analyze-metadata')
            .map((df) => df.asset_type),
        })),
    ),
  );

  noRecognition = new StatsConfig(StatsType.STATIC, 'Metadata recognition', 'insights', {
    majorData: '',
    description: 'No filename conventions found to run metadata recognition',
    minorData: '',
  });

  create = (data: PostFilenameConvention) => this.filenameConventionsService.create(data);
  delete = (uuid: string) => this.filenameConventionsService.delete(uuid);

  update = (uuid: string, data: PostFilenameConvention) => this.filenameConventionsService.edit(uuid, data);
}
