import { ValueFormat } from '@vdms-hq/shared';
import {
  COMBINED_STATUS,
  DELIVERY_STATUS,
  DOWNLOAD_STATUS,
  ORDER_TYPE,
  SORT_BY,
  STATUS_OPTIONS,
} from './orders-filters-provider';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import moment from 'moment-timezone';
import { FilterType } from '@vdms-hq/fields';

export const sharedPacksMetadata = [
  'sharedBy',
  'sharedWith',
  'totalAssets',
  'expiryDate',
  'totalSize',
  'usedDepartment',
  'comment',
];

export const mediaPulseMetadata = ['purchaseOrderNo', 'deliveryDate', 'status', 'comment'];

export const FILTER_DATE_DAYS_DURATION = 90;
export const dateInitial = (duration = FILTER_DATE_DAYS_DURATION) => ({
  from: moment(new Date()).subtract(duration, 'days'),
  to: moment(new Date()),
});

export const salesForceFiltersInitialValues = {
  keyword: '',
  sort: SORT_BY[0].key,
  status: 'all',
  deliveryStatus: null,
  downloadStatus: null,
  combinedStatus: null,
  ordersType: 'own',
  txDate: dateInitial(),
};

export const defaultInitialValues = (duration: number) => ({
  keyword: '',
  sort: SORT_BY[0].key,
  status: 'all',
  deliveryStatus: null,
  downloadStatus: null,
  combinedStatus: null,
  ordersType: 'own',
  txDate: dateInitial(duration),
});

export const sharedPacksInitialValues = {
  keyword: '',
  sort: SORT_BY[0].key,
  status: 'all',
  deliveryStatus: null,
  downloadStatus: null,
  combinedStatus: null,
  ordersType: 'shared-packs',
  txDate: dateInitial(),
};

export const approvalInitialValues = (duration: number) => ({
  keyword: '',
  sort: SORT_BY[0].key,
  status: 'pending',
  deliveryStatus: null,
  downloadStatus: null,
  combinedStatus: null,
  ordersType: null,
  txDate: dateInitial(duration),
});

export const salesForceFiltersApprovalValues = {
  keyword: '',
  sort: SORT_BY[0].key,
  status: 'pending',
  deliveryStatus: null,
  downloadStatus: null,
  combinedStatus: null,
  ordersType: null,
  txDate: dateInitial(),
};

export const mediaPulseFilters: DynamicFilterInput[] = [
  {
    id: 'orderNo',
    label: 'common.orders.filters.order_no',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'wo_desc',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'keyword',
    label: 'common.orders.filters.order_title',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'poNo',
    label: 'common.orders.filters.po_no',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'po',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'txDate',
    label: 'common.orders.filters.due_date',
    format: ValueFormat.DATE,
    filters: {
      validFormat: 'range',
      objectPath: 'delivery_date',
      type: FilterType.DATEPICKER_RANGE,
    },
    scope: ['other-orders'],
  },
];

export const defaultFiltersOnlyMine: DynamicFilterInput[] = [
  {
    id: 'keyword',
    label: '',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'status',
    label: '',
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'status',
      type: FilterType.RADIO_LIST,
    },
    selectOptions: STATUS_OPTIONS,
    scope: ['other-orders'],
  },
  {
    id: 'sort',
    label: 'common.orders.filters.sort',
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'sort',
      type: FilterType.SELECTOR,
    },
    selectOptions: [...SORT_BY],
    scope: ['other-orders'],
  },
];

export const defaultFilters: DynamicFilterInput[] = [
  {
    id: 'keyword',
    label: 'common.orders.filters.keyword',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'status',
    label: '',
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'status',
      type: FilterType.RADIO_LIST,
    },
    selectOptions: STATUS_OPTIONS,
    scope: ['other-orders'],
  },
  {
    id: 'ordersType',
    label: 'common.orders.filters.orders_type',
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'ordersType',
      type: FilterType.SELECTOR,
    },
    selectOptions: ORDER_TYPE,
    scope: ['other-orders'],
  },
  {
    id: 'sort',
    label: 'common.orders.filters.sort',
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'sort',
      type: FilterType.SELECTOR,
    },
    selectOptions: [...SORT_BY],
    scope: ['other-orders'],
  },
  {
    id: 'txDate',
    label: 'common.orders.filters.order_date',
    format: ValueFormat.DATE,
    filters: {
      validFormat: 'range',
      objectPath: 'delivery_date',
      type: FilterType.DATEPICKER_RANGE,
    },
    scope: ['other-orders'],
  },
];

export const salesForceFiltersOnlyMine: DynamicFilterInput[] = [
  {
    id: 'keyword',
    label: 'common.orders.filters.keyword_sales_force',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'deliveryStatus',
    label: 'common.orders.filters.delivery_status',
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'deliveryStatus',
      type: FilterType.SELECTOR,
    },
    selectOptions: DELIVERY_STATUS,
    scope: ['other-orders'],
  },
  {
    id: 'sort',
    label: 'common.orders.filters.sort',
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'sort',
      type: FilterType.SELECTOR,
    },
    selectOptions: [...SORT_BY],
    scope: ['other-orders'],
  },
  {
    id: 'txDate',
    label: 'common.orders.filters.order_date',
    format: ValueFormat.DATE,
    filters: {
      validFormat: 'range',
      objectPath: 'delivery_date',
      type: FilterType.DATEPICKER_RANGE,
    },
    scope: ['other-orders'],
  },
];

export const salesForceFilters: DynamicFilterInput[] = [
  {
    id: 'keyword',
    label: 'common.orders.filters.keyword_sales_force',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'deliveryStatus',
    label: 'common.orders.filters.delivery_status',
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'deliveryStatus',
      type: FilterType.SELECTOR,
    },
    selectOptions: DELIVERY_STATUS,
    scope: ['other-orders'],
  },
  {
    id: 'ordersType',
    label: 'common.orders.filters.orders_type',
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'ordersType',
      type: FilterType.SELECTOR,
    },
    selectOptions: ORDER_TYPE,
    scope: ['other-orders'],
  },
  {
    id: 'sort',
    label: 'common.orders.filters.sort',
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'sort',
      type: FilterType.SELECTOR,
    },
    selectOptions: [...SORT_BY],
    scope: ['other-orders'],
  },
  {
    id: 'txDate',
    label: 'common.orders.filters.order_date',
    format: ValueFormat.DATE,
    filters: {
      validFormat: 'range',
      objectPath: 'delivery_date',
      type: FilterType.DATEPICKER_RANGE,
    },
    scope: ['other-orders'],
  },
];

export const sharedPacksFilters: DynamicFilterInput[] = [
  {
    id: 'keyword',
    label: 'common.orders.filters.keyword_shared_pack',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'combinedStatus',
    label: 'common.orders.filters.combined_status',
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'combinedStatus',
      type: FilterType.SELECTOR,
    },
    selectOptions: COMBINED_STATUS,
    scope: ['other-orders'],
  },
  {
    id: 'sort',
    label: 'common.orders.filters.sort',
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'sort',
      type: FilterType.SELECTOR,
    },
    selectOptions: [...SORT_BY],
    scope: ['other-orders'],
  },
];
