import { Pipe, PipeTransform, inject } from '@angular/core';
import { POSSIBLE_KEYS, TRANSLATED_POSSIBLE_KEYS } from '@vdms-hq/api-contract';
import { Observable, map } from 'rxjs';
import { FieldsFetcherService } from '@vdms-hq/fields';

@Pipe({
  name: 'keyToTitle',
  standalone: true,
})
export class KeyToTitlePipe implements PipeTransform {
  #fieldsFetcherService = inject(FieldsFetcherService);

  transform(value: (typeof POSSIBLE_KEYS)[number]): Observable<string> {
    const translatedKey = TRANSLATED_POSSIBLE_KEYS[value];

    return this.#fieldsFetcherService.getConfiguration$('metadata-recognition').pipe(
      map((fieldsConfig) => {
        if (!fieldsConfig || !fieldsConfig.table.system) {
          return null;
        }

        return fieldsConfig.table.system.filter((field) => {
          return (
            field.id.toLowerCase() === value.toLowerCase() ||
            field.sourceListKey?.replace(/_/g, '').toLowerCase() === value.toLowerCase()
          );
        });
      }),
      map((fields) => {
        if (!fields) {
          return null;
        }

        const field = fields[0];

        return field?.label;
      }),
      map((label) => {
        return label ?? translatedKey ?? value;
      }),
    );
  }
}
