import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, map, filter, takeUntil, of, take, Subject, BehaviorSubject, tap } from 'rxjs';
import {
  DialogResponse,
  TileSelectableConfig,
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import {
  Destroyable,
  DiacriticsRevertConverter,
  E2eIdDirective,
  emailPatternValidator,
  ValueFormat,
} from '@vdms-hq/shared';
import {
  SelectableListInterface,
  SelectableWrapperConfig,
  UiSelectableTilesComponent,
} from '@vdms-hq/selectable-tiles-wrapper';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { ToastService } from '@vdms-hq/toast';
import { ViewSettingsService } from '@vdms-hq/view-settings';
import { ActivatedClientService, Permission, PermissionService } from '@vdms-hq/activated-client';
import { UsersShareData, UsersShareDataSource } from '../../logic/users-share-ds.service';
import { AuthService } from '@vdms-hq/auth';
import { CollectionsService } from '@vdms-hq/api-contract';
import { FilterType } from '@vdms-hq/fields';
import { NewUserWizardDialogComponent } from '@vdms-hq/client-user-admin';

export interface ShareUsersInput {
  shared_to_users?: {
    name: string;
    uuid: string;
  }[];
  collectionUuid?: string;
  supplierUuid?: string;
}

@Component({
  selector: 'vdms-hq-users-share-dialog',
  templateUrl: './users-share-dialog.component.html',
  styles: [
    '.form-content { display: flex; flex-direction: column; gap: 16px; padding-right: 1rem;} mat-divider { margin-bottom: 8px;}',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UILoaderModule,
    DynamicFiltersModule,
    UIEmptyResultsModule,
    MatDividerModule,
    UiSelectableTilesComponent,
    E2eIdDirective,
  ],
})
export class UsersShareDialogComponent extends Destroyable() implements OnInit {
  private dialogRef = inject(MatDialogRef<UsersShareDialogComponent>);
  private dialog = inject(MatDialog);
  private toastService = inject(ToastService);
  private viewSettingsService = inject(ViewSettingsService);
  private activatedClientService = inject(ActivatedClientService);
  private collectionsService = inject(CollectionsService);
  public usersShareDS = inject(UsersShareDataSource);
  private authService = inject(AuthService);
  private permissionService = inject(PermissionService);

  public data: ShareUsersInput = inject(MAT_DIALOG_DATA);

  #refresh$ = new BehaviorSubject(new Date());
  #selectedUsers: SelectableListInterface[] = [];

  isSaving = false;
  isLoading = true;
  hideUsersList$ = this.activatedClientService.userListHidden$;
  userEmail$ = this.authService.email$;

  readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'Search by name or email',
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['other-users'],
    },
  ];

  usersTilesConfig: TileSelectableConfig<UsersShareData> = {
    label: 'label',
    key: 'key',
    icon: 'person',
    metadata: [
      {
        label: 'Email',
        valuePath: 'mail',
        viewFormat: {
          modifiers: {
            truncateCharacters: 50,
          },
        },
      },
    ],
  };

  usersAddSelectableConfig: SelectableWrapperConfig<UsersShareData> | null = null;

  #form = new FormGroup({
    selected: new FormControl<string[]>([], { nonNullable: true }),
  });
  selectedControl = this.#form.get('selected') as FormControl<string[]>;

  ngOnInit() {
    combineLatest([this.permissionService.verifyWithOwnedPermissions$([Permission.CREATE_USERS]), this.#refresh$])
      .pipe(
        takeUntil(this.isDestroyed$),
        tap(() => {
          this.usersAddSelectableConfig = null;
        }),
        map(
          ([canCreateUsers]) =>
            (this.usersAddSelectableConfig = {
              fieldsConfig: this.filtersConfig,
              tileConfig: this.usersTilesConfig,
              selectedList: {
                data: this.data.shared_to_users ?? this.#selectedUsers,
                title: 'Collection shared with',
              },
              searchView: {
                placeholder: 'Type name or user email',
                label: 'Search Users',
                showSearch: false,
              },
              datasourceTitle: 'Users list',
              emptyMessage: 'No users found',
              loadingText: 'Loading users',
              hasEmptyActionButton: () => canCreateUsers,
              emptyActionButtonText: 'Create new user',
              showPaginator: true,
            }),
        ),
      )
      .subscribe();

    combineLatest([
      this.viewSettingsService.preferredSelectableView$,
      this.hideUsersList$,
      this.userEmail$,
      this.data.collectionUuid ? this.collectionsService.getCollectionData(this.data.collectionUuid) : of(null),
      this.permissionService.verifyWithOwnedPermissions$([Permission.CREATE_USERS]),
    ])
      .pipe(take(1), takeUntil(this.isDestroyed$))
      .subscribe(([view, hidden, userEmail, collectionData, canCreateUsers]) => {
        this.usersShareDS.showUsers$.next(!hidden);

        if (!this.usersAddSelectableConfig) {
          return;
        }

        if (hidden) {
          this.usersAddSelectableConfig.hasEmptyActionButton = (value) => value !== userEmail && canCreateUsers;
          this.usersShareDS.filters.controls.keyword.setValidators(emailPatternValidator());
          this.usersAddSelectableConfig.searchView.showSearch = false;
          this.usersAddSelectableConfig.showPaginator = false;
          this.usersAddSelectableConfig = {
            ...this.usersAddSelectableConfig,
            searchView: {
              ...this.usersAddSelectableConfig.searchView,
              showSearch: true,
              only: true,
              errorMsg: 'common.validators.email',
            },
            datasourceTitle: 'Share to:',
            loadingText: 'Searching for user...',
          };
          this.filtersConfig[0].label = "Enter user's email and if the user exists, click on its field to add";
        } else {
          this.usersAddSelectableConfig.searchView.showSearch = view === 'search';
        }
        if (collectionData) {
          this.usersAddSelectableConfig.selectedList.data = collectionData.users ?? [];
          const sharedUsersUuids = collectionData.users?.map((user) => user.uuid) ?? [];
          this.selectedControl.setValue(sharedUsersUuids);
        }

        this.isLoading = false;
      });
  }

  createNewUser() {
    const email = DiacriticsRevertConverter(this.usersShareDS.filters.controls['keyword'].value);
    this.dialog
      .open(NewUserWizardDialogComponent, {
        data: {
          multipleEmails: true,
          mode: 'collections_share',
          email,
        },
      })
      .afterClosed()
      .pipe(
        filter((dialogResponse) => dialogResponse?.status === DialogResponse.OK),
        filter((dialogResponse) => !!dialogResponse?.users),
        map((data) => data.users),
      )
      .subscribe({
        next: (users: { uuid: string; email: string }[]) => {
          if (!users) {
            return;
          }
          this.usersShareDS.filters.controls['keyword'].setValue('');
          this.usersShareDS.filters.controls['keyword'].markAsDirty();
          this.selectedControl.setValue(users.map((user) => user.uuid));
          this.#selectedUsers = users.map((user) => ({ name: user.email, uuid: user.uuid, isNew: true }));
          this.#refresh$.next(new Date());
        },
        error: () => {
          this.toastService.error({
            id: 'create_field_failure',
            message: 'common.notifications.users.create.exists_other_client',
            interpolatedParams: { reason: 'Not specified' },
          });
        },
      });
  }

  close(saved = false) {
    this.usersShareDS.refresh(saved);
    this.dialogRef.close(
      saved
        ? {
            status: DialogResponse.OK,
            userIds: this.selectedControl.value,
          }
        : undefined,
    );
  }

  saveViewSettings(showSearch: boolean) {
    this.viewSettingsService.savePreferredSelectableView(showSearch ? 'search' : 'list');
  }
}
