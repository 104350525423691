import { inject, Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { PostExportEgressReportInterface, StorageStats } from './storage-stats.model';
import { ApiEmptyResponse, ApiPaginatedResponse, ApiResponse } from '../api.model';
import { StatsEndpoints } from './endpoints';
import { EgressListItem, EgressStats } from './egress-stats.model';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { PaginationAPIModel } from '../pagination.model';
import { createErrorContext } from '../../error-handler';

@Injectable({ providedIn: 'root' })
export class StatsService {
  private apiService = inject(ApiService);

  getStorageStats() {
    return this.apiService.get<ApiResponse<StorageStats>>(StatsEndpoints.STORAGE).pipe(GetResponseData);
  }

  exportTransactions(params: { start_date: string; end_date: string; is_to_excel: boolean }) {
    const context = createErrorContext({
      '*': 'common.notifications.stats.export.success',
    });

    return this.apiService.post<PostExportEgressReportInterface, ApiEmptyResponse>(
      StatsEndpoints.EGRESS + '/export-report',
      params,
      undefined,
      context,
    );
  }

  #convertFilterParams(params?: { lookbackPeriod?: null | number | string; transferSources?: string }) {
    let lookbackPeriod = params?.lookbackPeriod ?? 30;
    let transferSources = params?.transferSources ?? 'vida,connect2,mediapulse';
    if (!params?.lookbackPeriod || isNaN(Number(params.lookbackPeriod))) {
      lookbackPeriod = 30;
    }
    if (!params?.transferSources) {
      transferSources = 'vida,connect2,mediapulse';
    }
    let httpParams = new HttpParams().set('lookbackPeriod', lookbackPeriod?.toString());
    httpParams = httpParams.append('transferSources', transferSources);

    return httpParams;
  }

  getEgressStats(params?: { lookbackPeriod?: null | number | string; transferSources?: string }) {
    const httpParams = this.#convertFilterParams(params);

    return this.apiService
      .get<ApiResponse<EgressStats>>(StatsEndpoints.EGRESS, { params: httpParams })
      .pipe(GetResponseData);
  }

  getEgressTransfersList(
    pagination?: PaginationAPIModel,
    params?: {
      lookbackPeriod?: null | number | string;
      transferSources?: string;
    },
  ) {
    let headers = new HttpHeaders();
    headers = pagination ? pagination.applyToHeaders(headers) : headers;

    const httpParams = this.#convertFilterParams(params);

    return this.apiService.get<ApiPaginatedResponse<EgressListItem>>(StatsEndpoints.EGRESS_TRANSFERS, {
      headers,
      params: httpParams,
    });
  }
}
