import { OptionType } from '@vdms-hq/shared';
import { LanguageSources } from '@vdms-hq/selectors';

export enum METADATA_RECOGNITION_ROUTES {
  ROOT = 'filename-conventions',
}

export interface KeyTypeToOptionTypeKeyInterface {
  [key: string]: string | OptionType | LanguageSources | ((value: string) => string | undefined);
  keyOf(value: string): string | undefined;
}

export const KeyTypeToOptionTypeKey: KeyTypeToOptionTypeKeyInterface = {
  episodeName: OptionType.EPISODE_NAME,
  seriesName: OptionType.SERIES_NAME,
  facilityOfOrigin: OptionType.FACILITY_OF_ORIGIN,
  language: LanguageSources.LANGUAGE,
  elements: OptionType.ELEMENTS,
  episodeNumber: 'episode_number',
  seriesNumber: 'series_number',
  segments: OptionType.SEAMLESS_SEGMENTED,
  genre: OptionType.GENRE,
  productionCompany: OptionType.PRODUCTION_COMPANY,
  contentClass: OptionType.CONTENT_CLASS,
  contentType: OptionType.CONTENT_CLASS,
  theme: OptionType.THEME,
  variation: OptionType.VARIATION,
  category: OptionType.CATEGORY,
  seasonTitle: OptionType.SEASON_TITLE,
  releaseYear: OptionType.RELEASE_YEAR,
  multiVersion: OptionType.MULTIVERSION,
  subType: OptionType.SUB_TYPE,
  keyOf(value: string): string | undefined {
    return Object.keys(this).find((key) => this[key] === value);
  },
};
