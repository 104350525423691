import { InjectionToken } from '@angular/core';
import { HttpContextToken } from '@angular/common/http';

export interface ErrorHandlingConfig {
  prefix?: string;
}
export const ERROR_HANDLING_CONFIG: InjectionToken<ErrorHandlingConfig> = new InjectionToken<ErrorHandlingConfig>(
  'ERROR_HANDLING_CONFIG',
);
export const redirect404 = new HttpContextToken<boolean>(() => true);
export const redirectNotPermitted403 = new HttpContextToken<boolean>(() => true);
export const authenticate401 = new HttpContextToken<boolean>(() => true);
