<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    [title]="'pages.home.menu.client_users'"
    [actions]="headerActions$ | async"
    (action)="handleAction($event)"
  >
  </vdms-hq-ui-data-presentation-header>
  @if (filtersConfig$ | async; as filtersConfig) {
    @if (viewConfiguration$ | async; as config) {
      <vdms-hq-ui-multiple-data-presentation
        [dataSource]="castToMultipleViewDS$(dataSource)"
        [configuration]="config"
        (action)="handleAction($event)"
      >
        <vdms-hq-dynamic-form-group-renderer
          filters
          [form]="dataSource.filters"
          [fieldsConfig]="filtersConfig"
        ></vdms-hq-dynamic-form-group-renderer>
      </vdms-hq-ui-multiple-data-presentation>
      <ng-container *requiresPermissions="[Permission.USER_ADMIN_EDIT_POLICIES]">
        @if (dataSource.selection.total$ | async; as total) {
          @if (total > 0) {
            <vdms-hq-client-user-admin-action-bar
              [dataSource]="castToSelectableConnectableDS$(dataSource)"
              (action)="handleAction($event)"
            ></vdms-hq-client-user-admin-action-bar>
          }
        }
      </ng-container>
    }
  } @else {
    <vdms-hq-ui-loader [backdrop]="false"></vdms-hq-ui-loader>
  }
</div>
