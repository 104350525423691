import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { combineLatest, distinctUntilChanged, EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlingService } from './error-handling.service';
import { authenticate401, redirect404, redirectNotPermitted403 } from './error-handling.types';
import { ErrorHandlingMessages, ErrorHandlingRoutes } from './error-handling.enum';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import { ERRORS_CATCHER_CONFIG } from '@vdms-hq/api-contract';
import { AuthService } from '@vdms-hq/auth';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  private readonly errorHandlingService = inject(ErrorHandlingService);
  private permissionService = inject(PermissionService);
  private auth = inject(AuthService);
  showErrors = false;
  allowedPages: string[] = [];

  constructor() {
    combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.PERMISSION_SHOW_ERROR_MESSAGES]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.BROWSE_COLLECTIONS]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.BROWSE_SHARED_PACKS]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.BROWSE_ALL_ORDERS]),
    ]).subscribe(([showErrors, collections, downloads, orders]) => {
      this.showErrors = showErrors;
      switch (true) {
        case collections:
          if (!this.allowedPages.includes('/collections')) {
            this.allowedPages.push('/collections');
          }
          break;
        case downloads:
          if (!this.allowedPages.includes('/shared-packs')) {
            this.allowedPages.push('/shared-packs');
          }
          break;
        case orders:
          if (!this.allowedPages.includes('/orders')) {
            this.allowedPages.push('/orders');
          }
          break;
      }
    });
  }

  #getMessageThatMatches = (patterns?: string[], errorCode?: string, errorMessage?: string) => {
    if (!patterns) {
      return;
    }

    const matchMessage = patterns.find((pattern) => {
      if (pattern === errorMessage) {
        return true;
      }

      return errorMessage?.includes(pattern);
    });

    if (matchMessage) {
      return matchMessage;
    }

    const matchCode = patterns.find((pattern) => pattern === errorCode);

    if (matchCode) {
      return matchCode;
    }

    return patterns.find((pattern) => pattern === '*');
  };

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const contextV2 = request.context.get(ERRORS_CATCHER_CONFIG);

    return next.handle(request.clone()).pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      catchError((error) => {
        if (request.context.get(redirect404) && error.status === +ErrorHandlingRoutes.NOT_FOUND) {
          this.errorHandlingService.redirectToErrorPage();
          return EMPTY;
        }
        if (request.context.get(redirectNotPermitted403) && error.status === +ErrorHandlingRoutes.NOT_PERMITTED) {
          if (this.allowedPages.length === 0) {
            this.errorHandlingService.redirectToErrorPage(ErrorHandlingRoutes.NOT_PERMITTED);
          } else {
            this.errorHandlingService.redirectTo(this.allowedPages[0]);
          }
          return EMPTY;
        }
        if (request.context.get(authenticate401) && error.status === +ErrorHandlingRoutes.NOT_AUTHORIZED_STATUS) {
          if (error.error.data === ErrorHandlingMessages.REQUIRED_2FA) {
            this.errorHandlingService.redirectToErrorPage(ErrorHandlingRoutes.NOT_AUTHORIZED_PAGE);
          } else {
            this.errorHandlingService.redirectToErrorPage(ErrorHandlingRoutes.NOT_AUTHORIZED_STATUS);
          }
          return EMPTY;
        }

        if (error.status === +ErrorHandlingRoutes.INTERNAL_SERVER_ERROR) {
          this.errorHandlingService.triggerErrorToast({
            id: 'internal-server-error',
            additionalMessage: 'common.errors.general',
            message: 'common.errors.exception_types.internal_server_error',
          });
          return throwError(error);
        }

        if (contextV2) {
          const patterns = Object.keys(contextV2.messagesMap);
          const errorMessage = error.error?.data;
          const errorCode = error.error?.error;
          const errorStatus: number = error.status;

          const matched = this.#getMessageThatMatches(patterns, errorCode, errorMessage);

          if (!matched) {
            return throwError(error);
          }

          if (errorStatus === 401) {
            return throwError(error);
          }

          const message = contextV2.messagesMap[matched];

          if (!message) {
            return throwError(error);
          }

          let additionalMessage;
          if (this.showErrors) {
            const errorResponse = error?.error;
            additionalMessage =
              errorResponse.data ??
              errorResponse.error ??
              errorResponse.message ??
              (errorResponse ? JSON.stringify(errorResponse) : 'Unknown error');
          } else {
            additionalMessage = 'common.errors.exception_types.' + error.error.type;
          }

          this.errorHandlingService.triggerErrorToast({
            id: contextV2.toastId,
            additionalMessage,
            message,
          });
        }

        return throwError(error);
      }),
    );
  }
}
