import { Component, inject, signal } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectorsModule } from '@vdms-hq/selectors';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { StatsService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { catchError } from 'rxjs';
import { SelectOption, SelectOptionKey } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-export-transactions-dialog',
  templateUrl: './export-transactions-dialog.component.html',
  styleUrls: ['./export-transactions-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UIButtonModule,
    TranslateModule,
    UIDialogWrapperModule,
    ReactiveFormsModule,
    UIFormModule,
    SelectorsModule,
  ],
})
export class ExportTransactionsDialogComponent {
  private dialogRef = inject(MatDialogRef<ExportTransactionsDialogComponent>);
  private statsService = inject(StatsService);
  private toastService = inject(ToastService);

  processing = signal(false);

  form = new FormGroup({
    range: new FormControl({
      from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      to: new Date(),
    }),
    exportOption: new FormControl<SelectOptionKey>('exportCSV'),
  });

  exportOptions: SelectOption[] = [
    {
      key: 'exportCSV',
      label: `Export to CSV`,
    },
    {
      key: 'exportXLSX',
      label: `Export to XLSX`,
    },
  ];

  cancel(): void {
    this.dialogRef.close(null);
  }

  export(): void {
    const payload = this.form.value;

    const from = payload.range?.from;
    const to = payload.range?.to;
    const exportToXLSX = payload.exportOption === 'exportXLSX';

    if (!from || !to) {
      return;
    }
    this.processing.set(true);

    this.statsService
      .exportTransactions({
        start_date: from.toISOString(),
        end_date: to.toISOString(),
        is_to_excel: exportToXLSX,
      })
      .pipe(
        catchError((e) => {
          this.processing.set(false);

          return [e];
        }),
      )
      .subscribe(() => {
        this.dialogRef.close();
        this.toastService.success({
          id: 'export',
          message: 'common.notifications.stats.export.success',
        });
        this.processing.set(false);
      });
  }
}
