import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MultipleDataPresentationComponent,
  UIResultsWrapperModule,
  UILoaderModule,
  UILayoutModule,
  DataPresentationHeaderComponent,
  ActionContextLess,
  MultipleViewDataSource,
} from '@vdms-hq/ui';
import { ClientUserAdminDatasource } from '../../logic/client-user-admin-datasource';
import { DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { castTo, ConnectableDataSource, SelectableDataSource, SharedModule } from '@vdms-hq/shared';
import { ClientUserAdminConfigService } from '../../logic/client-user-admin-config.service';
import { ClientUserAdminActionService } from '../../logic/client-user-admin-action.service';
import { map, Observable, combineLatest } from 'rxjs';
import { ClientUserAdminActionBarComponent } from '../../components/action-bar/client-user-admin-action-bar.component';
import { ActivatedClientModule, Permission, PermissionService } from '@vdms-hq/activated-client';
import { ClientUserAdminView } from '@vdms-hq/api-contract';

@Component({
  selector: 'vdms-hq-client-user-admin-page',
  templateUrl: './client-user-admin-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MultipleDataPresentationComponent,
    DynamicFiltersModule,
    SharedModule,
    UIResultsWrapperModule,
    UILoaderModule,
    UILayoutModule,
    ClientUserAdminActionBarComponent,
    DataPresentationHeaderComponent,
    ActivatedClientModule,
  ],
  providers: [],
})
export class ClientUserAdminPageComponent implements OnDestroy {
  private configurationService = inject(ClientUserAdminConfigService);
  private clientUserAdminActionService = inject(ClientUserAdminActionService);
  private actionService = inject(ClientUserAdminActionService);
  private permissionsService = inject(PermissionService);
  dataSource = inject(ClientUserAdminDatasource);

  castToMultipleViewDS$ = castTo<MultipleViewDataSource<ClientUserAdminView>>();
  castToSelectableConnectableDS$ = castTo<
    SelectableDataSource<ClientUserAdminDatasource> & ConnectableDataSource<ClientUserAdminDatasource>
  >();

  protected readonly Permission = Permission;
  viewConfiguration$ = this.configurationService.viewConfiguration$;
  filtersConfig$ = this.configurationService.filtersConfig$;

  headerActions$: Observable<ActionContextLess[]> = combineLatest([
    this.dataSource.isLoading$,
    this.permissionsService.verifyWithOwnedPermissions$([Permission.USER_ADMIN_CREATE]),
  ]).pipe(
    map(([isLoading, hasCreatePermission]) => {
      return hasCreatePermission ? [this.actionService.buildAddUserHeaderAction(isLoading)] : [];
    }),
  );

  handleAction(event: { key: string; item?: any }) {
    this.clientUserAdminActionService.handleAddUserAction(event);
    this.clientUserAdminActionService.handleEditAction(event);
    this.clientUserAdminActionService.handleDeleteAction(event);
    this.clientUserAdminActionService.handleBulkDeleteAction(event);
    this.clientUserAdminActionService.handleCancelAction(event);
    this.clientUserAdminActionService.handleEditSupplierAction(event);
  }

  ngOnDestroy() {
    if (this.dataSource.disconnect) {
      this.dataSource.disconnect();
    }
  }
}
