import { Injectable } from '@angular/core';
import { LoadableDataSource, LocalDataSource } from '@vdms-hq/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { HotColdChangeLogData } from '@vdms-hq/api-contract';
import { HotColdAssetLogsFetcher } from './hot-cold-asset-logs.fetcher';

@Injectable()
export class HotColdAssetLogsDs extends LocalDataSource<HotColdChangeLogData> implements LoadableDataSource {
  assetUuid$ = new BehaviorSubject<string>('');
  isLoading$ = new BehaviorSubject(false);

  emptyResults$?: Observable<boolean>;

  constructor(private logsFetcher: HotColdAssetLogsFetcher) {
    super(logsFetcher.logs$);
    this.total$.pipe().subscribe((total) => {
      this.emptyResults$ = new BehaviorSubject(total === 0);
    });
    this.assetUuid$.subscribe((uuid) => {
      this.logsFetcher.assetUuid = uuid;
      this.logsFetcher.refresh$.next();
    });
  }
}
