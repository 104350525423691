import { inject, Injectable } from '@angular/core';
import { MultipleViewDataSource } from '@vdms-hq/ui';
import {
  CollectionAccessTypeEnum,
  CollectionAccessTypes,
  CollectionsService,
  PaginationAPIModel as Pagination,
  PaginationAPIProps as PaginationProps,
} from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { capitalize, RefreshService } from '@vdms-hq/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { SORT_BY } from '../utils/collections-filters-provider';
import {
  BehaviorSubject,
  debounceTime,
  Observable,
  of,
  startWith,
  switchMap,
  map,
  combineLatest,
  EMPTY,
  catchError,
  shareReplay,
} from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterParamsPagination } from '@vdms-hq/view-settings';
import { StorageUrlService } from '@vdms-hq/storage';
import { FlatCollectionViewModel } from '../models/collection-view.model';
import { Permission, PermissionService } from '@vdms-hq/activated-client';

export type CollectionsFiltersView = {
  collectionType?: string;
  sort: Pick<PaginationProps, 'orderBy' | 'orderDir'>;
  nameSubject?: string;
  filter?: string;
};

@Injectable({ providedIn: 'root' })
export class CollectionsMultiDs
  extends RouterParamsPagination
  implements MultipleViewDataSource<FlatCollectionViewModel>
{
  private toastService = inject(ToastService);
  private refreshService = inject(RefreshService);
  private collectionsService = inject(CollectionsService);
  private storageUrlService = inject(StorageUrlService);
  private readonly permissionService = inject(PermissionService);

  total$ = new BehaviorSubject(0);
  emptyResults$ = new BehaviorSubject(this.total$.value === 0);
  isLoading$ = new BehaviorSubject(false);
  refresh$ = new BehaviorSubject(true);

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
    sort: new FormControl<string>(SORT_BY[0].key),
    collectionType: new FormControl<CollectionAccessTypeEnum>(CollectionAccessTypeEnum.OWNED),
  });

  values$: Observable<CollectionsFiltersView> = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(400),
    switchMap(() => {
      const formValue = this.filters.value;
      const selectedSort = SORT_BY.find((item) => item.key === formValue?.sort)?.props;
      const filters = <CollectionsFiltersView>{};

      if (selectedSort) {
        filters.sort = selectedSort;
      }

      switch (formValue?.collectionType) {
        case 'owned':
          filters.collectionType = CollectionAccessTypeEnum.OWNED;
          break;
        case 'shared':
          filters.collectionType = CollectionAccessTypeEnum.SHARED;
          break;
        default:
          filters.collectionType = CollectionAccessTypeEnum.ALL;
          break;
      }

      if (formValue?.keyword) {
        filters.filter = formValue.keyword;
      }

      this.changePageIndex$.next(0);
      return of(filters);
    }),
  );

  allData$: Observable<FlatCollectionViewModel[]> = combineLatest([
    this.pageIndex$,
    this.pageSize$,
    this.values$,
    this.permissionService.verifyWithOwnedPermissions$([Permission.BROWSE_COLLECTIONS]),
    this.refresh$,
    this.refreshService.refresh$,
  ]).pipe(
    tap(() => this.isLoading$.next(true)),
    switchMap(([pageIndex, pageSize, filters, permission]) => {
      if (!permission) {
        this.isLoading$.next(false);
        this.emptyResults$.next(true);
        return EMPTY;
      }
      const pagination = {
        page: pageIndex,
        perPage: pageSize,
      };
      return this.collectionsService
        .getList(
          {
            filter: filters.filter,
          },
          Pagination.create({ ...pagination, ...filters.sort }),
          filters.collectionType,
        )
        .pipe(
          catchError((error) => this.#errorHandler(error)),
          tap((response) => {
            this.total$.next(response.total);
            this.emptyResults$.next(response.total === 0);
          }),
          switchMap((collections) => {
            const collectionUuids = collections.data.map(({ uuid }) => uuid);
            const accessTypes$ =
              this.filters.value.collectionType !== CollectionAccessTypeEnum.OWNED
                ? this.collectionsService.getCollectionAccessType(collectionUuids)
                : of(
                    collectionUuids.map((uuid) => ({
                      uuid,
                      access_type: CollectionAccessTypeEnum.OWNED,
                    })) as CollectionAccessTypes[],
                  );
            return combineLatest([of(collections), accessTypes$]);
          }),
          map(([response, accessTypes]) => {
            return response.data.map((collection) => {
              const accessType =
                accessTypes.find(({ uuid }) => collection.uuid === uuid)?.access_type ?? CollectionAccessTypeEnum.OWNED;
              const thumb = collection.thumbnails.length > 0;
              const thumbnail = thumb ? collection.thumbnails[0].url : collection.custom_cover_path;
              const owner_type = capitalize(accessType);

              return {
                ...collection,
                access_type: accessType,
                owner_type,
                custom_cover_path: this.storageUrlService.updateCdn(thumbnail ?? undefined),
              };
            });
          }),
        );
    }),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );
  connection$: Observable<FlatCollectionViewModel[]> = this.allData$;

  #errorHandler(err: HttpErrorResponse) {
    this.toastService.error({ id: 'collections', message: 'Error fetching collections' });
    this.isLoading$.next(false);
    this.emptyResults$.next(true);
    return EMPTY;
  }

  refresh() {
    this.refresh$.next(true);
  }
}
