import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FieldsFetcherService } from '@vdms-hq/fields';
import { FieldRelationsServiceV2 } from '@vdms-hq/dynamic-form';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, take } from 'rxjs';
import { EnabledFieldsVo } from '@vdms-hq/dynamic-form';
import { AssetViewConfigKey } from '@vdms-hq/firebase-contract';
import { merge } from 'lodash';
import objectPath from 'object-path';

export interface BatchActionDialogInput {
  assetsCounter: number;
  batchConfig: AssetViewConfigKey;
}

export interface BatchActionDialogOutput {
  data: Record<string, unknown> | undefined;
}

@Component({
  selector: 'vdms-hq-batch-action-dialog',
  templateUrl: './batch-action-dialog.component.html',
  styleUrls: ['./batch-action-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchActionDialogComponent implements OnDestroy, OnInit {
  formGroup = new FormGroup({});
  #fieldsFetcherService = inject(FieldsFetcherService);
  #fieldRelation = inject(FieldRelationsServiceV2);
  dialogRef = inject(MatDialogRef<BatchActionDialogComponent, BatchActionDialogOutput>);
  data = inject<BatchActionDialogInput>(MAT_DIALOG_DATA);
  containers$ = this.#fieldsFetcherService
    .getAssetViewConfiguration$(this.data.batchConfig, true)
    .pipe(map((config) => config.getContainersWithGroupedElements()));
  enabledFields = new EnabledFieldsVo();

  ngOnInit() {
    this.containers$.pipe(take(1)).subscribe((containers) => {
      const allElements = containers.flatMap((container) => container.elements);

      containers.forEach((container) => {
        this.formGroup.addControl(
          container.containerId,
          new FormControl<any>({}, { nonNullable: true, validators: Validators.required }),
        );
        this.#fieldRelation.listenToFieldRelations(
          this.formGroup.get(container.containerId) as FormControl,
          allElements,
          undefined,
          this.enabledFields,
        );
      });
    });
  }

  save() {
    if (this.formGroup.invalid) {
      this.formGroup.markAsTouched();
      return;
    }

    const data = merge({}, ...Object.values(this.formGroup.value));
    this.dialogRef.close({ data });
  }

  ngOnDestroy() {
    this.#fieldRelation.resetFilters();
    this.#fieldRelation.onDestroy();
    this.formGroup.reset();
  }

  close() {
    this.dialogRef.close();
  }

  clear() {
    this.#fieldRelation.resetFilters();
    const rawValue = this.formGroup.getRawValue();

    Object.keys(this.#fieldRelation.flattenObject(rawValue)).forEach((key) => {
      objectPath.set(rawValue, key, null);
    });
    this.formGroup.patchValue(rawValue);
    this.formGroup.reset();
    this.enabledFields.reset();
  }
}
