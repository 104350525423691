import { inject, Injectable } from '@angular/core';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import {
  AddAssetPayload,
  AddPayload,
  AddToCartPayload,
  AssetSearchFilters,
  CartApiService,
} from '@vdms-hq/api-contract';
import { AssetFlatView2, AssetFlatView2Model } from '@vdms-hq/asset-results';
import { DataAction } from '@vdms-hq/ui';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CartStateService } from './cart-state.service';
import { Timecode } from '@vdms-hq/timecode';
import { ToastService } from '@vdms-hq/toast';

export type AddAssetToCartParams = {
  assetId: string;
  offset?: Timecode;
  tcIn?: Timecode;
  tcOut?: Timecode;
  note?: string;
  voiceOverRequired?: string;
};

@Injectable({ providedIn: 'root' })
export class AddToCartActionsService {
  private permissionService = inject(PermissionService);
  private cartService = inject(CartApiService);
  private cartStateService = inject(CartStateService);
  private toastService = inject(ToastService);

  buildAddAssetAction$<T extends AssetFlatView2Model = AssetFlatView2Model>(
    additionalCondition = false,
  ): Observable<DataAction<T>> {
    return combineLatest([
      this.cartStateService.isEnabled$,
      this.cartStateService.isClipOnlyAndPermitted$,
      this.permissionService.verifyWithOwnedPermissions$([Permission.USE_QUARANTINED_ASSETS]),
    ]).pipe(
      take(1),
      map(([isDisabled, isClipOnlyAndPermitted, canUseQuarantined]) => {
        return {
          key: 'add_to_cart',
          label: 'common.global.add_to_cart',
          icon: 'add_shopping_cart',
          color: 'primary',
          onDoubleClick: false,
          disabled: isDisabled,
          hiddenIf: (item?) =>
            !isClipOnlyAndPermitted ||
            additionalCondition ||
            (!!item?.isQuarantined && !canUseQuarantined) ||
            !item?.isUploaded,
          disabledIf: () => isDisabled,
        };
      }),
    );
  }

  handleAddAssetAction<T extends AssetFlatView2 = AssetFlatView2>($event: { key: string; item?: T }): void {
    if ($event.key !== 'add_to_cart' || !$event.item) {
      return;
    }

    this.#addAssetToCart([$event.item]);
  }

  addOrders(orderUuids: string[]) {
    const payload: AddPayload[] = orderUuids.map((orderUuid) => ({
      type: 'order',
      entity_uuid: orderUuid,
    }));
    this.#cartPut(payload);
  }

  addCollections(collectionUuids: string[]) {
    const payload: AddPayload[] = collectionUuids.map((collectionUuid) => ({
      type: 'collection',
      entity_uuid: collectionUuid,
    }));
    this.#cartPut(payload);
  }

  addFromSearchFilters(searchFilters: AssetSearchFilters) {
    this.toastService.processing({
      id: 'cart_add',
      message: 'Adding the folder to the shopping cart, please wait.',
    });
    this.cartStateService.isUpdating$.next(true);

    this.cartService.addFromSearchQuery(searchFilters).subscribe({
      error: () => {
        this.cartStateService.isUpdating$.next(false);
      },
    });
  }

  addAssets(items: AddAssetToCartParams[]) {
    this.#cartPut(
      items.map((item) => {
        const extra: AddAssetPayload['extra'] = {
          note: item.note,
          voiceover_required: item.voiceOverRequired,
        };

        if (item.offset) {
          extra.offset = item.offset.toDatabase();
        }

        const hasTimecodes = item.tcIn && item.tcOut;

        if (!hasTimecodes) {
          return {
            type: 'asset',
            entity_uuid: item.assetId,
            extra,
          };
        }

        extra.timecode_in = item.tcIn?.toDatabase();
        extra.timecode_out = item.tcOut?.toDatabase();

        return {
          type: 'asset',
          entity_uuid: item.assetId,
          extra,
        };
      }),
    );
  }

  #addAssetToCart<T extends AssetFlatView2 = AssetFlatView2>(assets: T[]) {
    const assetIds = assets.map((item) => item.props.uuid);

    this.addAssets(assetIds.map((assetId) => ({ assetId })));
  }

  #cartPut(payload: AddToCartPayload) {
    this.cartStateService.isUpdating$.next(true);

    this.cartService.addToCart(payload).subscribe({
      error: () => {
        this.cartStateService.isUpdating$.next(false);
      },
    });
  }
}
