import { inject, Injectable } from '@angular/core';
import { FieldsOptionsService } from '@vdms-hq/api-contract';
import { map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Extra, filterEmpty, Option, SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { cloneDeep, groupBy, isEqual, omitBy } from 'lodash';
import { ApiFieldsSourceService, LanguageSources, LocalSources } from '@vdms-hq/selectors';
import objectPath from 'object-path';
import { AllListComponentConfig, ConfigLessComponent } from '@vdms-hq/firebase-contract';
import { FormControl } from '@angular/forms';
import { EnabledFieldsVo } from './enabled-fields.vo';
import { FieldDefinitionModel, FieldType, InputDefinitionModel } from '@vdms-hq/fields';

function recordKeys<K extends PropertyKey, T>(object: Record<K, T>) {
  return Object.keys(object) as K[];
}

@Injectable({ providedIn: 'root' })
export class FieldRelationsServiceV2 {
  filters: Record<string, (selectOptions: SelectOption[]) => SelectOption[]> = {};
  #destroyed$ = new Subject<void>();
  loadingFields$ = new BehaviorSubject<Record<string, boolean>>({});
  definitions: InputDefinitionModel[] = [];
  control: FormControl = new FormControl();
  #prevFlattened: Record<string, string> = {};

  #fieldsOptionsService = inject(FieldsOptionsService);
  #apiFieldsSourceService = inject(ApiFieldsSourceService);

  listenToFieldRelations(
    control: FormControl,
    groupedElements: (InputDefinitionModel | AllListComponentConfig | ConfigLessComponent)[],
    initialValue?: object,
    enabledFields?: EnabledFieldsVo,
  ): void {
    this.filters = {};
    this.control = control;
    this.definitions = groupedElements.filter(
      (definition): definition is InputDefinitionModel => 'input' in definition,
    );

    if (initialValue) {
      this.#prevFlattened = this.flattenObject(initialValue as Record<string, unknown>);
    } else {
      this.#prevFlattened = {};
    }

    control.valueChanges
      .pipe(
        takeUntil(this.#destroyed$),
        map(() => {
          const next = control.getRawValue();
          if (!next || Object.keys(next).length === 0) {
            return null;
          }

          const nextFlattened = this.flattenObject(next);
          const changed = omitBy(nextFlattened, (value: SelectOptionKey, key: string) => {
            return key in this.#prevFlattened && isEqual(this.#prevFlattened[key], value);
          });
          const changedValue = Object.entries(changed);
          this.#prevFlattened = nextFlattened;

          if (changedValue.length !== 1) {
            return null;
          } else {
            const [fieldId, fieldValue] = changedValue[0];
            const definition = this.#getDefinition(fieldId);

            if (fieldValue === null && definition) {
              return null;
            }

            if (!definition) {
              return null;
            }
            return [definition, fieldValue] as [FieldDefinitionModel, SelectOptionKey];
          }
        }),
        filterEmpty(),
        mergeMap(([definition, value]) => this.#getSelectedOptionDetails(definition, value).pipe(take(1))),
      )
      .subscribe((data) => {
        this.#getParentFieldAndSetValue(data.parentField, control, enabledFields);
        this.#getSiblingsAndSetValue(data.extra, control, enabledFields);
        this.#getChildrenFieldsAndFilterOptions(data.childrenFields);
      });
  }

  resetFilters() {
    this.filters = {};
  }

  onDestroy() {
    this.#destroyed$.next();
    this.#destroyed$.complete();
  }

  #getSelectedOptionDetails(definition: FieldDefinitionModel, optionKey: SelectOptionKey) {
    const isLanguageSource = Object.values(LanguageSources).includes(definition.sourceListKey as LanguageSources);
    const isLocalSource = Object.values(LocalSources).includes(definition.sourceListKey as LocalSources);

    if (
      !definition ||
      !definition.sourceListKey ||
      isLanguageSource ||
      isLocalSource ||
      definition.input?.type === FieldType.TEXT
    ) {
      return EMPTY;
    }

    return this.#getFieldTypeOptionsByName(definition).pipe(
      switchMap((fieldOptions) => {
        const selectedOption = fieldOptions.filter((option) => option.key === optionKey)[0];
        if (!selectedOption) {
          return EMPTY;
        }
        return this.#getFieldByUuid(selectedOption?.uuid);
      }),
      map((details) => ({
        optionName: definition.id,
        childrenFields: this.#groupChildrenBy(details.child_fields),
        parentField: details.parent_field,
        extra: this.#reformatExtra(details.extra),
      })),
    );
  }

  #reformatExtra(extra: Extra | null): { type_name: string; value: string }[] {
    if (!extra || !extra.siblings || extra.siblings.length === 0) {
      return [];
    }

    const parsedExtra = JSON.parse(extra.siblings);
    if (parsedExtra.siblings && parsedExtra.siblings.length > 0) {
      return parsedExtra.siblings.map((item: { key: string; value: string }) => ({
        type_name: item.key,
        value: item.value,
      }));
    }
    return [];
  }

  #getParentFieldAndSetValue(parentField: Option | undefined, control: FormControl, enabledStates?: EnabledFieldsVo) {
    if (!parentField) {
      return;
    }
    const definition = this.#getDefinition(parentField.type_name);
    if (!definition?.input.objectPath) {
      return;
    }
    if (enabledStates) {
      enabledStates.enable(definition.id);
    }
    const obj = cloneDeep(control.value);
    objectPath.set(obj, definition.input.objectPath, parentField.key);
    control.patchValue(obj);
  }

  #getSiblingsAndSetValue(
    siblings: { type_name: string; value: string }[],
    control: FormControl,
    enabledStates?: EnabledFieldsVo,
  ) {
    if (siblings.length > 0) {
      siblings.forEach((item) => {
        const definition = this.#getDefinition(item.type_name);
        if (!definition?.input.objectPath) {
          return;
        }
        if (enabledStates) {
          enabledStates.enable(definition.id);
        }
        const obj = cloneDeep(control.value);
        objectPath.set(obj, definition.input.objectPath, item.value);
        control.patchValue(obj);
      });
    }
  }

  #getChildrenFieldsAndFilterOptions(childrenFields: Record<string, Option[]> | undefined) {
    if (!childrenFields) {
      return;
    }

    const includeOrphanChildren = false;

    const childrenKeys: SelectOptionKey[] = recordKeys(childrenFields);

    for (const childKey of childrenKeys) {
      const childKeyString = String(childKey);

      const children = childrenFields[childKeyString].map((orphan) => orphan.key);

      if (includeOrphanChildren) {
        this.#apiFieldsSourceService
          .listAllByType(childKeyString)
          .pipe(
            take(1),
            map((fields) => fields.filter((field) => !field.parent_field)),
          )
          .subscribe((orphanFields) => {
            orphanFields.filter((item) => !item.parent_field).forEach((item) => children.push(item.key));

            this.#filterChild(childKeyString, children);
          });
      } else {
        this.#filterChild(childKeyString, children);
      }
    }
  }

  #getDefinition(path: string | undefined): InputDefinitionModel | null {
    if (!path) {
      return null;
    }
    return this.definitions.filter(
      (field) => field.input?.objectPath === path || field.id === path || field.sourceListKey === path,
    )[0];
  }

  #getFieldTypeOptionsByName(field: FieldDefinitionModel) {
    this.#setLoading(field, true);

    return this.#fieldsOptionsService.getTypeByName(field?.sourceListKey as string).pipe(
      map((field) => field.fields),
      tap(() => {
        this.#setLoading(field, false);
      }),
    );
  }

  #getFieldByUuid(fieldUuid: string) {
    return this.#fieldsOptionsService.getField(fieldUuid).pipe(take(1));
  }

  #groupChildrenBy(children: Option[] | undefined) {
    if (!children) {
      return;
    }

    return groupBy(children, (child) => child.type_name);
  }

  #filterChild(childKeyString: string, children: string[]) {
    this.filters[childKeyString] = (selectOptions) =>
      selectOptions.filter((selectOption) => {
        if (!selectOption.key) {
          return true;
        }

        return children.includes(String(selectOption.key));
      });
  }

  #setLoading(field: FieldDefinitionModel, isLoading: boolean) {
    const nextValue = {
      ...this.loadingFields$.value,
    };
    nextValue[field.id] = isLoading;
    this.loadingFields$.next(nextValue);
  }

  flattenObject(obj: Record<string, unknown>, parentKey: string = ''): { [key: string]: string } {
    let result: { [key: string]: any } = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          result = { ...result, ...this.flattenObject(obj[key] as Record<string, unknown>, newKey) };
        } else {
          result[newKey] = obj[key];
        }
      }
    }
    return result;
  }
}
