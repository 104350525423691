import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectOption } from '@vdms-hq/shared';
import { DestinationApiService, DestinationModel, DestinationRule, DestinationStatus } from '@vdms-hq/api-contract';
import { DestinationValidator } from './destination.validator';
import { ActivatedClientService, Permission } from '@vdms-hq/activated-client';
import { CartStateService } from '@vdms-hq/cart-core';
import { shareReplay, Subject, takeUntil } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-destination-selector',
  templateUrl: './destination-selector.component.html',
  styleUrls: ['./destination-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DestinationSelectorComponent implements OnInit, OnDestroy {
  private cartStateService = inject(CartStateService);
  private destinationsApi = inject(DestinationApiService);
  private activatedClient = inject(ActivatedClientService);

  private destroy$ = new Subject<void>();

  @Input() destinationGroup!: FormGroup;
  @Input() submitted = false;
  @Input() label!: string;
  @Input() unavailableOptions: string[] = [];
  @Output() selectedDestination = new EventEmitter<DestinationModel>();
  private destinations!: DestinationModel[];
  selectorOptions!: SelectOption[];
  configOptions!: SelectOption[];

  hasPublishPermission = false;
  showBurnInText = false;

  get uuid() {
    return this.destinationGroup.get('uuid') as FormControl<string>;
  }

  get configUuid() {
    const config = this.destinationGroup.get('configUuid') as FormControl<string>;
    if (config.value && !this.configOptions && this.destinations) {
      this.changeDestination(config.value);
    }
    return this.destinationGroup.get('configUuid') as FormControl<string>;
  }

  get burnInText() {
    return this.destinationGroup.get('burnInTextValue') as FormControl<string | null>;
  }

  get hasBurnInText() {
    return this.destinationGroup.get('hasBurnInText') as FormControl<boolean>;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.activatedClient.permissions$.subscribe((permissions) => {
      this.hasPublishPermission = permissions.includes(Permission.PUBLISH_DELIVERY_DESTINATIONS);
    });
    this.cartStateService.destinationsData$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data === null) {
        this.getDestinationsOptions();
      } else {
        this.selectorOptions = data.options;
        this.destinations = data.destinations.filter((item) => item.status === DestinationStatus.ACTIVE);
      }
    });
    this.destinationGroup.setValidators(DestinationValidator.isRequired());

    this.configUuid.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((config) => {
      const currentValue = this.uuid?.value;
      const currentDest = this.destinations.find((item) => item.uuid === currentValue);

      if (!currentDest) {
        this.showBurnInText = false;
      }

      const configData = currentDest?.configs.find((item) => item.uuid === config);
      if (configData && configData.rules.find((rule: any) => rule.transcode && rule.transcode.burnInText)) {
        this.showBurnInText = true;
        this.burnInText.patchValue('');
        this.hasBurnInText.patchValue(true);
        this.burnInText.markAsTouched();
      } else {
        this.showBurnInText = false;
        this.hasBurnInText.patchValue(false);
        this.burnInText.patchValue(null);
      }
    });
  }

  getDestinationsOptions() {
    this.destinationsApi
      .getAll()
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe((destinations) => {
        this.destinations = destinations.data.filter((item) => item.status === DestinationStatus.ACTIVE);
        const options = [
          {
            key: null,
            label: 'Please select',
          },
          ...destinations.data
            .filter((item) => item.status === DestinationStatus.ACTIVE)
            .map((item) => ({
              key: item.uuid,
              label: item.name,
            })),
        ];
        this.cartStateService.destinationsData$.next({
          destinations: destinations.data,
          options,
        });
        this.selectorOptions = options;
      });
  }

  changeDestination(config?: string) {
    const currentValue = this.uuid?.value;
    const currentDest = this.destinations.find((item) => item.uuid === currentValue);
    this.selectedDestination.emit(currentDest);

    if (!currentDest) {
      this.configOptions = [];
      return;
    }

    if (config) {
      this.unavailableOptions = this.unavailableOptions.filter((option) => option !== config);
    }

    this.configOptions = [
      {
        key: null,
        label: 'Please select',
      },
      ...currentDest.configs
        .map((item) => ({
          key: item.uuid,
          label: item.name ?? item.uuid.slice(0, 10),
          disabled: this.checkIFConfigIsDisabled(item.rules, item.name),
        }))
        .filter((option) => !(this.unavailableOptions ?? []).includes(option?.key)),
    ];

    if (config) {
      this.configUuid.setValue(config);
    }
  }

  checkIFConfigIsDisabled(rules: DestinationRule[], name: string) {
    if (!rules.find((rule) => rule.publish)) return false;
    return rules.length > 0 && rules.find((rule) => rule.publish)?.publish?.type && !this.hasPublishPermission;
  }
}
