<vdms-hq-ui-actions-footer>
  <div left>
    <vdms-hq-ui-button color="secondary" (click)="emitAction(CLIENT_USER_ADMIN_EVENT_KEYS.CANCEL)">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
  </div>
  <div right>
    <div class="right-buttons">
      <vdms-hq-ui-button color="primary" (click)="emitAction(CLIENT_USER_ADMIN_EVENT_KEYS.EDIT)"
        >{{ 'client-user-admin.save.label' | translate }} ({{ dataSource.selection.total$ | async }})</vdms-hq-ui-button
      >
      <vdms-hq-ui-button
        color="warn"
        *requiresPermissions="[Permission.USER_ADMIN_DELETE_BULK]"
        (click)="emitAction(CLIENT_USER_ADMIN_EVENT_KEYS.BULK_DELETE)"
        >{{ 'client-user-admin.bulk_delete.label' | translate }} ({{
          dataSource.selection.total$ | async
        }})</vdms-hq-ui-button
      >
    </div>
  </div>
</vdms-hq-ui-actions-footer>
