import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectOptionsEditDialogInput, SelectOptionsEditDialogResponse } from './select-options-edit-dialog.model';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { SelectOption } from '@vdms-hq/shared';
import { FieldsOptionsService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { AssetMasterType } from '@vdms-hq/selectors';

@Component({
  selector: 'vdms-hq-selectors-select-options-edit-dialog',
  templateUrl: './select-options-edit-dialog.component.html',
  styleUrls: ['./select-options-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionsEditDialogComponent implements OnInit, OnDestroy {
  editSelectOptionForm: UntypedFormGroup = new UntypedFormGroup({
    label: new UntypedFormControl(this.data.option.label, Validators.required),
    enabled: new UntypedFormGroup({
      in_forms: new UntypedFormControl(this.data.option.enabled.in_forms),
      in_search: new UntypedFormControl(this.data.option.enabled.in_search),
    }),
    type_name: new UntypedFormControl({ value: this.data.option?.type_name, disabled: true }),
    parent_field_type_name: new UntypedFormControl(this.data.option?.parent_field?.type_name),
    parent_field_uuid: new UntypedFormControl(this.data.option?.parent_field?.uuid),
    extra: new FormGroup({
      siblings: new FormControl<string | null>(null),
      restricted: new FormControl<string[] | null>(null),
    }),
  });
  dirty$: Observable<boolean> = this.editSelectOptionForm.valueChanges.pipe(
    map(
      (nextFormValue) =>
        this.#hasFormValueChanged(this.#initialFormValue, nextFormValue) && this.editSelectOptionForm.dirty,
    ),
    startWith(false),
  );
  fieldsDataSource: Record<string, SelectOption[]> = {};
  fieldGroups: SelectOption[] = [];
  assetMasterTypes: SelectOption[] = AssetMasterType;
  loading$ = new BehaviorSubject(true);
  saveInProgress$ = new BehaviorSubject(false);
  #destroy$ = new Subject<void>();
  #initialFormValue = this.editSelectOptionForm.value;

  get label() {
    return this.editSelectOptionForm.controls['label'].value;
  }

  constructor(
    public selectOptionsEditDialogRef: MatDialogRef<SelectOptionsEditDialogComponent, SelectOptionsEditDialogResponse>,
    private fieldTypesService: FieldsOptionsService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: SelectOptionsEditDialogInput,
  ) {
    of(data.option)
      .pipe(
        take(1),
        switchMap(({ uuid, type_name, extra }) => {
          this.editSelectOptionForm.patchValue({ uuid, type_name, extra });
          return forkJoin({
            type: this.fieldTypesService
              .getTypeByName(type_name as string)
              .pipe(catchError(() => of({ parent_field_type_name: '' }))),
            field: this.fieldTypesService.getField(uuid).pipe(catchError(() => of({ parent_field: null }))),
          });
        }),
      )
      .subscribe(({ type, field }) => {
        const patchValue = {
          parent_field_type_name: type?.parent_field_type_name,
          parent_field_uuid: field?.parent_field?.uuid,
        };
        this.#handleChangesInFieldTypeName(type?.parent_field_type_name);
        this.editSelectOptionForm.patchValue(patchValue);
        this.loading$.next(false);
      });
    this.#initialFormValue = data.option;
  }

  ngOnInit(): void {
    this.#initSources();
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  save(): void {
    this.saveInProgress$.next(true);
    this.editSelectOptionForm.controls['type_name'].enable();
    const formValue = this.editSelectOptionForm.value;
    const requestsArray: Observable<unknown>[] = [];
    if (
      this.#initialFormValue?.type_name !== formValue?.type_name ||
      this.#initialFormValue?.parent_field_type_name !== formValue?.parent_field_type_name
    ) {
      requestsArray.push(
        this.fieldTypesService.patchType({
          name: formValue.type_name,
          parent_field_type_name: formValue.parent_field_type_name,
        }),
      );
    }
    if (this.#initialFormValue?.type_name !== formValue.parent_field_uuid) {
      requestsArray.push(this.fieldTypesService.patchField(this.#initialFormValue.uuid, formValue));
    }
    forkJoin(requestsArray)
      .pipe(take(1))
      .subscribe(() => {
        this.selectOptionsEditDialogRef.close({
          optionUUID: formValue.uuid,
          optionChanges: formValue,
        });
      });
  }

  cancel(): void {
    this.selectOptionsEditDialogRef.close();
  }

  #hasFormValueChanged = (
    initialFormValue: SelectOptionsEditDialogResponse['optionChanges'],
    nextFormValue: SelectOptionsEditDialogResponse['optionChanges'],
  ): boolean =>
    initialFormValue.label !== nextFormValue.label ||
    initialFormValue.parent_field_type_name !== nextFormValue.parent_field_type_name ||
    initialFormValue.extra !== nextFormValue.extra ||
    initialFormValue.type_name !== nextFormValue.type_name;

  #initSources(): void {
    const sourceOptions: Record<string, SelectOption[]> = {};
    const groups: SelectOption[] = [];

    this.data.fieldTypes.forEach((source) => {
      const options = source.fields
        .filter((field) => field?.parent_field?.uuid !== this.data.option.uuid)
        .map((field): { key: string | null; label: string } => ({ key: field.uuid, label: field.label }));
      options.unshift({ key: null, label: 'N/A' });
      sourceOptions[source.name] = options;
      groups.push({ key: source.name, label: source.name });
    });

    groups.unshift({ key: null, label: 'N/A' });

    this.fieldGroups = this.data.option?.type_name
      ? groups.filter((group) => group.key !== this.data.option?.type_name)
      : groups;
    this.fieldsDataSource = sourceOptions;
    this.cdr.detectChanges();
  }

  #handleChangesInFieldTypeName(initialParentField?: string) {
    this.editSelectOptionForm.controls['parent_field_type_name'].valueChanges
      .pipe(takeUntil(this.#destroy$))
      .subscribe((newValue) => {
        if (!newValue) {
          this.editSelectOptionForm.controls['parent_field_uuid'].disable({ emitEvent: false });
        } else {
          this.editSelectOptionForm.controls['parent_field_uuid'].enable({ emitEvent: false });
        }

        if (!initialParentField || newValue === initialParentField) {
          return;
        }

        this.toastService.info({
          id: 'parent_field_type_change',
          message: `After save all relations with ${initialParentField} will be deleted.`,
        });
      });
  }
}
