<vdms-hq-ui-layout-page-wrapper
  [breadcrumbs]="[{ name: 'common.notification_subscriptions.all_notifications' | translate }]"
>
  <div class="actions" [formGroup]="form">
    <vdms-hq-ui-form-input-select
      formControlName="sortDir"
      [withFooter]="false"
      [label]="'Sort'"
      [selectOptions]="sortDirSelectOptions"
    ></vdms-hq-ui-form-input-select>
    <vdms-hq-ui-form-input-select
      formControlName="types"
      [withFooter]="false"
      [multiple]="true"
      [label]="'Event type'"
      [selectOptions]="(notificationTypes$ | async) ?? []"
    ></vdms-hq-ui-form-input-select>
    <vdms-hq-ui-form-radio-list
      formControlName="categories"
      orientation="horizontal"
      [selectOptions]="categoriesSelectOptions"
    ></vdms-hq-ui-form-radio-list>
  </div>
  <div class="content">
    @if (dataSource.isLoading$ | async) {
      <vdms-hq-ui-loader mode="over-parent" [backdrop]="true" [center]="false"></vdms-hq-ui-loader>
    }

    @if (dataSource$ | async; as notifications) {
      @if (notifications?.length) {
        <div class="mark--all">
          <span (click)="markAllNotificationsAsRead()">
            {{ 'common.notification_subscriptions.buttons.mark_all_as_read' | translate }}
          </span>
        </div>
        <vdms-hq-ui-infinity-loader
          (scrolled)="dataSource.loadMore()"
          [loadOnScroll]="false"
          [total]="(dataSource.totalNotifications$ | async) ?? 0"
          [batchSize]="(dataSource.perPage$ | async) ?? 0"
          [loaded]="notifications?.length ?? 0"
        >
          @for (notification of notifications; track dataSource.trackBy) {
            <div class="notification--upper__bar">
              <div class="notification--created_date">
                @if (!notification.isRead) {
                  <div class="notification--unread__dot"></div>
                }
                @if (notification.props.created_at | daysFrom) {
                  {{ 'common.notification_subscriptions.notification_date' | translate }}:
                  {{ notification.props.created_at | date: 'yyyy-MM-dd HH:mm' }}
                } @else {
                  Today
                }
              </div>
              @if (!notification.isRead) {
                <ng-container>
                  <span class="notification--mark__read" (click)="markAsRead(notification.props.uuid)">{{
                    'common.notification_subscriptions.buttons.mark_as_read' | translate
                  }}</span>
                </ng-container>
              }
            </div>

            <vdms-hq-notification-message
              [notification]="notification"
              [detailedView]="true"
            ></vdms-hq-notification-message>
          }
        </vdms-hq-ui-infinity-loader>
      } @else {
        <vdms-hq-ui-empty-results></vdms-hq-ui-empty-results>
      }
    }
  </div>
</vdms-hq-ui-layout-page-wrapper>
