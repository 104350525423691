import { ChangeDetectionStrategy, Component, Input, EventEmitter, Output } from '@angular/core';
import { SelectableDataSource, ConnectableDataSource, SharedModule } from '@vdms-hq/shared';
import { UILayoutModule, UIButtonModule, ActionContextLess } from '@vdms-hq/ui';
import { CommonModule } from '@angular/common';
import { CLIENT_USER_ADMIN_EVENT_KEYS } from '../../logic/client-user-admin-config.service';
import { ActivatedClientModule, Permission } from '@vdms-hq/activated-client';

@Component({
  standalone: true,
  selector: 'vdms-hq-client-user-admin-action-bar',
  templateUrl: './client-user-admin-action-bar.component.html',
  styleUrls: ['./client-user-admin-action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UILayoutModule, UIButtonModule, SharedModule, ActivatedClientModule],
})
export class ClientUserAdminActionBarComponent<T> {
  @Input() dataSource!: SelectableDataSource<T> & ConnectableDataSource<T>;
  @Output() action = new EventEmitter<ActionContextLess>();
  protected readonly CLIENT_USER_ADMIN_EVENT_KEYS = CLIENT_USER_ADMIN_EVENT_KEYS;

  Permission = Permission;
  emitAction(key: CLIENT_USER_ADMIN_EVENT_KEYS) {
    this.action.emit({ key } as ActionContextLess);
  }
}
