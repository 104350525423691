<vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'Email text templates' }]">
  <div class="grid grid-upto2 grid-gap-x-double">
    <vdms-hq-ui-info-bar
      [type]="InfoBarType.INFO"
      message="Note: The following templates apply exclusively to emails without an included asset list."
    >
    </vdms-hq-ui-info-bar>
  </div>

  @if (dataSource$ | async; as data) {
    @if (data.length) {
      <ng-container [formGroup]="form">
        <vdms-hq-ui-form-section
          [divider]="false"
          title="Template name: {{ item.template_name }}"
          *ngFor="let item of data"
        >
          <p header class="email-templates__header">
            Available parameters:
            <span
              *ngFor="let parameter of item.parameters"
              class="pill yellow"
              [class.done]="$castToString(form.get(item.uuid)?.value).includes(parameter)"
              (click)="setVariable(parameter, item.uuid)"
              >{{ parameter }}</span
            >
          </p>
          <vdms-hq-ui-form-input-text-area [formControlName]="item.uuid"></vdms-hq-ui-form-input-text-area>
        </vdms-hq-ui-form-section>
      </ng-container>
    } @else {
      <div class="grid grid-upto2">
        <vdms-hq-ui-empty-results [message]="'List of email text templates is empty'"></vdms-hq-ui-empty-results>
      </div>
    }
  } @else {
    <div class="grid grid-upto2">
      <vdms-hq-ui-loader [center]="true" [mode]="'default'"></vdms-hq-ui-loader>
    </div>
  }
</vdms-hq-ui-layout-page-wrapper>

<vdms-hq-ui-actions-footer>
  <vdms-hq-ui-button right (click)="save()" [disabled]="form.pristine || loading" [loading]="loading" color="primary">
    Save Templates
  </vdms-hq-ui-button>
</vdms-hq-ui-actions-footer>
