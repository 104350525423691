import { inject, Injectable } from '@angular/core';
import {
  OrderService,
  OrderItemTypeEnum,
  PostFastDownloadInterface,
  ProgressStreamService,
  filterStreamMsgFactory,
  WebsocketNotificationActionEnum,
  DownloadTypeEnum,
} from '@vdms-hq/api-contract';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from '@vdms-hq/auth';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import moment from 'moment';
import { ToastService } from '@vdms-hq/toast';
import { OrderActionsService } from '../details/logic/order-actions.service';

type itemUuid = string;

export interface FastOrderLoadingState {
  downloadNowLoading?: boolean;
  sendToDownloadsLoading?: boolean;
}

@Injectable({ providedIn: 'root' })
export class FastOrdersService {
  private orderService = inject(OrderService);
  private authService = inject(AuthService);
  private activatedClientService = inject(ActivatedClientService);
  private streamService = inject(ProgressStreamService);
  private toastService: ToastService = inject(ToastService);
  private orderActionsService = inject(OrderActionsService);

  readonly connect$ = this.streamService.connect();
  readonly PROCESSING_MSG_ID = 'downloads_now';
  readonly ERROR_MSG_ID = 'downloads_now_error';

  currentUserEmail$ = this.authService.email$;
  isSharedPackEnabled$ = this.activatedClientService.clientDefinite$.pipe(
    map((client) => client?.vida?.sharedPacks ?? false),
  );

  #downloadedFastOrderUuid$ = new BehaviorSubject<string | null>(null);

  fastDownload(items: itemUuid[], orderItemType: OrderItemTypeEnum, toDownload = false) {
    const title = `Fast Download - ${items.length} Asset(s) - ${moment(new Date()).utc(true).format('YYYY-MM-DD HH:mm:ss')}`;

    if (toDownload) {
      this.#downloadedFastOrderUuid$.next(null);
      this.#popProcessingMsg();
    }

    return combineLatest([this.isSharedPackEnabled$, this.currentUserEmail$]).pipe(
      map(([sendAsSharedPack, currentUserEmail]) => {
        const orderUuid = uuidv4();

        if (toDownload) {
          this.#downloadedFastOrderUuid$.next(orderUuid);
        }

        return {
          uuid: orderUuid,
          items: items.map((item: itemUuid) => ({ type: orderItemType, entity_uuid: item })),
          package_title: title,
          subject: `Fast Download - ${items.length} Asset(s) - ${moment(new Date()).utc(true).format('YYYY-MM-DD HH:mm:ss')}`,
          delivery_emails: [currentUserEmail],
          send_as_shared_pack: sendAsSharedPack,
        } as PostFastDownloadInterface;
      }),
      switchMap((payload) => this.orderService.fastDownload(payload)),
    );
  }

  registerWebSocketListener() {
    return this.connect$.pipe(
      filterStreamMsgFactory([WebsocketNotificationActionEnum.ORDER_DOWNLOAD_READY]),
      withLatestFrom(this.#downloadedFastOrderUuid$),
      tap(([msg, lastOrderUuid]) => {
        if (!msg.data?.orderUuid) {
          return;
        }

        if (!lastOrderUuid) {
          return;
        }

        if (msg.data.orderUuid !== lastOrderUuid) {
          return;
        }

        this.orderActionsService.download(msg.data.orderUuid, [], {
          download_type: DownloadTypeEnum.ASPERA,
        });
        this.toastService.close(this.PROCESSING_MSG_ID);
      }),
    );
  }

  #popProcessingMsg() {
    this.toastService.processing({
      id: this.PROCESSING_MSG_ID,
      message: 'common.notifications.generic.download_now_processing',
    });

    setTimeout(() => {
      this.toastService.close(this.PROCESSING_MSG_ID);
      this.toastService.error({
        id: this.ERROR_MSG_ID,
        message: 'common.notifications.generic.download_now_error',
      });
    }, 300000);
  }
}
