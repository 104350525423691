import { inject, Injectable } from '@angular/core';
import { AssetActionsService } from '@vdms-hq/asset-results';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { CollectionsActionsService } from '@vdms-hq/collections';
import { LicensePackagesActionsService } from '@vdms-hq/license-packages';
import { CartWebsocketService } from '@vdms-hq/cart-core';
import { AdminSubscriptionWebsocketUpdatesService, NotificationsWebsocketService } from '@vdms-hq/notifications';
import { MetadataActionsService } from '@vdms-hq/metadata-list';
import { FastOrdersService, OrderActionsRetryWsService } from '@vdms-hq/orders';

@Injectable({ providedIn: 'root' })
export class WebsocketInitializerService {
  destroyed$ = new Subject<void>();
  private readonly notificationService = inject(NotificationsWebsocketService);
  private readonly assetActionsService = inject(AssetActionsService);
  private readonly collectionsActionsService = inject(CollectionsActionsService);
  private readonly cartActionsService = inject(CartWebsocketService);
  private readonly orderActionsRetryWSService = inject(OrderActionsRetryWsService);
  private readonly licensePackagesActionsService = inject(LicensePackagesActionsService);
  private readonly subscriptionUpdates = inject(AdminSubscriptionWebsocketUpdatesService);
  private readonly metadataActionsService = inject(MetadataActionsService);
  private readonly fastOrdersService = inject(FastOrdersService);

  unregisterListeners() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  registerListeners() {
    return combineLatest([
      this.notificationService.registerListeners(),
      this.assetActionsService.registerWebSocketListener(),
      this.assetActionsService.registerHotColdWebSocketListener(),
      this.collectionsActionsService.registerWebSocketListener(),
      this.cartActionsService.registerWebSocketListener(),
      this.orderActionsRetryWSService.registerWebSocketListener(),
      this.licensePackagesActionsService.registerWebSocketListener(),
      this.subscriptionUpdates.registerWebSocketListener(),
      this.metadataActionsService.registerWebSocketListener(),
      this.fastOrdersService.registerWebSocketListener(),
    ]).pipe(takeUntil(this.destroyed$));
  }
}
