import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UIDialogWrapperModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { QualityControlAnalyseRow } from '../../logic/quality-control-view.model';
import { QualityControlDatasource } from '../../logic/quality-control-results-ds.service';

export type QCResultsDialogInput = {
  assetUuid: string;
};

@Component({
  selector: 'vdms-hq-qc-results-dialog',
  templateUrl: './qc-results-dialog.component.html',
  styleUrls: ['./qc-results-dialog.component.scss'],
  imports: [UIDialogWrapperModule, TranslateModule, UIButtonModule, UILoaderModule, MultipleDataPresentationComponent],
  standalone: true,
})
export class QcResultsDialogComponent implements OnInit {
  configuration: MultipleViewConfiguration<QualityControlAnalyseRow> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: false,
      emptyResults: {
        message: 'Seems like there are no QA data for this asset yet.',
      },
    },
    tableAdvanced: {
      columns: [
        {
          id: 'summary',
          label: 'All checks',
          valuePath: 'summary',
          viewFormat: {
            pills: {
              passed: 'done',
              failed: 'failed',
              default: 'orange-light',
            },
          },
        },
        {
          id: 'asset_type',
          label: 'Asset Type',
          valuePath: 'assetType',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'file_extension',
          label: 'File extension',
          valuePath: 'fileExtension',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'timecode_start',
          label: 'Timecode start',
          valuePath: 'timecodeStart',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'dropped_frames',
          label: 'Dropped frames',
          valuePath: 'droppedFrames',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'video_codec',
          label: 'Video codec',
          valuePath: 'videoCodec',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'bit_depth',
          label: 'Bit depth',
          valuePath: 'videoBitDepth',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'chroma_format',
          label: 'Chroma format',
          valuePath: 'chromaFormat',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'framerate',
          label: 'Framerate',
          valuePath: 'framerate',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'interlace_mode',
          label: 'Interlace mode',
          valuePath: 'interlaceMode',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'width',
          label: 'Width',
          valuePath: 'width',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'height',
          label: 'Height',
          valuePath: 'height',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'audio_codec',
          label: 'Audio codec',
          valuePath: 'audioCodec',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'channel_count',
          label: 'Channel count',
          valuePath: 'channelCount',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'channels_per_track',
          label: 'Channels per track',
          valuePath: 'channelsPerTrack',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'audio_sample_rate',
          label: 'Audio sample rate',
          valuePath: 'audioSampleRate',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'audio_bit_depth',
          label: 'Audio bit depth',
          valuePath: 'audioBitDepth',
          viewFormat: {
            pills: {
              passed: 'done',
            },
            pillsMatch: 'include',
          },
        },
        {
          id: 'settings',
          type: 'settings',
        },
      ],
      columnsEnabled: [
        'summary',
        'asset_type',
        'file_extension',
        'timecode_start',
        'dropped_frames',
        'video_codec',
        'bit_depth',
        'chroma_format',
        'framerate',
        'interlace_mode',
        'width',
        'height',
        'audio_codec',
        'channel_count',
        'channels_per_track',
        'audio_sample_rate',
        'audio_bit_depth',
        'settings',
      ],
    },
  };

  constructor(
    public qualityControlDatasource: QualityControlDatasource,
    private dialogRef: MatDialogRef<QcResultsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QCResultsDialogInput,
  ) {}

  ngOnInit(): void {
    this.qualityControlDatasource.assetUuid$.next(this.data.assetUuid);
  }

  close(): void {
    this.dialogRef.close(null);
  }

  refresh() {
    this.qualityControlDatasource.refresh();
  }
}
