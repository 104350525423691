import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ManageUserDialogComponent } from '../components/manage-user-dialog/manage-user-dialog.component';
import { ClientUserAdminView, ClientUserAdminService, UserApiService } from '@vdms-hq/api-contract';
import { DialogResponse } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { CLIENT_USER_ADMIN_EVENT_KEYS } from './client-user-admin-config.service';
import { DataAction, UIConfirmationDialogService, ActionContextLess } from '@vdms-hq/ui';
import { switchMap, tap, catchError, EMPTY, iif, map, filter, take, delay } from 'rxjs';
import { ClientUserAdminDatasource } from './client-user-admin-datasource';
import { ManageUserDialogData } from '../models/manage-user-dialog.model';
import { ChangeUserSupplierDialogComponent } from '../components/change-user-supplier-dialog/change-user-supplier-dialog.component';
import { UsersDeletionConfirmationDialogComponent } from '../components/user-deletion-confirmation-dialog/user-deletion-confirmation-dialog.component';
import { isArray } from 'lodash';
import { NewUserWizardDialogComponent } from '../components/new-user-wizard-dialog/new-user-wizard-dialog.component';

@Injectable({ providedIn: 'root' })
export class ClientUserAdminActionService {
  private matDialog = inject(MatDialog);
  private toast = inject(ToastService);
  private clientUserAdminService = inject(ClientUserAdminService);
  private clientUserAdminDataSource = inject(ClientUserAdminDatasource);
  private readonly confirmationDialogService = inject(UIConfirmationDialogService);
  private userApi = inject(UserApiService);

  popToast = {
    USER_DELETED: () =>
      this.toast.success({
        id: 'user_deleted',
        message: 'common.notifications.users.delete.success',
      }),
    USER_DELETE_ERROR: (error: any) =>
      this.toast.error({
        id: 'user_deleted',
        message: 'common.notifications.users.delete.failure',
      }),
  };

  buildEditAction(canEditPolicies: boolean): DataAction<ClientUserAdminView> {
    return {
      key: CLIENT_USER_ADMIN_EVENT_KEYS.EDIT,
      icon: 'settings',
      label: 'client-user-admin.edit.title',
      hiddenIf: () => !canEditPolicies,
    };
  }

  buildChangeSupplierAction(canChangeSupplier = true): DataAction<ClientUserAdminView> {
    return {
      key: CLIENT_USER_ADMIN_EVENT_KEYS.EDIT_SUPPLIER,
      icon: 'people',
      label: 'client-user-admin.edit_supplier.title',
      hiddenIf: () => !canChangeSupplier,
    };
  }

  handleEditAction(event: { key: string; item?: ClientUserAdminView }) {
    if (event.key !== CLIENT_USER_ADMIN_EVENT_KEYS.EDIT) {
      return;
    }

    this.#popManageUserDialog(event?.item, { edit: true });
  }

  buildAddUserHeaderAction(disabled?: boolean): ActionContextLess {
    return {
      key: CLIENT_USER_ADMIN_EVENT_KEYS.ADD_USER,
      label: 'client-user-admin.add.title',
      color: 'primary',
      disabled: disabled === true,
    };
  }

  handleAddUserAction(event: { key: string }) {
    if (event.key !== CLIENT_USER_ADMIN_EVENT_KEYS.ADD_USER) {
      return;
    }

    this.#popNewUserWizard();
    // this.#popManageUserDialog(undefined, { edit: false });
  }

  buildDeleteAction(canDeletePolicies: boolean): DataAction<ClientUserAdminView> {
    return {
      key: CLIENT_USER_ADMIN_EVENT_KEYS.DELETE,
      icon: 'delete',
      label: 'client-user-admin.delete.title',
      hiddenIf: () => !canDeletePolicies,
    };
  }

  handleEditSupplierAction(event: { key: string; item?: ClientUserAdminView }) {
    if (event.key !== CLIENT_USER_ADMIN_EVENT_KEYS.EDIT_SUPPLIER) {
      return;
    }
    if (event.item) {
      this.#popEditUserSupplier(event?.item);
    }
  }

  handleBulkDeleteAction(event: { key: string; item?: ClientUserAdminView }) {
    if (event.key !== CLIENT_USER_ADMIN_EVENT_KEYS.BULK_DELETE) {
      return;
    }
    this.#popDeleteUsersDialog();
  }

  handleDeleteAction(event: { key: string; item?: ClientUserAdminView }) {
    if (event.key !== CLIENT_USER_ADMIN_EVENT_KEYS.DELETE) {
      return;
    }
    this.confirmationDialogService
      .openDelete(undefined)
      .pipe(
        filter((dialogResponse) => dialogResponse),
        switchMap(() => {
          if (!event.item) {
            return EMPTY;
          }
          return this.clientUserAdminService.deleteUserFromGroup(event.item.uuid);
        }),
        tap(() => this.popToast.USER_DELETED()),
        tap(() => this.clientUserAdminDataSource.refresh()),
        catchError((error) => {
          this.popToast.USER_DELETE_ERROR(error);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  handleCancelAction(event: { key: string; item?: any }) {
    if (event.key !== CLIENT_USER_ADMIN_EVENT_KEYS.CANCEL) {
      return;
    }
    this.clientUserAdminDataSource.selection.clear();
  }

  #popEditUserSupplier(item: ClientUserAdminView) {
    const data = {
      user: item,
    };

    this.matDialog
      .open(ChangeUserSupplierDialogComponent, {
        data,
      })
      .afterClosed()
      .pipe(
        filter((dialogResponse) => dialogResponse?.status === DialogResponse.OK),
        switchMap((dialogResponse) => {
          return this.clientUserAdminService.updateSupplierForUserForGroup(item.uuid, {
            supplier: dialogResponse.supplier,
          });
        }),
        tap(() => {
          this.toast.success({
            id: 'user_edited',
            message: 'common.notifications.users.update.success',
          });
          this.clientUserAdminDataSource.selection.clear();
          this.clientUserAdminDataSource.refresh();
        }),
      )
      .subscribe();
  }

  #popDeleteUsersDialog() {
    this.clientUserAdminDataSource.selection.entities$
      .pipe(
        take(1),
        map((users) => users.map((user) => user.uuid)),
        switchMap((users) => {
          return this.matDialog
            .open(UsersDeletionConfirmationDialogComponent, {
              data: { total: isArray(users) ? users.length : 1 },
            })
            .afterClosed()
            .pipe(
              take(1),
              filter((response) => response),
              switchMap((response) => {
                if (isArray(users)) {
                  return this.userApi.bulkDelete(users, response.reason);
                }
                return this.userApi.delete(users);
              }),
            );
        }),
        tap(() => this.toast.success({ id: 'delete_success', message: 'common.notifications.remove.done' })),
        delay(1000),
        tap(() => {
          this.clientUserAdminDataSource.refresh();
          this.clientUserAdminDataSource.selection.clear();
        }),
      )
      .subscribe();
  }

  #popNewUserWizard() {
    this.matDialog.open(NewUserWizardDialogComponent, {
      data: {
        multipleEmails: true,
      },
    });
  }

  #popManageUserDialog(item?: ClientUserAdminView, options?: { edit: boolean }) {
    const isSingleUserEdit = !!item;

    const data: ManageUserDialogData = {
      users: item ? [item] : [],
      policies: [],
      edit: !!options?.edit,
    };

    const dialog$ = this.matDialog
      .open(ManageUserDialogComponent, {
        data,
      })
      .afterClosed()
      .pipe(filter((dialogResponse) => dialogResponse?.status === DialogResponse.OK));

    const multiple$ = this.clientUserAdminDataSource.selection.entities$.pipe(
      switchMap((users) => {
        data.users = users;
        return dialog$;
      }),
    );

    iif(() => isSingleUserEdit, dialog$, multiple$).subscribe({
      next: (dialogResponse) => {
        this.toast.success({
          id: dialogResponse?.edited ? 'user_edited' : 'user_created',
          message: dialogResponse?.edited
            ? 'common.notifications.users.update.success'
            : 'common.notifications.users.create.success',
        });
        this.clientUserAdminDataSource.selection.clear();
        this.clientUserAdminDataSource.refresh();
      },
    });
  }

  startLoading() {
    this.clientUserAdminDataSource.isLoading$.next(true);
  }

  stopLoading() {
    this.clientUserAdminDataSource.isLoading$.next(false);
  }
}
