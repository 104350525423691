import { Component, HostListener, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DestroyComponent,
  E2eIdDirective,
  emailPatternValidator,
  forbiddenValueValidator,
  SelectOption,
} from '@vdms-hq/shared';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, combineLatest, take } from 'rxjs';
import { PatchPartnerUser, Policy, PolicyService, RightsPartnersService } from '@vdms-hq/api-contract';
import { RightsPartnersRefreshService } from '../../logic/rights-partners-refresh.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RightsPartnersActionsService } from '../../logic/rights-partners-actions.service';
import {
  FormSectionComponent,
  SuffixConfig,
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { RightsPartnersAddDsService } from '../../logic/rights-partners-add-ds.service';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { RightsPartnersCreateEditFormService } from '../../logic/rights-partners-create-edit-form.service';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'vdms-hq-rights-partners-create-edit-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    ReactiveFormsModule,
    FormSectionComponent,
    UIFormModule,
    UIButtonModule,
    UIEmptyResultsModule,
    E2eIdDirective,
    MatTooltip,
  ],
  templateUrl: './rights-partners-create-edit-dialog.component.html',
  styles: [
    `
      .users-action {
        overflow: hidden;
      }
    `,
  ],
})
export class RightsPartnersCreateEditDialogComponent extends DestroyComponent {
  usersToAdd: PatchPartnerUser[] = [];
  usersToRemove: PatchPartnerUser[] = [];

  columnSizes = ['minmax(300px, 5fr)', 'minmax(360px, 6fr)', '50px'];
  noRestrictedPoliciesColumnSizes = ['minmax(300px, 5fr)', '50px'];

  addUser = new FormControl<string>('', {
    nonNullable: true,
    validators: [emailPatternValidator()],
  });

  restrictedPoliciesOptions: SelectOption[] = [];
  partnerUsersLoaded: string[] = [];
  usersLoaded: { uuid: string; policies?: string[] }[] = [];

  isLoading$ = new BehaviorSubject(false);
  editMode$ = new BehaviorSubject(false);

  patchUsers$ = new BehaviorSubject<'attach' | 'detach' | 'all' | null>(null);
  addUserSuffix: SuffixConfig = {
    text: 'Add',
    onFocus: true,
    type: 'primary',
  };

  title = '';
  saveButton = '';

  constructor(
    public formService: RightsPartnersCreateEditFormService,
    private rightsPartnersService: RightsPartnersService,
    private rightsPartnersRefreshService: RightsPartnersRefreshService,
    private rightsPartnersActionsService: RightsPartnersActionsService,
    private rightsPartnersAddDsService: RightsPartnersAddDsService,
    private PolicyService: PolicyService,
    private activatedClientService: ActivatedClientService,
    private dialogRef: MatDialogRef<RightsPartnersCreateEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { uuid: string | undefined },
  ) {
    super();
    if (data.uuid) {
      this.title = 'common.dialogs.rights_partners.edit.title';
      this.saveButton = 'common.dialogs.rights_partners.edit.button';
      this.isLoading$.next(true);
      this.editMode$.next(true);
      combineLatest([
        this.rightsPartnersService.getOne(data.uuid),
        this.rightsPartnersService.getUsers(data.uuid),
        this.activatedClientService.ccRestrictedPolicies$,
        this.PolicyService.getPoliciesUnRestricted(),
      ])
        .pipe(this.takeUntilDestroyed())
        .subscribe(([res, users, ccPolicies, policies]) => {
          this.formService.form.patchValue({
            name: res.name,
            partner_id: res.partner_id,
          });
          this.restrictedPoliciesOptions = policies
            .filter((policies) => ccPolicies.includes(policies.uuid))
            .map((policy) => ({
              key: policy.uuid,
              label: policy.name,
            }));
          this.partnerUsersLoaded = users.data.map((user) => user.email);
          this.usersLoaded = users.data.map((user) => ({
            uuid: user.email,
            policies: user.policies?.map((policy) => policy.uuid),
          }));
          users.data.forEach((user) => {
            this.attachUser(user.email, true, user.policies);
          });
          this.setValidators();
          if (this.restrictedPoliciesOptions.length == 0) {
            this.formService.usersControls.forEach((g) => {
              g.controls.policies.disable();
            });
          }
          this.isLoading$.next(false);
        });
    } else {
      this.title = 'common.dialogs.rights_partners.create.title';
      this.saveButton = 'common.dialogs.rights_partners.create.button';
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onEscape() {
    this.formService.resetForm();
    this.dialogRef.close();
  }

  setValidators() {
    this.addUser.setValidators([
      emailPatternValidator(),
      forbiddenValueValidator(this.formService.userEmailsArray, 'usedMail'),
    ]);
  }

  attachUser(email: string, onLoad = false, policies?: Policy[]) {
    if (!this.formService.usersValue.some((item) => item.email === email)) {
      this.formService.insertUser(0, this.formService.newUserFormGroup(email, policies));
      if (!this.partnerUsersLoaded.includes(email)) {
        !onLoad && this.usersToAdd.push({ email, policies: { attach: [], detach: [] } });
      }
      if (this.usersToRemove.some((item) => item.email === email)) {
        this.usersToRemove = this.usersToRemove.filter((item) => item.email !== email);
      }
      this.setValidators();
    }
    this.addUser.setValue('');
  }

  detachUser(email: string) {
    const index = this.formService.indexByEmail(email);
    if (index > -1) {
      this.formService.removeUser(index);
      if (this.partnerUsersLoaded.includes(email)) {
        this.usersToRemove.push({ email, policies: { attach: [], detach: [] } });
      }
      if (this.usersToAdd.some((item) => item.email === email)) {
        this.usersToAdd = this.usersToAdd.filter((item) => item.email !== email);
      }
      this.setValidators();
    }
  }

  setPatchUsers() {
    const restrictedPoliciesKeys = this.restrictedPoliciesOptions.map((option) => option.key as string);

    // check if user from usersControlValue is in the list of users to add and if is fill the policies
    this.formService.usersValue.forEach((user) => {
      const userToAdd = this.usersToAdd.find((x) => x.email === user.email);
      if (userToAdd && userToAdd.policies?.attach) {
        userToAdd.policies.attach = user.policies;
      }
    });

    //check changes in polices of existing users, if any new policy is added or removed, if added,  add to users to add and to policies attached, if removed add to users to add and to policies detached
    this.usersLoaded.forEach((user) => {
      const userControl = this.formService.usersValue.find((x) => x.email === user.uuid);
      if (userControl) {
        const policiesToAdd = userControl.policies?.filter((x) => !user.policies?.includes(x));
        //only remove policies that are in the list of restricted policies and were deleted/changed in the form control
        const policiesToRemove = user.policies
          ?.filter((policyUuid) => restrictedPoliciesKeys.includes(policyUuid))
          .filter((x) => !userControl.policies?.includes(x));
        if (policiesToAdd && policiesToAdd.length > 0) {
          const userToAdd = this.usersToAdd.find((x) => x.email === user.uuid);
          if (userToAdd && userToAdd.policies) {
            userToAdd.policies.attach = policiesToAdd;
          } else {
            this.usersToAdd.push({ email: user.uuid, policies: { attach: policiesToAdd, detach: [] } });
          }
        }
        if (policiesToRemove && policiesToRemove.length > 0) {
          const userToAdd = this.usersToAdd.find((x) => x.email === user.uuid);
          if (userToAdd && userToAdd.policies) {
            userToAdd.policies.detach = policiesToRemove;
          } else {
            this.usersToAdd.push({ email: user.uuid, policies: { attach: [], detach: policiesToRemove } });
          }
        }
      }
    });

    if (this.usersToAdd.length > 0 && this.usersToRemove.length > 0) {
      this.patchUsers$.next('all');
    } else if (this.usersToAdd.length > 0) {
      this.patchUsers$.next('attach');
    } else if (this.usersToRemove.length > 0) {
      this.patchUsers$.next('detach');
    } else {
      this.patchUsers$.next(null);
    }
  }

  onSubmit() {
    this.formService.form.markAllAsTouched();
    if (this.formService.isInvalid) {
      return;
    }
    this.isLoading$.next(true);

    if (this.editMode$.value && this.data.uuid) {
      this.setPatchUsers();
      this.rightsPartnersService
        .patch(this.data.uuid, {
          name: this.formService.name,
          partner_id: this.formService.partnerId,
        })
        .pipe(this.takeUntilDestroyed(), take(1))
        .subscribe({
          next: () => {
            this.patchUsers();
          },
          error: (error) => {
            this.isLoading$.next(false);
            throw error;
          },
        });
    } else {
      this.rightsPartnersService
        .create({ name: this.formService.name, partner_id: this.formService.partnerId })
        .pipe(this.takeUntilDestroyed(), take(1))
        .subscribe({
          next: () => {
            this.isLoading$.next(false);
            this.rightsPartnersRefreshService.refresh$.next(true);
            this.rightsPartnersAddDsService.refresh$.next(true);
            this.rightsPartnersActionsService.popToast.CREATE_SUCCESS();
            this.closeDialog();
          },
          error: (error) => {
            this.isLoading$.next(false);
            throw error;
          },
        });
    }
  }

  patchUsers() {
    const addUsers$ = this.rightsPartnersService.addUsers(this.data.uuid as string, this.usersToAdd);
    const removeUsers$ = this.rightsPartnersService.removeUsers(this.data.uuid as string, this.usersToRemove);

    switch (this.patchUsers$.value) {
      case 'attach':
        addUsers$.subscribe({
          next: () => {
            this.handleSuccess();
          },
          error: (error) => {
            this.isLoading$.next(false);
            throw error;
          },
        });
        break;
      case 'detach':
        removeUsers$.subscribe({
          next: () => {
            this.handleSuccess();
          },
          error: (error) => {
            this.isLoading$.next(false);
            throw error;
          },
        });
        break;
      case 'all':
        combineLatest([addUsers$, removeUsers$]).subscribe({
          next: () => {
            this.handleSuccess();
          },
          error: (error) => {
            this.isLoading$.next(false);
            throw error;
          },
        });
        break;
      default:
        this.handleSuccess();
    }
  }

  handleSuccess() {
    this.isLoading$.next(false);
    this.rightsPartnersRefreshService.refresh$.next(true);
    this.rightsPartnersAddDsService.refresh$.next(true);
    this.rightsPartnersActionsService.popToast.UPDATE_SUCCESS();
    this.closeDialog();
  }

  closeDialog(): void {
    this.formService.resetForm();
    this.dialogRef.close();
  }
}
