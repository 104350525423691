import { Injectable, inject } from '@angular/core';
import {
  PolicyService,
  ClientsService,
  ClientUserAdminService,
  Policy,
  PermissionModel,
  GetUserDetailsForUserAdminType,
  ClientUserAdminView,
  DefaultPolicies,
  SHARING_COLLECTION_POLICY_TYPE,
  SHARING_SHARED_PACK_POLICY_TYPE,
  PaginationAPIModel as Pagination,
} from '@vdms-hq/api-contract';
import {
  Subject,
  BehaviorSubject,
  startWith,
  tap,
  switchMap,
  combineLatest,
  shareReplay,
  map,
  withLatestFrom,
  Observable,
  debounceTime,
  endWith,
  merge,
} from 'rxjs';
import { LoadableDataSource, RefreshService } from '@vdms-hq/shared';
import { ActivatedClientService, Permission } from '@vdms-hq/activated-client';
import { FormControl, FormGroup } from '@angular/forms';

interface PolicyWithDefaultFor extends Policy {
  defaultFor: string[];
  isRestricted?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ClientUserAdminDialogDataSource implements LoadableDataSource {
  private policyService = inject(PolicyService);
  private clientService = inject(ClientsService);
  private userAdminService = inject(ClientUserAdminService);
  private activatedClientService = inject(ActivatedClientService);
  private refreshService = inject(RefreshService);

  #mappedPolicies?: PolicyWithDefaultFor[];

  form = new FormGroup({
    filter: new FormControl(''),
    templateUser: new FormControl(''),
    templateUserSearch: new FormControl('', { nonNullable: true }),
  });

  #refresh$ = new Subject<void>();
  refresh$ = merge([this.refreshService.refresh$, this.#refresh$]);
  #filter$ = this.form.controls.filter.valueChanges.pipe(startWith(''));
  #selectedPolicies$ = new BehaviorSubject<Policy[]>([]);

  isLoading$ = new BehaviorSubject<boolean>(false);

  allData$: Observable<{
    policies: Policy[];
    defaultPolicies: DefaultPolicies;
    users: GetUserDetailsForUserAdminType[];
  }> = combineLatest([this.refresh$.pipe(startWith(null)), this.activatedClientService.clientIdDefinite$]).pipe(
    tap(() => this.isLoading$.next(true)),
    withLatestFrom(this.form.controls.templateUserSearch.valueChanges.pipe(startWith(''))),
    switchMap(([[, clientId], userSearch]) => {
      const perPage = 300;

      return combineLatest({
        defaultPolicies: this.clientService.getClientDefaultPolicies(clientId),
        policies: this.policyService.getSimplePolicies(),
        users: this.userAdminService
          .getAllUsersForGroup(Pagination.create({ page: 0, perPage, orderBy: 'user.displayName', orderDir: 'asc' }), {
            text: userSearch,
          })
          .pipe(map(({ data }) => data.map((user) => ClientUserAdminView.fromClientUserType(user)))),
      });
    }),
    map(({ policies, defaultPolicies, users }) => {
      return {
        policies,
        defaultPolicies,
        users,
      };
    }),
    tap(() => this.isLoading$.next(false)),
    endWith({
      policies: [],
      defaultPolicies: {},
      users: [],
    }),
    shareReplay(1),
  );

  selectedPolicies$ = this.#selectedPolicies$.asObservable();

  selectedPoliciesUuids$ = this.selectedPolicies$.pipe(map((policies) => policies.map((policy) => policy.uuid)));

  policiesList$: Observable<PolicyWithDefaultFor[]> = combineLatest([
    this.allData$,
    this.#filter$.pipe(debounceTime(200)),
    this.refresh$.pipe(map(() => true)),
  ]).pipe(
    map(([{ policies, defaultPolicies }, filter, refresh]) => {
      const policiesChanged = policies.some((policy) => {
        return !this.#mappedPolicies?.some((mappedPolicy) => mappedPolicy.uuid === policy.uuid);
      });
      const notAvailableToReadPolicies = [SHARING_SHARED_PACK_POLICY_TYPE, SHARING_COLLECTION_POLICY_TYPE];

      if (refresh || !this.#mappedPolicies || policiesChanged) {
        this.#mappedPolicies = policies.map((policy) => {
          const hasUserEditRestrictedPolicy = policy.permissions?.some(
            (permission) => permission.code === Permission.EDIT_RESTRICTED_POLICIES,
          );
          const isPolicyRestricted = policy.restricted;
          let isRestricted = false;

          if (isPolicyRestricted) {
            isRestricted = true;
          }

          if (hasUserEditRestrictedPolicy) {
            isRestricted = false;
          }

          return {
            ...policy,
            defaultFor: Object.entries(defaultPolicies)
              .filter(([key]) => !notAvailableToReadPolicies.includes(key))
              .reduce((acc, [key, value]) => {
                if (value === policy.uuid) {
                  acc.push(key);
                }
                return acc;
              }, [] as string[]),
            isRestricted,
          };
        });

        this.#mappedPolicies.sort((a, b) => b.defaultFor.length - a.defaultFor.length);
      }

      return filter
        ? this.#mappedPolicies.filter((policy) => policy.name.toLowerCase().includes(filter.toLowerCase()))
        : this.#mappedPolicies;
    }),
  );

  usersList$ = this.allData$.pipe(map(({ users }) => users));

  usersSelectOptions$ = this.usersList$.pipe(
    map((users) => {
      const usersOptions = users.map((user) => ({
        key: user.uuid,
        label: user.name,
      }));

      usersOptions.unshift({ key: '', label: 'None' });

      return usersOptions;
    }),
  );

  permissionsList$ = combineLatest([this.selectedPoliciesUuids$, this.policiesList$]).pipe(
    map(([selectedPoliciesUuids, policies]) =>
      policies.filter((policy) => selectedPoliciesUuids.includes(policy.uuid)),
    ),
    map((selectedPolicies) => {
      return selectedPolicies.reduce(
        (acc, policy) => {
          if (!policy.permissions) {
            return acc;
          }
          policy.permissions.forEach((permission) => {
            if (!acc[permission.label]) {
              acc[permission.label] = [];
            }
            acc[permission.label].push(permission);
          });
          return acc;
        },
        {} as { [label: string]: PermissionModel[] },
      );
    }),
    map((permissions) => {
      return Object.entries(permissions).reduce(
        (acc, [label, permissions]) => {
          acc[label] = permissions.filter((permission, index, self) => {
            return index === self.findIndex((p) => p.uuid === permission.uuid);
          });
          return acc;
        },
        {} as { [label: string]: PermissionModel[] },
      );
    }),
    map((permissions) => {
      return Object.entries(permissions).reduce(
        (acc, [label, permissions]) => {
          acc[label] = permissions.sort((a, b) => a.name.localeCompare(b.name));
          return acc;
        },
        {} as { [label: string]: PermissionModel[] },
      );
    }),
  );

  permissionsListTotal$ = this.permissionsList$.pipe(
    map((permissions) => Object.values(permissions).reduce((acc, permissions) => acc + permissions.length, 0)),
  );

  connection$ = this.allData$;

  refresh() {
    this.#refresh$.next();
  }

  policyIsSelected(policy: Policy) {
    return this.selectedPoliciesUuids$.pipe(map((uuids) => uuids.includes(policy.uuid)));
  }

  policyIsDefault(policy: Policy) {
    return this.allData$.pipe(map(({ defaultPolicies }) => Object.values(defaultPolicies).includes(policy.uuid)));
  }

  togglePolicy(event: boolean | null | undefined, policy: Policy) {
    const isSelected = this.#selectedPolicies$.value.some((p) => p.uuid === policy.uuid);

    if (event === true && !isSelected) {
      this.#selectedPolicies$.next([...this.#selectedPolicies$.value, policy]);
      return;
    }

    if (event === false && isSelected) {
      this.#selectedPolicies$.next(this.#selectedPolicies$.value.filter((p) => p.uuid !== policy.uuid));
    }
  }

  emitSelectedPolicies(policies: Policy[]) {
    this.#selectedPolicies$.next(policies);
  }

  reset() {
    this.#selectedPolicies$.next([]);
    this.form.controls.templateUser.setValue('');
    this.form.controls.filter.setValue('');
  }

  searchUsers(search: string) {
    this.form.controls.templateUserSearch.setValue(search);
    this.refresh();
  }
}
