import { Provider } from '@angular/core';
import { METADATA_MINIMUM_PERCENT_ACCURACY } from './logic/filename-conventions.validator';

export interface AccuracyConfig {
  default: number;
  [key: string]: number;
}

const defaultAccuracyConfig = {
  default: 25,
  language: 75,
};

export const configureMetadataRecognition = (config: AccuracyConfig = defaultAccuracyConfig): Provider[] => {
  return [
    {
      provide: METADATA_MINIMUM_PERCENT_ACCURACY,
      useValue: config,
    },
  ];
};
