<vdms-hq-ui-dialog-wrapper footerAlign="space-between">
  <h1 title>Add new user</h1>
  <div content class="dialog-content">
    @if (policiesPerApp$ | async; as policiesPerApp) {
      @switch (currentStep$ | async) {
        @case (0) {
          <span>Please select application you want to assign users to</span>
          @if (isAppButtonVisible$('vida') | async) {
            <vdms-hq-ui-button-advanced
              [imgUrl]="'/assets/images/application_logos/VIDA.svg'"
              [imageOnly]="true"
              [imgHeight]="16"
              [imgWidth]="58"
              [imgInvertColor]="(theme$ | async) === Theme.white"
              (click)="chooseApplication('vida')"
              class="advanced-button"
            ></vdms-hq-ui-button-advanced>
          }
          @if (isAppButtonVisible$('connect2') | async) {
            <vdms-hq-ui-button-advanced
              [imgUrl]="'/assets/images/application_logos/CONNECT2.svg'"
              [imageOnly]="true"
              [imgHeight]="16"
              [imgWidth]="133"
              [imgInvertColor]="(theme$ | async) === Theme.white"
              (click)="chooseApplication('connect2')"
              class="advanced-button"
            ></vdms-hq-ui-button-advanced>
          }
          @if (isAppButtonVisible$('launchpad') | async) {
            <vdms-hq-ui-button-advanced
              [imgUrl]="'/assets/images/application_logos/LAUNCHPAD.svg'"
              [imageOnly]="true"
              [imgHeight]="16"
              [imgWidth]="160"
              [imgInvertColor]="(theme$ | async) === Theme.white"
              (click)="chooseApplication('launchpad')"
              class="advanced-button"
            ></vdms-hq-ui-button-advanced>
          }
          @if (isAppButtonVisible$('storefront') | async) {
            <vdms-hq-ui-button-advanced
              [imgUrl]="'/assets/images/application_logos/STOREFRONT.svg'"
              [imageOnly]="true"
              [imgHeight]="16"
              [imgWidth]="174"
              [imgInvertColor]="(theme$ | async) === Theme.white"
              (click)="chooseApplication('storefront')"
              class="advanced-button"
            ></vdms-hq-ui-button-advanced>
          }
        }
        @case (1) {
          <p>Select type of user you want to add</p>
          @for (policy of policiesPerApp[this.chosenApplication]; track policy.uuid) {
            <vdms-hq-ui-button-advanced
              [title]="policy.name"
              [subtitle]="policy.extra?.description ?? ''"
              [icon]="policy.extra?.icon ?? 'person'"
              (click)="choosePolicy(policy)"
              class="advanced-button"
            ></vdms-hq-ui-button-advanced>
          }
        }
        @case (2) {
          @if (showSupplierFor.includes(this.chosenApplication)) {
            <p>Select the supplier for this launchpad user</p>
            <vdms-hq-ui-form-input-select
              [label]="'common.supplier' | translate"
              [formControl]="supplier"
              [selectOptions]="(suppliersSelectOptions$ | async) ?? []"
              [loading]="suppliersLoading"
            >
            </vdms-hq-ui-form-input-select>
          }

          @if (existingUsersEmails.length) {
            <vdms-hq-ui-info-bar
              [message]="
                'Following email addresses are already in the database:\n' +
                '<strong>' +
                existingUsersEmailsAsText +
                '\n' +
                '</strong>' +
                'Proceed to extend their policies or remove email address.'
              "
              [type]="InfoBarType.WARNING"
            ></vdms-hq-ui-info-bar>
          }

          <p>Add email addresses and press enter after each one</p>
          @if (!!this.data?.multipleEmails) {
            <vdms-hq-ui-form-input-text
              [formControl]="addEmail"
              [placeholder]="'client-user-admin.email_add_hint' | translate"
              [label]="'client-user-admin.emails' | translate"
              [fallbackSuffixIcon]="'add'"
              (keyup.enter)="!addEmail.errors && attachUser(addEmail.value)"
              (clickSuffix)="!addEmail.errors && attachUser(addEmail.value)"
            >
            </vdms-hq-ui-form-input-text>
            <ng-container *ngTemplateOutlet="emailChips"></ng-container>
            <mat-error *ngIf="addEmail.touched && !emails.value.length">Email is required</mat-error>
          } @else {
            <vdms-hq-ui-form-input-text
              [formControl]="addEmail"
              [label]="'client-user-admin.email' | translate"
              [withFooter]="true"
            >
            </vdms-hq-ui-form-input-text>
          }
        }
        @case (3) {
          <div class="success">
            <mat-icon class="success__icon">how_to_reg</mat-icon>
            <h1>Users added!</h1>
            <mat-card class="success__results">
              <mat-card-content>
                <div class="success__results__chips">
                  <ng-container *ngTemplateOutlet="emailChips"></ng-container>
                </div>
                <mat-divider class="success__results__divider"></mat-divider>
                <div class="success__results__selected-policy">
                  @if (chosenPolicy?.extra?.icon) {
                    <mat-icon>{{ chosenPolicy?.extra?.icon }}</mat-icon>
                  }
                  <div>
                    <h2>{{ chosenPolicy?.name }}</h2>
                    <p>{{ chosenPolicy?.extra?.description }}</p>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        }
      }
    } @else {
      <vdms-hq-ui-loader></vdms-hq-ui-loader>
    }
  </div>

  <ng-container footer>
    <div class="buttons-group">
      @if (this.currentStep$.value > 0 && this.currentStep$.value < 3) {
        <vdms-hq-ui-button (click)="back()" color="secondary"
          >{{ 'common.dialog.back_to' | translate: { prevStep: steps[this.currentStep$.value - 1] } }}
        </vdms-hq-ui-button>
      }

      @if (this.currentStep$.value !== this.steps.length - 1) {
        <vdms-hq-ui-button (click)="abort()" color="secondary"
          >{{ 'common.global.dialog.cancel' | translate }}
        </vdms-hq-ui-button>
      }
    </div>

    <div class="buttons-group">
      @if (this.currentStep$.value === 2) {
        <vdms-hq-ui-button
          [disabled]="!canConfirm()"
          [loading]="confirmLoading"
          (click)="confirm()"
          color="primary"
          [isSubmit]="true"
          >{{ 'common.global.confirm' | translate }}
        </vdms-hq-ui-button>
      } @else if (this.currentStep$.value === this.steps.length - 1) {
        <vdms-hq-ui-button (click)="abort()" color="secondary"
          >{{ 'common.dialog.close' | translate }}
        </vdms-hq-ui-button>
      }
    </div>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>

<ng-template #emailChips>
  <mat-chip-listbox class="material-custom-chip-input">
    @if (!this.data?.multipleEmails) {
      <mat-chip-option [selectable]="false" [removable]="false" class="users-chip">
        {{ addEmail.value }}
      </mat-chip-option>
    } @else {
      @for (email of emails.value; track email) {
        <mat-chip-option
          [selectable]="false"
          [removable]="currentStep$.value === 2"
          (removed)="detachEmail(email)"
          class="users-chip"
        >
          {{ email }}
          @if (currentStep$.value === 2) {
            <mat-icon matChipRemove>cancel</mat-icon>
          }
        </mat-chip-option>
      }
    }
  </mat-chip-listbox>
</ng-template>
