<vdms-hq-ui-actions-footer class="action-bar">
  <ng-container left>
    <vdms-hq-ui-button
      color="secondary"
      [matMenuTriggerFor]="menu"
      [iconOnly]="true"
      icon="more_vert"
      *requiresPermissions="
        [
          Permission.RELEASE_QUARANTINED_ASSETS,
          Permission.EDIT_LICENSED_PACKAGES,
          Permission.RELEASE_QUARANTINED_ASSETS,
          Permission.SET_ASSETS_AS_COLD_ONLY,
        ];
        comparison: 'some'
      "
    >
    </vdms-hq-ui-button>
    <mat-menu #menu="matMenu">
      <div class="wrapper d-flex flex-col align-items-center">
        <button
          mat-menu-item
          *requiresPermissions="[Permission.EDIT_LICENSED_PACKAGES]"
          (click)="actions.emit(ResultsActions.ADD_TO_LICENSED_PACKAGE)"
        >
          <span>{{ 'pages.lists.add_to_licensed_packages' | translate }}</span>
        </button>
        <ng-container *ngIf="canMoveToCold$ | async">
          <button
            mat-menu-item
            [disabled]="
              (selectionTotal$ | async) > 500 ||
              (selectedVirtualAssets$ | async) ||
              (selectedQuarantinedAssets$ | async)
            "
            [matTooltipDisabled]="
              (selectedVirtualAssets$ | async) === false &&
              (selectionTotal$ | async) <= 500 &&
              (selectedQuarantinedAssets$ | async) === false
            "
            [matTooltip]="
              'Action disabled if selection has virtual assets, has quarantined assets or more than 500 assets selected'
            "
            [matTooltipClass]="'permissions'"
            *requiresPermissions="[Permission.SET_ASSETS_AS_COLD_ONLY]"
            (click)="actions.emit(ResultsActions.SET_AS_COLD)"
          >
            <span> {{ 'pages.lists.set_as_cold' | translate }}</span>
          </button>
        </ng-container>

        <button
          *requiresPermissions="[Permission.RELEASE_QUARANTINED_ASSETS]"
          mat-menu-item
          (click)="actions.emit(ResultsActions.BATCH_IMPOSE_QUARANTINE)"
          [disabled]="selectedQuarantinedAssets$ | async"
          [matTooltipDisabled]="(selectedQuarantinedAssets$ | async) === false"
          [matTooltip]="'Action disabled if selection has quarantined assets'"
        >
          <span>{{ 'pages.assets_list.impose_quarantine' | translate }}</span>
        </button>

        <button
          *requiresPermissions="[Permission.RELEASE_QUARANTINED_ASSETS]"
          mat-menu-item
          (click)="actions.emit(ResultsActions.BATCH_LIFT_QUARANTINE)"
          [disabled]="(selectedQuarantinedAssets$ | async) === false"
        >
          <span>{{ 'pages.assets_list.lift_quarantine' | translate }}</span>
        </button>

        <button
          *requiresPermissions="[Permission.DELETE_ASSETS]"
          mat-menu-item
          (click)="actions.emit(ResultsActions.DELETE)"
          [disabled]="(selectedQuarantinedAssets$ | async) && (canEditQuarantined$ | async) === false"
        >
          <span>{{ 'common.global.delete_from_vida' | translate }}</span>
        </button>
      </div>
    </mat-menu>
    <vdms-hq-ui-button color="secondary" (click)="actions.emit(ResultsActions.CLEAR_SELECTION)">
      {{ 'common.global.deselect_all' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      color="primary"
      *requiresPermissions="[Permission.EDIT_ASSETS]"
      (click)="actions.emit(ResultsActions.BATCH_UPDATE)"
      [disabled]="(selectedQuarantinedAssets$ | async) && (canEditQuarantined$ | async) === false"
    >
      {{ 'pages.assets_list.batch_update' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      color="primary"
      *requiresPermissions="[Permission.EDIT_COLLECTIONS]"
      (click)="actions.emit(ResultsActions.ADD_TO_COLLECTION)"
    >
      {{ 'pages.lists.add_to_collection' | translate }}
    </vdms-hq-ui-button>
  </ng-container>

  <ng-container center *ngIf="selectionManager?.total$; let total">
    <vdms-hq-ui-button color="transparent">
      {{ 'common.global.selected' | translate: { selected: total | async } }}
    </vdms-hq-ui-button>
  </ng-container>

  <ng-container right>
    <vdms-hq-ui-button
      *requiresPermissions="[Permission.ENABLE_DOWNLOAD_NOW]"
      (click)="actions.emit(ResultsActions.DOWNLOAD_NOW)"
      [disabled]="isDownloadLoading.downloadNowLoading || ((disabledButtons$ | async) ?? false)"
      [sharedE2eId]="'download-now'"
      [loading]="isDownloadLoading.downloadNowLoading ?? false"
      color="secondary"
      >{{ 'pages.assets_list.download_now' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      *requiresPermissions="[Permission.ENABLE_SEND_TO_DOWNLOADS]"
      (click)="actions.emit(ResultsActions.SEND_TO_DOWNLOADS)"
      [sharedE2eId]="'send-to-downloads'"
      [disabled]="isDownloadLoading.sendToDownloadsLoading || ((disabledButtons$ | async) ?? false)"
      [loading]="isDownloadLoading.sendToDownloadsLoading ?? false"
      color="secondary"
      >{{ 'pages.assets_list.send_downloads' | translate }}
    </vdms-hq-ui-button>

    <vdms-hq-ui-button
      *requiresPermissions="[Permission.SHOPPING_CART]"
      (click)="actions.emit(ResultsActions.ADD_TO_CART)"
      [hidden]="disableAddToCart$ | async"
      [sharedE2eId]="'add-to-cart'"
      [disabled]="(disabledButtons$ | async) ?? false"
      color="primary"
      >{{ 'pages.assets_list.add_to_basket' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-actions-footer>
