import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, takeUntil, debounceTime } from 'rxjs';
import {
  DisableIfRowPipe,
  PaginatorComponent,
  TileSelectableConfig,
  UiAddDialogSelectableTilesComponent,
  UIButtonModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILoaderModule,
  UIPipesModule,
  ValueRendererComponent,
} from '@vdms-hq/ui';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { ConnectableDataSource, Destroyable, PageableDataSource, SelectOption, LocalDataSource } from '@vdms-hq/shared';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MetadataHiddenPipe } from '@vdms-hq/ui';

export type SelectableTilesDataSource<T extends SelectOption, F, M> = ConnectableDataSource<T> &
  PageableDataSource & {
    responseData$: Observable<{
      data: M[];
      total: number;
    }>;
    isLoading$: Observable<boolean>;
    emptyResults$: Observable<boolean>;
    filters: F;
    searchKeyword: (keyword: string) => void;
    getFormGroup: () => FormGroup;
  };

export interface SelectableListInterface {
  name: string;
  uuid: string;
  isNew?: boolean;
  toRemove?: boolean;
}

export interface SelectableWrapperConfig<T> {
  filters?: {
    wrap?: boolean;
    withFooter?: boolean;
  };
  datasourceTitle: string;
  tileConfig: TileSelectableConfig<T>;
  selectedList: {
    title: string;
    data: SelectableListInterface[];
  };
  loadingText: string;
  fieldsConfig: DynamicFilterInput[];
  emptyMessage: string;
  searchView: {
    errorMsg?: string;
    placeholder: string;
    label: string;
    showSearch: boolean;
    only?: boolean;
  };
  hasLoader?: boolean;
  fullWidth?: boolean;
  hasEmptyActionButton?: ((text: string) => boolean) | boolean;
  emptyActionButtonText?: string;
  keepSelectedListState?: boolean;
  showPaginator?: boolean;
}

@Component({
  selector: 'vdms-hq-ui-selectable-tiles',
  templateUrl: './ui-selectable-tiles.component.html',
  styleUrls: ['./ui-selectable-tiles.component.scss'],
  standalone: true,
  imports: [
    DynamicFiltersModule,
    MatDividerModule,
    CommonModule,
    UiAddDialogSelectableTilesComponent,
    UIEmptyResultsModule,
    UILoaderModule,
    TranslateModule,
    UIButtonModule,
    UIFormModule,
    DisableIfRowPipe,
    UIPipesModule,
    MatTooltipModule,
    MetadataHiddenPipe,
    ValueRendererComponent,
    PaginatorComponent,
  ],
})
export class UiSelectableTilesComponent<T extends SelectOption, F, M> extends Destroyable() implements OnInit {
  @Input() dataSource!: SelectableTilesDataSource<T, F, M>;
  @Input() selected: string[] = [];
  @Output() emptyActionButtonClick = new EventEmitter<void>();
  @Output() selectedChangeEmit = new EventEmitter<string[]>();
  @Output() updateSelectedListEmit = new EventEmitter<SelectableListInterface[]>();
  @Output() updateConfigEmit = new EventEmitter<SelectableWrapperConfig<T>>();

  @Input() set filters(value: FormGroup) {
    this.#filters = value;
  }

  get filters() {
    if (!this.#filters) {
      throw new Error('Filters not set');
    }
    return this.#filters;
  }

  @Input() set config(value: SelectableWrapperConfig<T>) {
    this.#config = value;
    this.selectedList$.next(value.selectedList.data);
    this.hasLoader = value.hasLoader ?? this.hasLoader;
    this.fullWidth = value.fullWidth ?? this.fullWidth;
    this.emptyActionButtonText = value.emptyActionButtonText ?? this.emptyActionButtonText;
    this.keepSelectedListState = value.keepSelectedListState ?? false;
    this.listViewShow.setValue(!value.searchView.showSearch);
    this.showPaginator = value.showPaginator ?? true;
    this.onlySearch = value.searchView.only ?? false;
    this.showActionEmptyButton$.next(value.hasEmptyActionButton !== undefined);
  }

  get config() {
    if (!this.#config) {
      throw new Error('Config not set');
    }
    return this.#config;
  }

  #config!: SelectableWrapperConfig<T>;
  hasLoader = true;
  fullWidth = false;
  hasEmptyActionButton = false;
  emptyActionButtonText = '';
  keepSelectedListState = false;
  showPaginator = true;
  onlySearch = false;
  #filters?: FormGroup;
  selectedListFilterInput$ = new BehaviorSubject<string>('');
  selectedList$: BehaviorSubject<SelectableListInterface[]> = new BehaviorSubject<SelectableListInterface[]>([]);

  total$ = new BehaviorSubject(0);
  emptyResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showActionEmptyButton$ = new BehaviorSubject<boolean>(false);
  selectedDataSource!: LocalDataSource<SelectableListInterface>;

  listViewShow = new FormControl(false);

  ngOnInit() {
    this.selectedList$.next(this.config.selectedList.data);
    this.selectedDataSource = new LocalDataSource<SelectableListInterface>([]);

    combineLatest([this.selectedListFilterInput$, this.selectedList$, this.dataSource.pageSize$])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([value, list, pageSize]) => {
        const filteredValue = list.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
        this.selectedDataSource.updateData(filteredValue);
        this.selectedDataSource.pageSize$.next(pageSize);
        this.emptyResults$.next(filteredValue.length === 0);
        this.total$.next(filteredValue.length);
      });

    this.filters.controls['keyword'].valueChanges
      .pipe(takeUntil(this.isDestroyed$), debounceTime(400))
      .subscribe((value) => {
        if (this.config.hasEmptyActionButton !== undefined) {
          if (typeof this.config.hasEmptyActionButton === 'function') {
            this.hasEmptyActionButton = this.config.hasEmptyActionButton(value);
          } else {
            this.hasEmptyActionButton = this.showActionEmptyButton$.value;
          }
        }
      });
  }

  emptyActionButtonClicked() {
    this.emptyActionButtonClick.emit();
  }

  applyFilter($event: KeyboardEvent) {
    const value = ($event.target as HTMLFormElement)['value'];
    this.selectedListFilterInput$.next(value);
  }

  selectedChange($event: SelectOption) {
    this.updateSelectedList({ name: $event.label, uuid: $event.key as string });
  }

  removeItem(item: SelectableListInterface) {
    this.updateSelectedList(item);
  }

  updateSelected(selected: string) {
    const selectedUuids = this.selected;
    if (selectedUuids.includes(selected)) {
      this.selected = selectedUuids.filter((uuid) => uuid !== selected);
    } else {
      this.selected = [...selectedUuids, selected];
    }
    this.selectedChangeEmit.emit(this.selected);
  }

  updateSelectedList(item: SelectableListInterface) {
    const selected = this.selected;
    const selectedList = this.selectedList$.value;
    const selectedItem = selectedList.find((selected) => selected.uuid === item.uuid);

    if (!selected.includes(item.uuid) && (!selectedItem || selectedItem.toRemove)) {
      if ((selectedItem && !selectedItem.toRemove) || !selectedItem) {
        selectedList.unshift({ name: item.name, uuid: item.uuid, isNew: true });
        this.selectedList$.next(selectedList);
      }
    }

    if (selectedItem) {
      if (selectedItem.toRemove) {
        this.selectedList$.next(
          selectedList.map((selected) => (selected.uuid === item.uuid ? { ...selected, toRemove: false } : selected)),
        );
      } else {
        if (selectedItem.isNew) {
          this.selectedList$.next(selectedList.filter((selected) => selected.uuid !== item.uuid));
        } else {
          this.selectedList$.next(
            selectedList.map((selected) =>
              selected.uuid === item.uuid
                ? {
                    ...selected,
                    toRemove: true,
                  }
                : selected,
            ),
          );
        }
      }
    }
    this.updateSelected(item.uuid);

    if (this.keepSelectedListState) {
      this.updateSelectedListEmit.emit(this.selectedList$.value);
    }

    if (!selectedItem) {
      this.selectedDataSource.pageIndex$.next(0);
    }
  }

  toggleSearchView() {
    this.config.searchView.showSearch = !this.listViewShow.value;
    this.updateConfigEmit.emit(this.config);
  }
}
