import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, switchMap, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AsperaDownloaderService } from '../aspera/aspera-downloader.service';
import { ASPERA_CONFIG } from '../config-token';
import {
  ASPERA_DOWNLOAD_STATUS,
  AsperaDownloadableFile,
  OrderService,
  PostAsperaDownloadStatus,
} from '@vdms-hq/api-contract';
import { TransferEvent } from '../aspera/model/aspera-transfer-event.model';
import { ToastService } from '@vdms-hq/toast';

/**
 * todo Move the domain logic (fetchOrder, downloadWithAspera, navigation) to the asset-storage library
 */
@Injectable({
  providedIn: 'root',
})
export class AssetDownloadService {
  private toastService = inject(ToastService);
  private aspera = inject(AsperaDownloaderService);
  completedTransfer$ = this.aspera.completedTransfer$;
  private orderService = inject(OrderService);
  private router = inject(Router);
  private config = inject(ASPERA_CONFIG);
  private completedTransferArray: TransferEvent[] = [];
  private asperaPayloadData!: AsperaDownloadableFile;

  withAspera(response: AsperaDownloadableFile): void {
    this.aspera.initAsperaConnect();
    this.aspera.runOutsideAngular({
      remote_host: response.remoteHost ?? this.config.remoteHost,
      remote_user: response.remoteUser ?? this.config.remoteUser,
      direction: 'receive',
      authentication: 'token',
      paths: response.paths ?? [],
      cookie: response.cookie,
      token: response.token,
      ssh_port: response.sshPort ?? 33001,
      fasp_port: response.faspPort ?? 33001,
    });
  }

  withHttps(downloadableUrls?: string[]) {
    if (!downloadableUrls || !downloadableUrls.length || !downloadableUrls.some((url) => url.includes('https'))) {
      return 0;
    }

    const delay = 5000;
    const estimatedTime = downloadableUrls.length * delay;

    const prepareDownloads = (url: string, delay: number) => {
      setTimeout(() => {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'filename.ext'; // Suggest a filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, delay);
    };

    downloadableUrls.forEach((url, index) => {
      prepareDownloads(url, (index + 1) * delay);
    });

    return estimatedTime;
  }

  fetchOrder(token: string) {
    this.orderService
      .downloadWithAspera(token)
      .pipe(
        map(({ data }) => data),
        tap((asperaPayload: AsperaDownloadableFile): void => {
          this.asperaPayloadData = asperaPayload;
          this.toastService.info({
            id: 'processing_download',
            message: 'common.notifications.generic.download_processing',
          });
          this.withAspera(asperaPayload);
        }),
        switchMap(() => this.completedTransfer$),
        filter(Boolean),
        tap((completedTransfer) => {
          if (
            completedTransfer &&
            this.completedTransferArray.find((uniqueTransfer) => uniqueTransfer.uuid === completedTransfer.uuid) ===
              undefined
          ) {
            this.completedTransferArray.push(completedTransfer);

            if (completedTransfer?.file_counts?.failed === 0 && completedTransfer?.file_counts?.skipped === 0) {
              this.sendDownloadStatus({
                status: ASPERA_DOWNLOAD_STATUS.SUCCESS,
                transferUuid: this.asperaPayloadData.transferUuid,
              });
            }
          }
        }),
      )
      .subscribe({
        next: () => this.router.navigate(['/']),
        error: (error) => {
          console.error(error);
          this.router.navigate(['/']);
        },
      });
  }

  sendDownloadStatus(status: PostAsperaDownloadStatus) {
    this.orderService.sendOrderDownloadStatus(status).pipe(take(1)).subscribe();
  }
}
