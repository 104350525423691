<vdms-hq-ui-dialog-wrapper>
  <h1 title>{{ 'pages.fields.edit_field' | translate }}</h1>
  <form content [formGroup]="editSelectOptionForm" class="fit-inputs metadata-wrapper">
    <vdms-hq-ui-loader *ngIf="(loading$ | async) === true; else dialog"></vdms-hq-ui-loader>
    <ng-template #dialog>
      <div class="relation--inputs__section">
        <vdms-hq-ui-form-input-text formControlName="type_name" label="Field Type"></vdms-hq-ui-form-input-text>
        <vdms-hq-ui-form-input-text
          formControlName="label"
          [label]="'pages.fields.columns.label' | translate"
        ></vdms-hq-ui-form-input-text>
      </div>
      <div class="relation--inputs__section">
        <ng-container [formGroupName]="'extra'">
          <vdms-hq-ui-form-input-select
            [label]="'Restricted to'"
            [formControlName]="'restricted'"
            [selectOptions]="assetMasterTypes"
            [enableClear]="true"
            [multiple]="true"
          ></vdms-hq-ui-form-input-select>
        </ng-container>
        <vdms-hq-ui-form-input-select
          label="Parent field type"
          formControlName="parent_field_type_name"
          [selectOptions]="fieldGroups"
        ></vdms-hq-ui-form-input-select>
        <vdms-hq-ui-form-input-select
          label="Parent field value"
          formControlName="parent_field_uuid"
          [selectOptions]="fieldsDataSource[editSelectOptionForm?.value['parent_field_type_name']] ?? []"
        ></vdms-hq-ui-form-input-select>
      </div>
      <ng-container [formGroupName]="'extra'">
        <vdms-hq-selectors-select-options-edit-extra
          [formControlName]="'siblings'"
        ></vdms-hq-selectors-select-options-edit-extra>
      </ng-container>
      <div class="field-identifier--section__choice">
        <section class="checkboxes" formGroupName="enabled">
          <ul>
            <li>
              <mat-checkbox formControlName="in_forms">{{
                'pages.fields.columns.enabled_in_forms' | translate
              }}</mat-checkbox>
            </li>
            <li>
              <mat-checkbox formControlName="in_search">{{
                'pages.fields.columns.enabled_in_search' | translate
              }}</mat-checkbox>
            </li>
          </ul>
        </section>
      </div>
    </ng-template>
  </form>

  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="cancel()">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button
      color="primary"
      (click)="save()"
      [loading]="saveInProgress$ | async"
      [disabled]="(dirty$ | async) === false"
    >
      {{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
