export enum ENDPOINTS {
  TRANSCRIPTION_ROOT = 'transcription',
  ASSET_ROOT = 'asset',
  AI_ROOT = 'ai',
}

type TranscriptionSource = 'Whisper';
type SummarySource = 'OpenAI';
type Status = 'pending' | 'fail' | 'complete';

export interface TranscriptionModel {
  uuid: string;
  assetUuid: string;
  source: TranscriptionSource;
  default: boolean;
  status: Status;
}

export interface TranscriptionItem {
  uuid: string;
  transcriptionUuid: string;
  timecodeIn: number;
  timecodeOut: number | null;
  content: string;
}

export interface SummaryModel {
  uuid: string;
  status: Status;
  source: SummarySource;
  content: string | null;
}

export interface RunModel {
  modelUuid?: string;
  speed?: string;
  options?: {
    tone?: string;
  };
}

export interface AIModelModel {
  uuid: string;
  name: string;
  context: 'transcribe' | 'summary';
  isDefault: boolean;
}
