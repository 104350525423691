import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ButtonAdvancedComponent,
  InfoBarClickAction,
  InfoBarType,
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
  UILayoutModule,
  UILoaderModule,
  DialogResponse,
} from '@vdms-hq/ui';
import {
  DestroyComponent,
  emailPatternValidator,
  forbiddenValueValidator,
  SelectOption,
  SharedModule,
} from '@vdms-hq/shared';
import {
  BatchUserToUserTypeResponse,
  ClientsService,
  ClientUserAdminService,
  Policy,
  PolicyService,
  PostBatchUserToUserType,
  SimpleType,
  Supplier,
  SupplierService,
  VidaAppType,
  VidaAppTypes,
} from '@vdms-hq/api-contract';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { v4 as uuidv4 } from 'uuid';
import { ToastService } from '@vdms-hq/toast';
import { HttpErrorResponse } from '@angular/common/http';
import { Theme, ThemeSwitcherService } from '@vdms-hq/theming';

interface UserData {
  name: string;
  email: string;
  uuid: string;
  groups: string[];
  policy_uuids: string[];
  supplier: string;
  office_phone_number: string;
  mobile_number: string;
  office_address: string;
}

export interface WizardDialogData {
  multipleEmails: boolean;
  mode: 'client_users' | 'collections_share';
  email: string;
}

type PoliciesPerApp = {
  [key in VidaAppType]?: Policy[];
};

@Component({
  selector: 'vdms-hq-new-user-wizard-dialog',
  standalone: true,
  imports: [
    UILayoutModule,
    UIButtonModule,
    SharedModule,
    UIDialogWrapperModule,
    ButtonAdvancedComponent,
    UILoaderModule,
    UIFormModule,
    MatCardContent,
    MatCard,
    MatDivider,
  ],
  templateUrl: './new-user-wizard-dialog.component.html',
  styleUrls: ['./new-user-wizard-dialog.component.scss'],
})
export class NewUserWizardDialogComponent extends DestroyComponent implements OnInit {
  protected readonly InfoBarClickAction = InfoBarClickAction;
  protected readonly InfoBarType = InfoBarType;
  protected readonly Theme = Theme;

  readonly theme = inject(ThemeSwitcherService);
  dialogRef = inject(MatDialogRef<NewUserWizardDialogComponent>);
  clientService = inject(ClientsService);
  supplierService = inject(SupplierService);
  activatedClientService = inject(ActivatedClientService);
  clientUserAdminService = inject(ClientUserAdminService);
  toastService = inject(ToastService);
  data: WizardDialogData = inject(MAT_DIALOG_DATA);
  policyService = inject(PolicyService);

  steps = ['App Selection', 'User Type', 'Email Choice', 'Results'];
  currentStep$ = new BehaviorSubject(0);
  theme$ = this.theme.theme$;

  chosenPolicy: Policy | null = null;
  chosenApplication: VidaAppType = 'vida';

  showSupplierFor: VidaAppType[] = ['launchpad'];

  emails = new FormControl<string[]>([], {
    nonNullable: true,
    validators: [Validators.minLength(1)],
  });
  addEmail = new FormControl<string>('', {
    nonNullable: true,
    validators: [emailPatternValidator()],
  });
  supplier = new FormControl<string>('', {
    validators: [Validators.required],
  });
  suppliersLoading = true;
  suppliersSelectOptions$: Observable<SelectOption[]> = this.supplierService.getSuppliers().pipe(
    map((suppliers: Supplier[]) => suppliers.map(({ uuid: key, name: label }: SimpleType) => ({ key, label }))),
    tap(() => {
      this.suppliersLoading = false;
    }),
  );

  existingUsersEmails: string[] = [];
  existingUsersEmailsConfirm: boolean | undefined = undefined;

  confirmLoading = false;

  #policiesPerApp$: ReplaySubject<PoliciesPerApp> = new ReplaySubject<PoliciesPerApp>();
  policiesPerApp$: Observable<PoliciesPerApp> = this.#policiesPerApp$.asObservable();

  #newUserPolicy?: Policy;
  #isCollectionShareMode = this.data.mode === 'collections_share';
  #createdUsersUuids: { uuid: string; email: string }[] = [];

  get existingUsersEmailsAsText() {
    return this.existingUsersEmails.join(', ').toString();
  }

  ngOnInit() {
    this.setValidators();

    if (this.#isCollectionShareMode) {
      this.currentStep$.next(2);
      this.emails.setValue([this.data.email]);
    }

    combineLatest([
      this.policyService.getSimplePolicies(),
      this.activatedClientService.clientIdDefinite$.pipe(
        switchMap((clientId) => this.clientService.getClient(clientId)),
      ),
    ])
      .pipe(
        map(([policies, client_policies]) => {
          const wizard_policies = client_policies?.wizard_policies;
          const default_policies = client_policies?.default_policies;
          const vidaApp = VidaAppTypes[0];

          this.#newUserPolicy = policies.find((policy) => policy.name.toLowerCase() === 'new user');
          const policyUuidPerApp: { [key in VidaAppType]?: string[] } = {};
          for (let type of VidaAppTypes) {
            if (!wizard_policies) {
              return;
            }
            policyUuidPerApp[type] = [...(policyUuidPerApp[type] ?? []), ...(wizard_policies[type] ?? [])];

            if (Object.values(wizard_policies).every((arr) => arr.length === 0)) {
              const policies = [...(policyUuidPerApp[vidaApp] ?? [])];
              if (this.#newUserPolicy) {
                policyUuidPerApp[vidaApp] = [this.#newUserPolicy.uuid, ...policies];
              } else {
                policyUuidPerApp[vidaApp] = policies;
              }
            }
          }

          if (this.#isCollectionShareMode) {
            if (!default_policies) {
              return;
            }
            policyUuidPerApp[vidaApp] = default_policies.sharing_collection
              ? [default_policies.sharing_collection]
              : [];
          }

          const policiesPerApp: { [key in VidaAppType]?: Policy[] } = {};
          policies.forEach((policy) => {
            Object.entries(policyUuidPerApp).forEach(([appName, uuids]) => {
              if (uuids.some((uuid) => uuid == policy.uuid)) {
                policiesPerApp[appName as VidaAppType] = [
                  ...(policiesPerApp[appName as VidaAppType] ?? []),
                  policy,
                ].sort((a, b) => (a.name.toLowerCase() === 'new user' ? -1 : 1));
              }
            });
          });

          if (this.#isCollectionShareMode) {
            for (let type of VidaAppTypes.filter((item) => item !== vidaApp)) {
              policiesPerApp[type] = [];
            }

            this.choosePolicy(policiesPerApp[vidaApp]?.[0] as Policy, true);
          }

          return policiesPerApp;
        }),
        take(1),
      )
      .subscribe((policiesPerApp) => {
        if (!policiesPerApp) {
          return;
        }

        this.#policiesPerApp$.next(policiesPerApp);
      });

    this.emails.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => (this.existingUsersEmailsConfirm = false));
  }

  attachUser(email: string) {
    const newEmailArray: string[] = [email];
    const controlArray = this.emails.value;
    if (!controlArray.some((item) => item === email)) {
      this.emails.setValue(newEmailArray.concat(controlArray));
      this.setValidators();
    }
    this.addEmail.setValue('');
  }

  static noWhitespaceValidator(control: FormControl<string>): ValidationErrors | null {
    return (control.value || '').trim().length ? null : { required: true };
  }

  setValidators() {
    this.addEmail.clearValidators();
    if (!!this.data?.multipleEmails) {
      this.addEmail.setValidators([emailPatternValidator(), forbiddenValueValidator(this.emails.value, 'usedMail')]);
    } else {
      this.addEmail.setValidators([
        emailPatternValidator(),
        Validators.required,
        NewUserWizardDialogComponent.noWhitespaceValidator,
      ]);
    }
  }

  detachEmail(email: string) {
    if (this.emails.value) {
      this.emails.setValue(this.emails.value.filter((item) => item !== email));
      this.setValidators();
    }
  }

  choosePolicy(policy: Policy, skipNext = false) {
    this.chosenPolicy = policy;

    if (skipNext) {
      return;
    }
    this.next();
  }

  abort() {
    if (this.currentStep$.value === this.steps.length - 1) {
      this.dialogRef.close({ status: DialogResponse.OK, users: this.#createdUsersUuids });
    } else {
      this.dialogRef.close(false);
    }
  }

  back() {
    this.addEmail.reset('');
    this.emails.reset([]);
    this.setValidators();
    if (this.currentStep$.value > 0) {
      this.currentStep$.next(this.currentStep$.value - 1);
    }
  }

  canConfirm() {
    return !!this.data?.multipleEmails
      ? this.emails.value.length > 0
      : this.addEmail.valid &&
          (!this.showSupplierFor.includes(this.chosenApplication) ||
            (this.showSupplierFor.includes(this.chosenApplication) && this.supplier.valid));
  }

  emailToUserData(email: string, clientId: string): UserData {
    return {
      email: email,
      groups: [clientId],
      mobile_number: '',
      name: email,
      office_address: '',
      office_phone_number: '',
      policy_uuids: !!this.chosenPolicy ? [this.chosenPolicy.uuid] : [],
      supplier: this.supplier.value ?? '',
      uuid: uuidv4(),
    };
  }

  batchUserToUserData(emails: string[]): PostBatchUserToUserType {
    return {
      emails,
      policy_uuid: this.chosenPolicy?.uuid ?? null,
      supplier_uuid: this.supplier.value ?? undefined,
      confirm: this.existingUsersEmailsConfirm ?? undefined,
      default_policy_for: this.#isCollectionShareMode ? 'sharing_collection' : undefined,
    };
  }

  confirm() {
    this.confirmLoading = true;
    this.activatedClientService.clientIdDefinite$
      .pipe(
        this.takeUntilDestroyed(),
        switchMap((clientId) => {
          if (!!this.data?.multipleEmails) {
            const data = this.batchUserToUserData(this.emails.value);
            return this.clientUserAdminService.createUserForGroupBatch(data).pipe(
              catchError((err: HttpErrorResponse) => {
                const errorResponse: BatchUserToUserTypeResponse = err.error;
                this.existingUsersEmails = errorResponse.error?.existingUsersEmails ?? [];
                this.existingUsersEmailsConfirm = true;
                this.confirmLoading = false;

                return EMPTY;
              }),
            );
          }
          const userData: UserData = this.emailToUserData(this.addEmail.value, clientId);
          return this.clientUserAdminService.createUserForGroup(userData);
        }),
        catchError((err) => {
          console.warn(err);

          this.confirmLoading = false;
          return err;
        }),
        tap(() => {
          this.confirmLoading = false;
          if (!!this.data?.multipleEmails) {
            this.toastService.success({
              id: 'new-wizard-users-success',
              message: `Successfully created ${this.emails.value.length} user(s).`,
            });
          } else {
            this.toastService.success({
              id: 'new-wizard-users-success',
              message: `Successfully created user.`,
            });
          }

          this.next();
        }),
      )
      .subscribe(
        (resp) =>
          (this.#createdUsersUuids =
            (resp as BatchUserToUserTypeResponse).data.map((user) => ({ uuid: user.uuid, email: user.email })) ?? []),
      );
  }

  isAppButtonVisible$(appName: VidaAppType) {
    return this.policiesPerApp$.pipe(
      map((policiesPerApp: PoliciesPerApp) => {
        const policiesForApp = policiesPerApp[appName];
        return !!policiesForApp && policiesForApp.length > 0;
      }),
    );
  }

  chooseApplication(app: VidaAppType) {
    this.chosenApplication = app;
    this.next();
  }

  next() {
    if (this.currentStep$.value < this.steps.length - 1) {
      this.currentStep$.next(this.currentStep$.value + 1);
    }
  }
}
