import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgIf, NgForOf, NgStyle } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { UIButtonModule, UIModule } from '@vdms-hq/ui';
import { PlayerAction, Subtitles } from '../../logic/player.interface';
import { OmakasePlayer } from '@byomakase/omakase-player';
import { Timecode } from '@vdms-hq/timecode';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { LoadedSubtitle } from '../../logic/advanced-player.service';
import { SelectOption, ExtractLabelPipe } from '@vdms-hq/shared';
import { FormatTimePipe } from '../../pipes/format-time.pipe';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'vdms-hq-advanced-player-controls',
  templateUrl: './advanced-player-controls.component.html',
  styleUrls: ['./advanced-player-controls.component.scss'],
  standalone: true,
  imports: [
    MatSliderModule,
    NgIf,
    UIButtonModule,
    MatMenuModule,
    NgForOf,
    TranslateModule,
    NgStyle,
    UIModule,
    ExtractLabelPipe,
    FormatTimePipe,
    FormsModule,
  ],
  providers: [FormatTimePipe],
})
export class AdvancedPlayerControlsComponent {
  @Input() player?: OmakasePlayer;
  @Input() isPictureInPictureAvailable = false;
  @Input() peakMeterConnected?: boolean;
  @Input() sliderMax = 0;
  @Input() sliderValue = 0;
  @Input() currentTimecode?: Timecode | null;
  @Input() duration?: Timecode | null;
  @Input() spriteCss: Record<string, string | number> | null = null;
  @Input() closedCaptions: boolean | null = null;
  @Input() currentPlaybackRate = 1;
  @Input() playbackRates: number[] = [0.25, 0.5, 1, 2, 4, 8, 16];
  @Input() currentSubtitles: Subtitles | null = null;
  @Input() loadedSubtitles: LoadedSubtitle[] | null = [];
  @Input() currentQuality: number | null = null;
  @Input() currentQualityPlayerV2: string | null = null;
  @Input() qualityOptions: SelectOption[] = [];
  @Input() audioTracksOptions: SelectOption[] = [];
  @Input() currentAudioTrack: number | null = null;
  @Input() isAdvancedV2 = false;
  @Output() action = new EventEmitter<{
    key: PlayerAction;
    volume?: number | null;
    subtitles?: Subtitles;
    qualityLevel?: string | number;
    rate?: number;
    audioTrack?: number;
  }>();
  @Output() sliderValueChange = new EventEmitter<number>();
  @Output() mouseMoveTimelineBar = new EventEmitter<MouseEvent>();
  @Output() mouseLeaveTimelineBar = new EventEmitter<MouseEvent>();

  protected readonly Number = Number;
  fixedControls = false;

  emitAction(action: {
    key: PlayerAction;
    volume?: number | null;
    subtitles?: Subtitles;
    qualityLevel?: string | number;
    audioTrack?: number;
    rate?: number;
  }) {
    const { key, volume, subtitles, qualityLevel, rate, audioTrack } = action;

    if (key === 'updateVolume' && volume !== undefined && volume !== null) {
      this.action.emit({ key, volume });
      return;
    }

    if (key === 'changeSubtitles' && subtitles) {
      this.action.emit({ key, subtitles });
      return;
    }

    if (key === 'changeQualityLevel' && qualityLevel !== undefined) {
      this.action.emit({ key, qualityLevel });
      return;
    }

    if (key === 'changePlaybackRate' && rate !== undefined) {
      this.action.emit({ key, rate });
      return;
    }

    if (key === 'changeAudioTrack' && audioTrack !== undefined) {
      this.action.emit({ key, audioTrack });
      return;
    }

    this.action.emit({ key });
  }

  formatVolumeTooltip(volume: number) {
    return `${(volume * 100).toFixed()}%`;
  }

  formatSliderTooltip(value: number) {
    return Timecode.fromSeconds(value)?.toString() ?? 'N/A';
  }

  onSliderValueChange(e: number | null) {
    this.sliderValueChange.emit(e ?? 0);
  }

  onMouseMoveTimelineBar(e: MouseEvent) {
    this.mouseMoveTimelineBar.emit(e);
  }

  onMouseLeaveTimelineBar(e: MouseEvent) {
    this.mouseLeaveTimelineBar.emit(e);
  }
}
