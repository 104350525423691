import { Component, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthMfaService, AuthService } from '@vdms-hq/auth';
import { ToastService } from '@vdms-hq/toast';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, filter, map, take } from 'rxjs/operators';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TotpSecret } from '@angular/fire/auth';
import { throwError } from 'rxjs';
import { UIButtonModule, UIConfirmationDialogService, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { string } from 'zod';

export interface MfaDialogOutput {
  added?: boolean;
}

@Component({
  selector: 'vdms-hq-mfa-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UIButtonModule,
    FormsModule,
    ReactiveFormsModule,
    UIFormModule,
    MatTabsModule,
  ],
  templateUrl: './mfa-dialog.component.html',
})
export class MfaDialogComponent implements OnDestroy {
  confirmationDialog = inject(UIConfirmationDialogService);

  processing = false;
  tabState: 'sms' | 'totp' = 'sms';
  totpSecretKey$ = this.mfaService.totpSecretKey$;
  totpDoesntExist$ = this.mfaService.factors$.pipe(
    map((factor) => !factor?.find(({ factorId }) => factorId === 'totp')),
    filter(Boolean),
  );

  form = new FormGroup({
    step1: new FormGroup({
      name: new FormControl<string | null>(null, Validators.required),
      phone: new FormControl<string | null>(null, Validators.required),
    }),
    step2: new FormGroup({
      verificationId: new FormControl<string | null>(null, [Validators.required]),
      code: new FormControl<string | null>(null, [Validators.required]),
    }),
  });

  secondaryForm = new FormGroup({
    code: new FormControl<string | null>(null, [Validators.required]),
  });

  get step1Ctrl() {
    return this.form.controls.step1;
  }

  get step2Ctrl() {
    return this.form.controls.step2;
  }

  get name() {
    return this.step1Ctrl.controls.name.value;
  }

  get phoneNumber() {
    return this.step1Ctrl.controls.phone.value;
  }

  get code() {
    return this.step2Ctrl.controls.code.value;
  }

  get verificationId() {
    return this.step2Ctrl.controls.verificationId.value;
  }

  constructor(
    private mfaService: AuthMfaService,
    private authService: AuthService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<MfaDialogComponent, MfaDialogOutput>,
  ) {}

  ngOnDestroy() {
    this.mfaService.destroy();
  }

  initTotp() {
    this.mfaService
      .initTotpMfa()
      .pipe(take(1))
      .subscribe((errorMsg) => {
        if (errorMsg && errorMsg.errorMsg === 'requires-recent-login') {
          this.logoutAndClose();
        }
      });
  }

  finalizeTotpVerification(totpSecret: TotpSecret) {
    const { code } = this.secondaryForm.value;
    if (!code) {
      return;
    }

    this.mfaService
      .finalizeTotp(totpSecret, code)
      .pipe(
        take(1),
        catchError((err) => {
          if (err.message.includes('auth/invalid-verification-code')) {
            this.toastService.error({ id: 'invalid_code', message: 'common.account_settings.mfa.invalid_code' });
          }
          return throwError(err);
        }),
      )
      .subscribe(() => {
        this.toastService.success({ id: 'totp_success', message: 'common.account_settings.mfa.totp_success' });
        this.dialogRef.close({ added: true });
      });
  }

  #restartTotp() {
    this.tabState === 'totp' ? this.initTotp() : this.mfaService.destroy();
  }

  sendVerification() {
    if (this.step1Ctrl.invalid || !this.phoneNumber) {
      this.step1Ctrl.markAllAsTouched();
      return;
    }
    this.processing = true;

    this.mfaService.setUpNewPhone(this.phoneNumber).subscribe(({ verificationId, logout }) => {
      this.processing = false;
      if (!verificationId) {
        if (logout) {
          this.logoutAndClose();
        }
        return;
      }

      this.step1Ctrl.disable();
      this.step2Ctrl.setValue({ verificationId, code: null });
      this.toastService.success({
        id: 'mfa_setup',
        message: 'common.account_settings.mfa.code_sent',
      });
    });
  }

  confirmNumber() {
    if (this.step2Ctrl.invalid || !this.name || !this.verificationId) {
      this.step2Ctrl.markAllAsTouched();
      return;
    }

    this.processing = true;
    this.mfaService.confirmNewPhone(this.name, this.verificationId, String(this.code)).subscribe((confirmed) => {
      this.processing = false;

      if (!confirmed) {
        this.step2Ctrl.patchValue({ code: null });
        return;
      }

      this.toastService.success({
        id: 'mfa_setup',
        message: 'common.account_settings.mfa.mfa_added',
      });

      this.dialogRef.close({ added: true });
    });
  }

  tabChanged(tabIndex: number) {
    this.tabState = tabIndex ? 'totp' : 'sms';
    this.#restartTotp();
  }

  closeDialog() {
    this.dialogRef.close({ added: false });
  }

  logoutAndClose() {
    this.confirmationDialog
      .open({
        title: 'string',
        message: 'string',
        okAction: {
          label: 'Logout',
          color: 'primary',
        },
        abortAction: {
          label: 'Cancel',
          color: 'secondary',
        },
      })
      .subscribe((res) => {
        if (res) {
          setTimeout(() => {
            this.authService.logout().then(() => this.dialogRef.close({ added: false }));
          }, 1500);
        }
      });
  }
}
