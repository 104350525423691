import { WithPermissions } from '@vdms-hq/activated-client';
import { FormControl } from '@angular/forms';
import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogResponse, UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ClientUserAdminView } from '@vdms-hq/api-contract';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { user } from '@angular/fire/auth';
import { SelectorsModule, SelectorSourceType } from '@vdms-hq/selectors';

@Component({
  selector: 'vdms-hq-change-user-supplier',
  standalone: true,
  imports: [CommonModule, UIFormModule, UIDialogWrapperModule, UIButtonModule, TranslateModule, SelectorsModule],
  templateUrl: './change-user-supplier-dialog.component.html',
})
export class ChangeUserSupplierDialogComponent extends WithPermissions() {
  public data = inject(MAT_DIALOG_DATA) as { user: ClientUserAdminView };
  public dialogRef = inject(MatDialogRef<ChangeUserSupplierDialogComponent>);

  supplierControl = new FormControl<string | null>(this.data.user.supplier?.uuid ?? null);

  onSubmit() {
    this.dialogRef.close({
      status: DialogResponse.OK,
      supplier: this.supplierControl.value == 'null' ? null : this.supplierControl.value,
    });
  }

  onAbort() {
    this.dialogRef.close({
      status: DialogResponse.ABORT,
    });
  }

  protected readonly user = user;
  protected readonly SelectorSourceType = SelectorSourceType;
}
