export enum Permission {
  APPROVE_ORDERS = 'approve_orders',
  ASSET_UPLOAD = 'asset_upload',
  ASSET_REUPLOAD = 'asset_reupload',
  /**
   * @deprecated use ASSET_UPLOAD instead
   */
  ASPERA_UPLOAD = 'aspera_upload',
  BROWSE_ASSETS_ARCHIVE = 'browse_assets_archive',
  BROWSE_ASSETS = 'browse_assets',
  BROWSE_ASSETS_STOREFRONT = 'browse_assets_storefront',
  BROWSE_ASSETS_ON_MAP = 'browse_assets_on_map',
  BROWSE_ASSETS_LIBRARY = 'browse_assets_library',
  BROWSE_ASSETS_METADATA_EXPORT = 'browse_assets_metadata_export',
  TRIGGER_ASSET_MEDIA_PULSE_GENERATE_JSON = 'asset_mediapulse_generate_json',
  BROWSE_CATALOG_ITEM_TYPES = 'browse_catalog_item_types',
  BROWSE_CHARTS = 'browse_charts',
  BROWSE_GROUPS = 'browse_groups',
  BROWSE_COLLECTIONS = 'browse_collections',
  BROWSE_COLLECTIONS_ANALYTICS = 'browse_collections_analytics',
  BROWSE_DASHBOARDS = 'browse_dashboards',
  BROWSE_HYBRIK_ANALYZE = 'browse_hybrik_analyze',
  BROWSE_HYBRIK_JOBS = 'browse_hybrik_jobs',
  RESTART_HYBRIK_JOBS = 'restart_hybrik_jobs',
  BROWSE_DELIVERY_DESTINATIONS = 'browse_delivery_destinations',
  BROWSE_DELIVERY_PACKS = 'browse_delivery_packs',
  BROWSE_DELIVERY_UPLOAD_JOBS = 'browse_delivery_upload_jobs',
  BROWSE_ASSETS_INGEST = 'browse_assets_ingest',
  BROWSE_JOBS = 'browse_jobs',
  BROWSE_CATALOG_ITEMS = 'browse_catalog_items',
  BROWSE_SHARED_PACKS = 'browse_shared_packs',
  BROWSE_USERS = 'browse_users',
  BROWSE_RIGHTS_CONTRACTS_LOGS = 'browse_rights_contracts_logs',
  REDELIVER_ORDERS = 'redeliver_orders',
  BROWSE_OWN_GROUP_USERS = 'browse_own_group_users',
  BROWSE_POLICIES = 'browse_policies',
  BROWSE_SHARED_COLLECTIONS = 'browse_shared_collections',
  BROWSE_STATS = 'browse_stats',
  BROWSE_POWER_BI = 'browse_power_bi',
  BROWSE_SUPPLIERS = 'browse_suppliers',
  CREATE_ASSETS = 'create_assets',
  CREATE_CATALOG_ITEM_TYPES = 'create_catalog_item_types',
  CREATE_GROUPS = 'create_groups',
  CREATE_COLLECTIONS = 'create_collections',
  CREATE_DASHBOARDS = 'create_dashboards',
  CREATE_DELIVERY_DESTINATIONS = 'create_delivery_destinations',
  CREATE_DELIVERY_PACKS = 'create_delivery_packs',
  CREATE_DELIVERY_UPLOAD_JOBS = 'create_delivery_upload_jobs',
  CREATE_JOBS = 'create_jobs',
  CREATE_CATALOG_ITEMS = 'create_catalog_items',
  CREATE_USERS = 'create_users',
  CREATE_POLICIES = 'create_policies',
  CREATE_SUPPLIERS = 'create_suppliers',
  CREATE_VIRTUAL_ASSET = 'create_virtual_asset',
  DELETE_ASSETS = 'delete_assets',
  DELETE_CATALOG_ITEM_TYPES = 'delete_catalog_item_types',
  DELETE_GROUPS = 'delete_groups',
  DELETE_COLLECTIONS = 'delete_collections',
  DELETE_DASHBOARDS = 'delete_dashboards',
  DELETE_DELIVERY_DESTINATIONS = 'delete_delivery_destinations',
  DELETE_DELIVERY_PACKS = 'delete_delivery_packs',
  DELETE_DELIVERY_UPLOAD_JOBS = 'delete_delivery_upload_jobs',
  DELETE_JOBS = 'delete_jobs',
  DELETE_CATALOG_ITEMS = 'delete_catalog_items',
  DELETE_POLICIES = 'delete_policies',
  DELETE_SHARED_COLLECTIONS = 'delete_shared_collections',
  DELETE_SUPPLIERS = 'delete_suppliers',
  DELETE_USERS = 'delete_users',
  EDIT_ASSETS = 'edit_assets',
  EDIT_CATALOG_ITEM_TYPES = 'edit_catalog_item_types',
  EDIT_GROUPS = 'edit_groups',
  EDIT_COLLECTIONS = 'edit_collections',
  EDIT_DASHBOARDS = 'edit_dashboards',
  EDIT_DELIVERY_DESTINATIONS = 'edit_delivery_destinations',
  EDIT_DELIVERY_PACKS = 'edit_delivery_packs',
  COORDINATOR_EDIT_DELIVERY_PACKS = 'coordinator_edit_delivery_packs',
  COORDINATOR_BROWSE_DELIVERY_PACKS = 'coordinator_browse_delivery_packs',
  EDIT_DELIVERY_UPLOAD_JOBS = 'edit_delivery_upload_jobs',
  EDIT_JOBS = 'edit_jobs',
  EDIT_CATALOG_ITEMS = 'edit_catalog_items',
  EDIT_ORDERS = 'edit_orders',
  EDIT_USERS = 'edit_users',
  EDIT_POLICIES = 'edit_policies',
  EDIT_SUPPLIERS = 'edit_suppliers',
  SHOPPING_CART = 'shopping_cart',
  SHOPPING_CART_V2 = 'shopping_cart_v2',
  SHOPPING_CART_SHOW_PRICES = 'show_prices_in_cart',
  GENERATE_XML = 'generate_xml',
  IMPORT_CSV = 'import_csv',
  LOGIN = 'login',
  RUN_MEDIA_INSIGHTS_WORKFLOW = 'run_media_insights_workflow',
  SHARE_COLLECTIONS = 'share_collections',
  BROWSE_FIELDS = 'browse_fields',
  CREATE_FIELDS = 'create_fields',
  EDIT_FIELDS = 'edit_fields',
  DELETE_FIELDS = 'delete_fields',
  DOWNLOAD_MASTER = 'download_master',
  DOWNLOAD_SUBTITLES = 'download_subtitles',
  ASSET_PDF_GENERATION = 'asset_pdf_generation',
  DOWNLOAD_PROXY = 'download_proxy',
  DOWNLOAD_HTTPS_FROM_DOWNLOADS = 'download_https_from_downloads',
  DOWNLOAD_HTTPS_FROM_CONNECT2 = 'download_https_from_connect2',
  CREATE_GROUP_LOGO = 'create_group_logos',
  DELETE_GROUP_LOGO = 'delete_group_logos',
  EDIT_GROUP_LOGO = 'edit_group_logos',
  CREATE_ATTACHMENTS = 'create_attachments',
  EDIT_ATTACHMENTS = 'edit_attachments',
  DELETE_ATTACHMENTS = 'delete_attachments',
  BROWSE_PREVIEW_REQUESTS = 'browse_preview_requests',
  CREATE_PREVIEW_REQUESTS = 'create_preview_requests',
  EDIT_PREVIEW_REQUESTS = 'edit_preview_requests',
  DELETE_PREVIEW_REQUESTS = 'delete_preview_requests',
  CREATE_SUPPORT_REQUESTS = 'create_support_requests',
  CLIENT_EXTERNAL_SUPPORT = 'client_external_support',
  VIMEO_UPLOAD = 'vimeo_upload',
  BROWSE_RATINGS = 'browse_ratings',
  CREATE_RATINGS = 'create_ratings',
  DELETE_RATINGS = 'delete_ratings',
  EDIT_RATINGS = 'edit_ratings',
  GENERAL_LOGIN_TO_VIDA = 'login_to_vida',
  GENERAL_LOGIN_TO_LAUNCHPAD = 'login_to_launchpad',
  GENERAL_LOGIN_TO_STOREFRONT = 'login_to_storefront',
  VERIFY_DELIVERY_PACKS = 'verify_delivery_packs',
  BROWSE_OWN_ASSETS = 'browse_own_assets',
  EDIT_EMBARGO_DATES = 'edit_embargo_dates',
  BROWSE_ASSETS_EMBARGOED = 'browse_assets_embargoed',
  CREATE_REPORTS = 'create_reports',
  FORCE_RELEASE_SF_REQUEST = 'force_release_sf_request',
  CREATE_ASSETS_TRAILER = 'create_assets_trailer',
  BROWSE_ASSETS_TRANSACTIONS = 'browse_assets_transactions',
  AI_BROWSE_TRANSCRIPTIONS = 'browse_ai_transcriptions',
  AI_BROWSE_MODELS = 'browse_ai_models',
  AI_BROWSE_SUMMARIES = 'browse_ai_summaries',
  AI_RUN_JOBS = 'run_ai_jobs',
  AI_SEARCH = 'ai_search',
  BROWSE_OWN_ORDERS = 'browse_own_orders',
  BROWSE_SHARED_ORDERS = 'browse_shared_orders',
  BROWSE_ALL_ORDERS = 'browse_all_orders',
  BROWSE_ORDERS_TO_APPROVE = 'browse_orders_to_approve',
  IMPORT_ASSETS_CSV_COLLECTION = 'import_assets_csv_collection',
  SET_ASSETS_AS_COLD_ONLY = 'set_assets_as_cold_only',
  SET_ASSETS_AS_HOT = 'set_assets_as_hot',
  ASSET_REGENERATION_PROXY = 'asset_regenerate_proxy',
  BROWSE_DELETED_ASSETS = 'browse_deleted_assets',
  PUBLISH_DELIVERY_DESTINATIONS = 'publish_delivery_destinations',
  BROWSE_CREDENTIALS = 'browse_credentials',
  CREATE_CREDENTIALS = 'create_credentials',
  EDIT_CREDENTIALS = 'edit_credentials',
  DELETE_CREDENTIALS = 'delete_credentials',
  BROWSE_MEDIA_PULSE_ORDERS = 'browse_mp_orders',
  RETRY_DELIVERY_DESTINATIONS = 'retry_delivery_destinations',
  LOGIN_TO_CONTENT_CORNER = 'login_to_content_corner',
  BROWSE_LICENSED_PACKAGES = 'browse_licensed_packages',
  BROWSE_SHARED_LICENSED_PACKAGES = 'browse_shared_licensed_packages',
  CREATE_LICENSED_PACKAGES = 'create_licensed_packages',
  EDIT_LICENSED_PACKAGES = 'edit_licensed_packages',
  DELETE_LICENSED_PACKAGES = 'delete_licensed_packages',
  BROWSE_RIGHTS_CONTRACTS = 'browse_rights_contracts',
  CREATE_RIGHTS_CONTRACTS = 'create_rights_contracts',
  EDIT_RIGHTS_CONTRACTS = 'edit_rights_contracts',
  DELETE_RIGHTS_CONTRACTS = 'delete_rights_contracts',
  BROWSE_RIGHTS_PARTNERS = 'browse_partners',
  CREATE_RIGHTS_PARTNERS = 'create_partners',
  EDIT_RIGHTS_PARTNERS = 'edit_partners',
  DELETE_RIGHTS_PARTNERS = 'delete_partners',
  CREATE_API_TOKENS = 'create_api_tokens',
  RETRIGGER_CLIPPING = 'retrigger_clipping',
  PERMISSION_BROWSE_QA_CONFIGS = 'browse_qa_configs',
  CREATE_QA_CONFIGS = 'create_qa_configs',
  EDIT_QA_CONFIGS = 'edit_qa_configs',
  DELETE_QA_CONFIGS = 'delete_qa_configs',
  BROWSE_QA_RESULTS = 'browse_qa_results',
  BROWSE_QUARANTINED_ASSETS = 'browse_quarantined_assets',
  USE_QUARANTINED_ASSETS = 'use_quarantined_assets',
  EDIT_QUARANTINED_ASSETS = 'edit_quarantined_assets',
  RELEASE_QUARANTINED_ASSETS = 'release_quarantined_assets',
  MANAGE_QUARANTINE_CONFIGS = 'manage_quarantine_configs',
  BROWSE_RATES = 'browse_rates',
  EDIT_RATES = 'edit_rates',
  BROWSE_THRESHOLDS = 'browse_thresholds',
  EDIT_THRESHOLDS = 'edit_thresholds',
  MANAGE_NOTIFICATION_SUBSCRIPTIONS = 'manage_notification_subscriptions',
  BROWSE_NOTIFICATIONS = 'browse_notifications',
  BROWSE_DISCOUNTS = 'browse_discount_codes',
  CREATE_DISCOUNTS = 'create_discount_codes',
  EDIT_DISCOUNTS = 'edit_discount_codes',
  DELETE_DISCOUNTS = 'delete_discount_codes',
  USER_ADMIN_BROWSE = 'user_admin_browse',
  USER_ADMIN_CREATE = 'user_admin_create',
  USER_ADMIN_DELETE = 'user_admin_delete',
  USER_ADMIN_EDIT_POLICIES = 'user_admin_edit_policies',
  USER_ADMIN_EDIT = 'user_admin_edit',
  USER_ADMIN_DELETE_BULK = 'bulk_delete_users',
  GENERATE_ORDER_DELIVERY_CONFIRMATION = 'order_pdf_generation',
  BROWSE_LOGGING_INFO = 'browse_logging_info',
  CREATE_LOGGING_INFO = 'create_logging_info',
  EDIT_LOGGING_INFO = 'edit_logging_info',
  DELETE_LOGGING_INFO = 'delete_logging_info',
  BROWSE_POLICY_LOGS = 'browse_policy_logs',
  BROWSE_TEAMS = 'browse_teams',
  CREATE_TEAMS = 'create_teams',
  EDIT_TEAMS = 'edit_teams',
  DELETE_TEAMS = 'delete_teams',
  USE_TEAMS = 'use_teams',
  SUPPORT_CHAT_AGENT = 'support_chat_agent',
  BROWSE_LANDINGPAD_ASSETS = 'browse_landingpad_assets',
  INGEST_LANDINGPAD_ASSETS = 'ingest_landingpad_assets',
  DELETE_LANDINGPAD_ASSETS = 'delete_landingpad_assets',
  BROWSE_FILENAME_CONVENTIONS = 'browse_filename_conventions',
  USE_FILENAME_CONVENTIONS = 'use_filename_conventions',
  CREATE_FILENAME_CONVENTIONS = 'create_filename_conventions',
  EDIT_FILENAME_CONVENTIONS = 'edit_filename_conventions',
  DELETE_FILENAME_CONVENTIONS = 'delete_filename_conventions',
  PERMISSION_SHOW_ERROR_MESSAGES = 'show_error_message',
  MANAGE_ORDER_DISCOUNTS = 'manage_assigned_order_discounts',
  EDIT_RESTRICTED_POLICIES = 'edit_restricted_policies',
  BROWSE_MANDATORY_FIELDS = 'browse_mandatory_fields',
  EDIT_MANDATORY_FIELDS = 'edit_mandatory_fields',
  DELETE_MANDATORY_FIELDS = 'delete_mandatory_fields',
  PERMISSION_BROWSE_TRANSCODE_CONTAINERS = 'browse_transcode_containers',
  PERMISSION_CREATE_TRANSCODE_CONTAINERS = 'create_transcode_containers',
  PERMISSION_EDIT_TRANSCODE_CONTAINERS = 'edit_transcode_containers',
  PERMISSION_DELETE_TRANSCODE_CONTAINERS = 'delete_transcode_containers',
  PERMISSION_BROWSE_TRANSCODE_TYPES = 'browse_transcode_types',
  PERMISSION_CREATE_TRANSCODE_TYPES = 'create_transcode_types',
  PERMISSION_EDIT_TRANSCODE_TYPES = 'edit_transcode_types',
  PERMISSION_DELETE_TRANSCODE_TYPES = 'delete_transcode_types',
  PAIR_DEVICES = 'pair_devices',
  ENABLE_DOWNLOAD_NOW = 'enable_download_now',
  ENABLE_SEND_TO_DOWNLOADS = 'enable_send_to_downloads',
  ENABLE_CART_DOWNLOAD_LIMIT_OVERRIDE = 'enable_cart_download_limit_override',
}

export const EMPTY_PERMISSIONS: Readonly<Permission[]> = [];
