import { Injectable, inject } from '@angular/core';
import { AuthStateService } from './auth-state.service';
import { AuthLoginService } from './auth-login.service';
import { ProviderType } from '../models/provider.type';
import { AuthenticatedUserModel } from '../models/authenticated-user.model';
import { AuthAttributesService } from './auth-attributes.service';
import { UserUpdateAttributes } from '@vdms-hq/firebase-contract';
import { tap } from 'rxjs';

@Injectable()
export class AuthService {
  private authStateService = inject(AuthStateService);
  private authLoginService = inject(AuthLoginService);
  private authAttributesService = inject(AuthAttributesService);

  static readonly defaultPerPage = 24;
  userAttributes$ = this.authAttributesService.currentAuthAttributesState$;

  userAttributesDefinite$ = this.authAttributesService.currentAuthAttributesStateDefinite$;
  auth$ = this.authStateService.authState$;

  authDefinite$ = this.authStateService.authStateDefinite$;
  email$ = this.authStateService.email$;
  id$ = this.authStateService.authId$;
  activeToken$ = this.authStateService.authToken$;

  displayName$ = this.authAttributesService.displayName$;

  clientsInfo$ = this.authStateService.clientsInfo$;

  error$ = this.authStateService.error$;

  updateProfile = (data: Partial<AuthenticatedUserModel>) => this.authAttributesService.updateProfile(data);

  updateAttributes = (data: UserUpdateAttributes) => this.authAttributesService.updateAttributes(data);

  loginWithProvider = (type: ProviderType) => this.authLoginService.loginWithProvider(type);

  loginPasswordLess = (email: string) => this.authLoginService.loginPasswordLess(email);

  loginWithCustomToken = (token: string) => this.authLoginService.loginWithCustomToken(token);

  logout = (url?: string) => this.authLoginService.logout(url);

  passwordLessVerify = () => this.authLoginService.passwordLessVerification();

  mfaVerify = async () => {
    await this.authStateService.setFromFirebaseUser();
    await this.authLoginService.navigateToAuthorized();
  };

  reloadAuthState = () => this.authStateService.reloadAuthState();
}
